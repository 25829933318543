import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import Footer from "../Footer/Footer";
import Header from "../Header";
import { RiLoader5Fill } from 'react-icons/ri'
import ReactHtmlParser from 'react-html-parser'

const fetchData = async (id) => {
  const finalUrl =
    window._HOST + "api/v1/pages/detail?url=" + id

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export default function ContentPage() {
  const { page } = useParams()
  const history = useHistory()

  const [selectedPage, setSelectedPage] = useState(null)
  const [isLoad, setIsLoad] = useState(false)


  const { screenSize } = useSelector(state => state.global)

  useEffect(() => {
    window.scrollTo(0, 0)

    fetchData(page)
      .then(response => {
        if (response.success) {
          setSelectedPage(response.data)
        }
        else {
          history.push("/")
        }
      })
      .catch(error => { })
      .finally(() => setIsLoad(false))

    setIsLoad(true)
  }, [page])

  return (
    <>
      <Header />
      <div
        className="w-full py-2 flex justify-center"
        style={{
          paddingTop: screenSize.width > 500 ? "120px" : "10px",
        }}
      >
        <div
          className={
            "container px-5"
          }
        >
          {
            isLoad ?
              (
                <div className="h-full w-full flex items-center justify-center text-blue-500">
                  <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
                </div>
              )
              :
              (
                <>
                  <div className="py-5 text-left" style={{ fontSize: "30px", fontFamily: "Nunito" }}>
                    <label>{selectedPage != null ? selectedPage.title : '-'}</label>
                  </div>
                  {/* <div className="text-left text-gray-700" dangerouslySetInnerHTML={{ __html: selectedPage != null ? selectedPage.content_html : null }}>
                    { ReactHtmlParser (html_string) }
                  </div> */}
                  <div className="text-left text-gray-700">
                    {selectedPage != null ? ReactHtmlParser(selectedPage.content_html) : ''}
                  </div>
                </>
              )
          }

        </div>
      </div>
      <Footer />
    </>
  )

}