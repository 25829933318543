import { createSlice } from '@reduxjs/toolkit'

const priceAndDateParams = [
  {
    id: 'newest',
    label: 'Terbaru'
  },
  {
    id: 'highest_price',
    label: 'Harga Tertinggi'
  },
  {
    id: 'lowest_price',
    label: 'Harga Terendah'
  }
]

const initialState = {
  getDataParams: {
    keywordSearch: '',
    selectedKelas: null,
    selectedPriceDate: priceAndDateParams[0],
    limit: 50,
    offset: 0,
  },
  dataStatus: {
    totalData: 0,
    totalFiltered: 0
  },
  priceAndDateParams: priceAndDateParams
}

export const ProductSlice = createSlice({
  name: 'Product',
  initialState,
  reducers: {
    setSelectedPriceDate: (state, { payload }) => {
      state.getDataParams.selectedPriceDate = payload
      state.getDataParams.offset = 0
    },
    setSelectedKelas: (state, { payload }) => {
      state.getDataParams.selectedKelas = payload
      state.getDataParams.offset = 0
    },
    setKeywordSearch: (state, { payload }) => {
      state.getDataParams.keywordSearch = payload
      state.getDataParams.offset = 0
    },
    setOffset: (state, { payload }) => {
      state.getDataParams.offset = payload
    },
    setDataStatus: (state, { payload }) => {
      state.dataStatus.totalData = payload.totalData
      state.dataStatus.totalFiltered = payload.totalFiltered
    }
  }
})

export const {
  setSelectedPriceDate,
  setSelectedKelas,
  setKeywordSearch,
  setDataStatus,
  setOffset
} = ProductSlice.actions

export default ProductSlice.reducer