import Header from "../../component/Header"
import Footer from "../../component/Footer/Footer"
import { useEffect, useState } from "react"
import ChangePasswordDialog from "./comp/ChangePasswordDialog"
import AddNewAddressDialog from "./comp/AddNewAddressDialog"
import { useHistory, useParams } from "react-router"
import MyOrders from "./comp/MyOrders"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import { startLoading, stopLoading } from "../../component/Loading/LoadingSlice"
import { openAddNewAddressDialog, openChangePasswordDialog, resetAccountDetail, setAccountDetail, setAddresses } from "./MyAccountSlice"
import ErrorDialog from "../../component/ErrorDialog"

const fetchAddresses = async () => {
  const finalUrl =
    window._HOST + "api/v1/user/addresses"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export default function MyAccountPage() {
  const dispatch = useDispatch()
  const history = useHistory()

  const { screenSize } = useSelector(state => state.global)
  const { accountDetail } = useSelector(state => state.myAccount)

  let { mode } = useParams()
  const [isLoad, setIsLoad] = useState(false)

  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    errorMessage: ''
  })
  const openErrorDialog = (errMsg, errD) => ({ ...errD, isOpen: true, errorMessage: errMsg })
  const closeErrorDialog = (errD) => ({ ...errD, isOpen: false, errorMessage: '' })

  useEffect(() => {
    if (mode != undefined && mode == 'orders') {

    }
    else {
      setIsLoad(true)

      fetchAddresses()
        .then(response => {
          if (response.success) {
            dispatch(setAddresses(response.data))
          }
          else {
            const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
            setErrorDialog(openErrorDialog(errorMsg, errorDialog))
          }
        })
        .catch(errors => {
          setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
        })
        .finally(() => setIsLoad(false))
    }

    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (accountDetail == null) {
      history.push("/login")
    }
  }, [])

  return (
    <>
      <ErrorDialog
        isShow={errorDialog.isOpen}
        errorMessage={errorDialog.errorMessage}
        onClose={() => setErrorDialog(closeErrorDialog(errorDialog))}
      />
      {screenSize.width > 500 && <ChangePasswordDialog />}
      <AddNewAddressDialog />
      <Header />
      <div
        className="w-full py-2 flex justify-center"
        style={{
          paddingTop: screenSize.width > 500 ? "120px" : "10px"
        }}
      >
        <div className="container flex bg-white space-x-10">
          {screenSize.width > 500 && <SidePane mode={mode} />}
          {mode == 'orders' ?
            (<MyOrders />)
            :
            (<MyAccountComp />)
          }
        </div>
      </div>
      <Footer />
    </>
  )
}

const logOutServer = async () => {
  const response =
    await axios.post(window._HOST + "api/v1/auths/logout", { withCredentials: true })

  return response.data
}

function SidePane({ mode }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    errorMessage: ''
  })
  const openErrorDialog = (errMsg, errD) => ({ ...errD, isOpen: true, errorMessage: errMsg })
  const closeErrorDialog = (errD) => ({ ...errD, isOpen: false, errorMessage: '' })

  function onLogOut() {
    dispatch(startLoading("Tunggu ya, sedang memproses..."))

    logOutServer()
      .then(response => {
        if (response.success) {
          dispatch(resetAccountDetail())
          history.push("/")
        }
        else {
          const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
          setErrorDialog(openErrorDialog(errorMsg, errorDialog))
        }
      })
      .catch(errors => {
        setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
      })
      .finally(() => dispatch(stopLoading()))
  }

  return (
    <div
      className="py-5 px-8 rounded-md shadow-xl font-bold space-y-12 border"
      style={{ fontSize: "20px", width: "280px", height: "360px" }}
    >
      <ErrorDialog
        isShow={errorDialog.isOpen}
        errorMessage={errorDialog.errorMessage}
        onClose={() => setErrorDialog(closeErrorDialog(errorDialog))}
      />
      <div className="flex flex-col">
        <button
          className={"w-full p-2 text-left hover:text-blue-600 " + (mode == 'orders' ? " text-blue-700 " : "")}
          onClick={() => history.push("/my-account/orders")}
        >
          Pesanan Saya
        </button>
        <button
          className={"w-full p-2 text-left hover:text-blue-600 " + (mode != 'orders' ? " text-blue-700 " : "")}
          onClick={() => history.push("/my-account/")}
        >
          Akun Saya
        </button>
      </div>
      <div className="flex flex-col">
        <button
          className="w-full p-2 text-left hover:text-blue-600"
          onClick={() => dispatch(openChangePasswordDialog())}
        >
          Ubah kata sandi
        </button>
        <button
          className="w-full p-2 text-left hover:text-blue-600"
          onClick={onLogOut}
        >
          Keluar
        </button>
      </div>
    </div>
  )
}

function MyAccountComp() {

  const { screenSize } = useSelector(state => state.global)

  const [confOpts, setConfOpts] = useState({
    selectedId: 0,
    data: [
      {
        id: 0,
        label: 'Biodata',
      },
      {
        id: 1,
        label: 'Daftar Alamat',
      }
    ]
  })

  return (
    <div
      className={
        "w-full " +
        (screenSize.width > 500 ? "" : " px-5 ")
      }
    >
      <div className="flex border-b">
        {confOpts.data.map(d => (
          <button
            className={"py-3 px-6 hover:bg-green-200 border-b-2 border-transparent " + (d.id == confOpts.selectedId ? " border-green-500 " : "  ")}
            onClick={() => setConfOpts({ ...confOpts, selectedId: d.id })}
          >
            {d.label}
          </button>
        ))}
      </div>
      <div>
        {
          confOpts.selectedId == 0 ? (<MyAccountBio />) : (<MyAccountAddress />)
        }
      </div>
    </div>
  )
}

const updateBioServer = async (payload) => {
  const response =
    await axios.put(window._HOST + "api/v1/user/profiles", payload, { withCredentials: true })

  return response.data
}

function MyAccountBio() {
  const dispatch = useDispatch()
  const { accountDetail } = useSelector(state => state.myAccount)

  const [form, setForm] = useState({
    fullName: accountDetail != null ? accountDetail.nama_user : '-',
    email: accountDetail != null ? accountDetail.email : '-',
    hp: accountDetail != null ? accountDetail.hp : '-'
  })

  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    errorMessage: ''
  })
  const openErrorDialog = (errMsg, errD) => ({ ...errD, isOpen: true, errorMessage: errMsg })
  const closeErrorDialog = (errD) => ({ ...errD, isOpen: false, errorMessage: '' })

  function onSave() {
    const payload = {
      nama_user: form.fullName,
      hp: form.hp
    }

    updateBioServer(payload)
      .then(response => {
        if (response.success) {
          dispatch(setAccountDetail({ ...accountDetail, nama_user: form.fullName, hp: form.hp }))
        }
        else {
          const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
          setErrorDialog(openErrorDialog(errorMsg, errorDialog))
        }
      })
      .catch(error => {
        setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
      })
      .finally(() => dispatch(stopLoading()))

    dispatch(startLoading("Tunggu ya, sedang memproses..."))
  }

  return (
    <div className="py-5 space-y-5">
      <div className="space-y-1">
        <div className="text-gray-500 text-left" style={{ fontSize: "13px" }}>
          Nama Lengkap
        </div>
        <div className="border-b border-gray-400">
          <input
            className="outline-none w-full py-1"
            value={form.fullName}
            onChange={e => setForm({ ...form, fullName: e.target.value })}
          />
        </div>
      </div>
      <div className="space-y-1">
        <div className="text-gray-500 text-left" style={{ fontSize: "13px" }}>
          Email
        </div>
        <div className="border-b border-gray-400">
          <input className="outline-none w-full py-1" value={form.email} readOnly />
        </div>
      </div>
      <div className="space-y-1">
        <div className="text-gray-500 text-left" style={{ fontSize: "13px" }}>
          No. Telp
        </div>
        <div className="border-b border-gray-400">
          <input
            className="outline-none w-full py-1"
            value={form.hp}
            onChange={e => setForm({ ...form, hp: e.target.value })}
          />
        </div>
      </div>
      <div className="flex" style={{ marginTop: "50px" }}>
        <button
          className="border px-10 py-2 rounded-md bg-blue-500 text-white hover:shadow-lg"
          onClick={onSave}
        >
          Simpan
        </button>
      </div>
    </div>
  )
}

function MyAccountAddress() {
  const dispatch = useDispatch()
  const { addresses } = useSelector(state => state.myAccount)

  return (
    <div className="py-5 space-y-3">
      <div className="flex justify-end">
        <button
          className="px-10 py-2 rounded-md bg-blue-800 text-white"
          onClick={() => dispatch(openAddNewAddressDialog())}
        >
          Tambah Alamat
        </button>
      </div>
      <div className="space-y-2">
        {addresses.length > 0 ?
          addresses.map(addr => (
            <div className="rounded-md p-3 bg-gray-100 text-left space-y-2">
              <div className="font-bold text-blue-700" style={{ fontSize: "16px" }}>
                {addr.label_alamat}
              </div>
              <div className="font-bold">
                {addr.nama_penerima}
              </div>
              <div className="flex flex-col">
                <label>{addr.subdistrict_name} {addr.kode_pos}</label>
                <label>{addr.type} {addr.city_name} - {addr.province}</label>
                <label>No. Telp : {addr.no_hp_penerima}</label>
              </div>
            </div>
          ))
          :
          (
            <div>
              Kosong
            </div>
          )
        }


      </div>
    </div>
  )
}