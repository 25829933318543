import { configureStore } from '@reduxjs/toolkit'
import GlobalReducer from './GlobalSlice'
import HeaderReducer from '../component/HeaderSlice'
import { setupListeners } from '@reduxjs/toolkit/query'
import CartReducer from '../pages/Cart/CartSlice'
import MyAccountReducer from '../pages/MyAccount/MyAccountSlice'
import CheckoutReducer from '../pages/Checkout/CheckoutSlice'
import LoadingReducer from '../component/Loading/LoadingSlice'
import ProductReducer from '../pages/Product/productSlice'
import CheckoutResultReducer from '../pages/CheckoutResult/CheckoutResultSlice'

export const store = configureStore({
  reducer: {
    global: GlobalReducer,
    header: HeaderReducer,
    cart: CartReducer,
    checkout: CheckoutReducer,
    myAccount: MyAccountReducer,
    loading: LoadingReducer,
    product: ProductReducer,
    checkoutResult: CheckoutResultReducer
  }
})

setupListeners(store.dispatch)