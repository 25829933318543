import { Dialog, Transition } from "@headlessui/react"
import { useEffect, useRef, useState } from "react"
import { AiOutlineStop } from "react-icons/ai"
import { VscChromeClose } from "react-icons/vsc"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"

export default function ErrorDialog({
  isShow,
  errorMessage,
  onClose
}) {
  const { screenSize } = useSelector(state => state.global)

  return (
    <Transition appear show={isShow}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto"
        style={{
          zIndex: "999"
        }}
        onClose={() => null}
      >
        <div className="min-h-screen px-4 text-center flex justify-center items-center">
          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-50 bg-black" />
          </Transition.Child>

          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={
                "absolute top-20 " +
                (screenSize.width > 500 ? " left-1/2 " : " left-0 ")
              }
            >
              <div
                className="py-3 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
                style={{
                  position: "absolute",
                  left: screenSize.width > 500 ? "-230px" : "0",
                  width: screenSize.width > 500 ? "460px" : screenSize.width + "px",
                  height: "450px"
                }}
              >
                <Dialog.Title
                  as="h3"
                  className="pb-3 px-5 text-lg flex items-center justify-between font-bold leading-6 text-gray-900 border-b border-gray-200"
                >
                  <button
                    className="p-2 hover:text-red-500"
                    onClick={onClose}
                  >
                    <VscChromeClose className="h-6 w-6" />
                  </button>
                </Dialog.Title>
                <div
                  className="mt-4 px-5 flex flex-col space-y-3 overflow-auto space-y-5"
                  style={{ minHeight: "450px" }}
                >
                  <div className="flex justify-center py-5">
                    <AiOutlineStop size={120} color="red" />
                  </div>
                  <div className="text-center">
                    {errorMessage}
                  </div>
                </div>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )

}