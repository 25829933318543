import { Dialog, Transition } from "@headlessui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { closeAddDialog } from './PmbBankAccountSlice'
import { VscChromeClose } from "react-icons/vsc"
import { closeChangePasswordDialog } from "../MyAccountSlice"
import axios from "axios"
import { RiLoader5Fill } from "react-icons/ri"

const changePasswordServer = async (payload) => {
  const finalUrl =
    window._HOST + "api/v1/auths/changepassword"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export default function ChangePasswordDialog() {
  const dispatch = useDispatch()

  const { changePasswordDialog } = useSelector(state => state.myAccount)
  const { screenSize } = useSelector(state => state.global)

  const [isLoad, setIsLoad] = useState(false)
  const [errors, setErrors] = useState([])

  const [form, setForm] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  })

  function onChangePassword() {
    const payload = {
      old_password: form.oldPassword,
      new_password: form.newPassword,
      password_confirmation: form.confirmNewPassword
    }

    setIsLoad(true)

    changePasswordServer(payload)
      .then(response => {
        if (response.success) {
          dispatch(closeChangePasswordDialog())
        }
        else {
          setErrors(response.errors)
        }
      })
      .catch()
      .finally(() => setIsLoad(false))
  }

  return (
    <Transition appear show={changePasswordDialog.isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto"
        style={{
          zIndex: "999"
        }}
        onClose={() => null}
      >
        <div className="min-h-screen px-4 text-center flex justify-center items-center">
          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-70 bg-gray-200" />
          </Transition.Child>

          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={
                "absolute " +
                (screenSize.width > 500 ? " left-1/2 top-20 " : " left-0 top-0 ")
              }
            >
              <div
                className="py-3 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
                style={{
                  position: "absolute",
                  left: screenSize.width > 500 ? "-230px" : "0",
                  width: screenSize.width > 500 ? "460px" : screenSize.width + "px"
                }}
              >
                {
                  isLoad ?
                    (
                      <>
                        <Dialog.Title
                          as="h3"
                          className="pb-3 px-5 text-lg flex items-center justify-between font-medium leading-6 text-gray-900 border-b border-gray-200"
                        >
                          Ubah kata sandi
                          <button
                            className="p-2 hover:text-red-500"
                            onClick={() => dispatch(closeChangePasswordDialog())}
                          >
                            <VscChromeClose className="h-6 w-6" />
                          </button>
                        </Dialog.Title>
                        <div className="h-full w-full flex items-center justify-center text-blue-500 py-10">
                          <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
                        </div>
                      </>
                    )
                    :
                    (
                      <>
                        <Dialog.Title
                          as="h3"
                          className="pb-3 px-5 text-lg flex items-center justify-between font-medium leading-6 text-gray-900 border-b border-gray-200"
                        >
                          Ubah kata sandi
                          <button
                            className="p-2 hover:text-red-500"
                            onClick={() => dispatch(closeChangePasswordDialog())}
                          >
                            <VscChromeClose className="h-6 w-6" />
                          </button>
                        </Dialog.Title>
                        <div className="mt-4 px-5 flex flex-col space-y-3">
                          {errors.length > 0 && (
                            <div className={"p-2 border rounded-md bg-red-500 text-white text-left"}>
                              <ul className="list-disc px-5">
                                {errors.map(e => (
                                  <li>{e}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                          <div className={"p-2 border border-gray-300 "}>
                            <input
                              type="password"
                              className="w-full outline-none"
                              placeholder="Password Lama"
                              value={form.oldPassword}
                              onChange={e => setForm({ ...form, oldPassword: e.target.value })}
                            />
                          </div>
                          <div className={"p-2 border border-gray-300 "}>
                            <input
                              type="password"
                              className="w-full outline-none"
                              placeholder="Kata Sandi baru"
                              value={form.newPassword}
                              onChange={e => setForm({ ...form, newPassword: e.target.value })}
                            />
                          </div>
                          <div className={"p-2 border border-gray-300 "}>
                            <input
                              type="password"
                              className="w-full outline-none"
                              placeholder="Ketik ulang Kata Sandi baru"
                              value={form.confirmNewPassword}
                              onChange={e => setForm({ ...form, confirmNewPassword: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="w-full flex px-5 mt-4 space-x-2">
                          <div className="w-full">
                            <button
                              type="button"
                              className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                              onClick={onChangePassword}
                            >
                              Simpan
                            </button>
                          </div>
                        </div>
                      </>
                    )
                }

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}