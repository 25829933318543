import { useEffect, useState } from "react"
import axios from "axios"
import {
  RiLoader5Fill
} from "react-icons/ri"
import { useHistory } from "react-router"
import { useSelector } from "react-redux"
import { numToRupiahFormat } from '../../../../util/currency'

const fetchDataFeatured = async () => {
  const finalUrl =
    window._HOST + "api/v1/admin/books/featured"

  const response =
    await axios.get(finalUrl)

  return response.data
}

export default function FeaturedItemBox() {
  const history = useHistory()

  const [isLoad, setIsLoad] = useState(false)
  const { screenSize } = useSelector(state => state.global)

  const [featuredCat, setFeaturedCat] = useState({
    selectedId: 1,
    data: [
      {
        id: 1,
        label: 'Cetak full color',
        bannerImage: (window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/varian-full-color.png",
        desc: (
          <div className="space-y-5">
            <div style={{ textAlign: 'justify' }}>
              Buku ini dicetak dengan full color sehingga memiliki warna gambar dan teks yang optimal, karena perpaduan warna cetak yang beragam.
            </div>
            <div style={{ textAlign: 'justify' }}>
              Buku pelajaran cerdas berkarakter. Untuk bangun prestasi dan akhlak mulia siswa secara terpadu. Mengacu pada kurikulum Nasional sehingga dapat digunakan secara umum dan update dengan perkembangan dunia pendidikan.
            </div>
            <div style={{ textAlign: 'justify' }}>
              Konten pembelajaran dalam buku terintegrasi dengan nilai-nilai dari Al-Quran dan Hadits. Buku pelajaran juga akan terhubung dengan aplikasi pendidikan As-Syifa Learning Center berupa bank soal, CBT dan e-learning.
            </div>
          </div>
        )
      },
      {
        id: 2,
        label: 'Cetak dua warna',
        bannerImage: (window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/varian-dua-warna.png",
        desc: (
          <div className="space-y-5">
            <div style={{ textAlign: 'justify' }}>
              Buku ini dicetak dengan paduan dua warna gambar dan teks yang elegan, lebih ekonomis tanpa mengurangi kualitas.
            </div>
            <div style={{ textAlign: 'justify' }}>
              Buku pelajaran cerdas berkarakter. Untuk bangun prestasi dan akhlak mulia siswa secara terpadu. Mengacu pada kurikulum Nasional sehingga dapat digunakan secara umum dan update dengan perkembangan dunia pendidikan.
            </div>
            <div style={{ textAlign: 'justify' }}>
              Konten pembelajaran dalam buku terintegrasi dengan nilai-nilai dari Al-Quran dan Hadits. Buku pelajaran juga akan terhubung dengan aplikasi pendidikan As-Syifa Learning Center berupa bank soal, CBT dan e-learning.
            </div>
          </div>
        )
      },
      {
        id: 0,
        label: 'Buku Digital',
        bannerImage: (window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/varian-digital.png",
        desc: (
          <div className="space-y-5">
            <div style={{ textAlign: 'justify' }}>
              Buku ini dikemas dalam bentuk electronic book (ebook) yang dapat diakses dari smartphone (Android), di mana saja dan kapan saja selama tersedia jaringan internet.
            </div>
            <div style={{ textAlign: 'justify' }}>
              Buku pelajaran cerdas berkarakter. Untuk bangun prestasi dan akhlak mulia siswa secara terpadu. Mengacu pada kurikulum Nasional sehingga dapat digunakan secara umum dan update dengan perkembangan dunia pendidikan.
            </div>
            <div style={{ textAlign: 'justify' }}>
              Konten pembelajaran dalam buku terintegrasi dengan nilai-nilai dari Al-Quran dan Hadits. Buku pelajaran juga akan terhubung dengan aplikasi pendidikan As-Syifa Learning Center berupa bank soal, CBT dan e-learning.
            </div>
          </div>
        )
      },
    ]
  })

  const getSelectedCat = (tabs) => {
    return tabs.data.find(t => t.id === tabs.selectedId)
  }

  const [items, setItems] = useState([
  ])

  function doGetDataFeatured() {
    setIsLoad(true)

    fetchDataFeatured()
      .then(response => {
        if (response.success) {
          const manipulatedData = response.data.slice(0, 10).map(d => ({
            id: d.id_buku,
            title: d.nama_buku,
            desc: d.deskripsi,
            img: d.detail_buku[0].thumb_img,
            hargaPrintFullColor: d.harga_buku_full_color,
            hargaPrintDuaWarna: d.harga_buku_dua_warna,
            hargaPrintSatuWarna: d.harga_buku_satu_warna,
            hargaDigital: d.harga_buku_digital,
            hargaPrintFullColorWithPromotion: d.harga_buku_full_color_with_promotion,
            hargaPrintDuaWarnaWithPromotion: d.harga_buku_dua_warna_with_promotion,
            hargaPrintSatuWarnaWithPromotion: d.harga_buku_satu_warna_with_promotion,
            hargaDigitalWithPromotion: d.harga_buku_digital_with_promotion,
            potonganHargaBukuFullColor: d.potongan_harga_buku_full_color,
            potonganHargaBukuDuaWarna: d.potongan_harga_buku_dua_warna,
            potonganHargaBukuSatuWarna: d.potongan_harga_buku_satu_warna,
            potonganHargaBukuDigital: d.potongan_harga_buku_digital
          }))

          setItems(manipulatedData)
        }
      })
      .catch(errors => {

      })
      .finally(() => setIsLoad(false))

  }

  // useEffect(() => {
  //   doGetDataFeatured()
  // }, [])


  return (
    <div
      className="w-full flex justify-center"
    >
      <div
        className="container bg-white"
      >
        {
          isLoad ?
            (
              <div className="h-full w-full flex items-center justify-center text-blue-500">
                <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
              </div>
            )
            :
            (
              <>
                <div
                  className={
                    " flex justify-center border-b mb-2 " +
                    (screenSize.width > 500 ? " px-10 " : "")
                  }
                >
                  {featuredCat.data.map(d => (
                    <button
                      className={"py-3 px-6 uppercase hover:bg-blue-700 border-b-2 border-transparent " + (d.id == featuredCat.selectedId ? " border-blue-500 " : "  ")}
                      onClick={() => setFeaturedCat({ ...featuredCat, selectedId: d.id })}
                      style={{
                        fontFamily: "Inter",
                        fontSize: screenSize.width > 500 ? "16px" : "14px",
                        backgroundColor: d.id == featuredCat.selectedId ? '#3b99b4' : 'transparent',
                        color: d.id == featuredCat.selectedId ? 'white' : 'black'
                      }}
                    >
                      { screenSize.width > 500 ? d.label : d.label.replace('Print', '')}
                    </button>
                  ))}
                </div>
                <BannerImageVarian item={getSelectedCat(featuredCat)} />
                {/* <div
                  className={
                    "grid " +
                    (screenSize.width > 500 ? " grid-cols-5 gap-y-3 " : " px-2 grid-cols-2 gap-y-2 ")
                  }
                >
                  {items.map(i => (
                    <ItemBox
                      item={i}
                      priceCategoryId={featuredCat.selectedId}
                    />
                  ))}
                </div> */}
                {/* <div
                  className="py-5 flex justify-center "
                >
                  <button
                    className="rounded-sm py-3 px-6 bg-green-600 text-white hover:bg-green-700 hover:shadow-lg"
                    onClick={() => history.push("/products")}
                  >
                    Lihat Semua
                  </button>
                </div> */}
              </>
            )
        }

      </div>
    </div>
  )
}

function BannerImageVarian({ item }) {
  const { screenSize } = useSelector(state => state.global)

  return (
    <div
      className={screenSize.width > 500 ? "py-5 flex space-x-5" : "py-5 flex flex-col items-center"}
    >
      <img width={screenSize.width > 500 ? "30%" : "90%"} src={item.bannerImage} />
      <div className={"px-3 flex flex-col items-start space-y-3 " + (screenSize.width > 500 ? " pt-6 " : " pt-2 ")}>
        <label style={{ fontFamily: "Nunito", fontSize: "30px", fontWeight: "700" }}>{item.label}</label>
        <div>
          {item.desc}
        </div>
      </div>
    </div>
  )
}

function ItemBox({ item, priceCategoryId }) {
  const history = useHistory()
  const { screenSize } = useSelector(state => state.global)

  function catHasDisc() {
    return ((priceCategoryId == 0 && item.potonganHargaBukuDigital != "0%") ||
      (priceCategoryId == 1 && item.potonganHargaBukuFullColor != "0%") ||
      (priceCategoryId == 2 && item.potonganHargaBukuDuaWarna != "0%") ||
      (priceCategoryId == 3 && item.potonganHargaBukuSatuWarna != "0%"))
  }

  function isHasNoPrice() {
    return (priceCategoryId == 0 && parseInt(item.hargaDigital) == 0) ||
      (priceCategoryId == 1 && parseInt(item.hargaPrintFullColor) == 0) ||
      (priceCategoryId == 2 && parseInt(item.hargaPrintDuaWarna) == 0) ||
      (priceCategoryId == 3 && parseInt(item.hargaPrintSatuWarna) == 0)
  }

  if (isHasNoPrice()) {
    return (
      <div></div>
    )
  }

  return (
    <button
      id={item.id}
      className="relative py-3 cursor-pointer group space-y-2 border border-transparent flex flex-col justify-start hover:border-green-300 hover:shadow-md"
      onClick={() => history.push("/product-detail/" + item.id)}
    >
      {
        catHasDisc() && (
          <div
            className="absolute right-5 rounded-full bg-red-500 text-white shadow-lg"
            style={{
              width: "50px",
              height: "50px"
            }}
          >
            <div className="h-full flex items-center justify-center">
              -{
                priceCategoryId == 0 ?
                  item.potonganHargaBukuDigital
                  :
                  priceCategoryId == 1 ?
                    item.potonganHargaBukuFullColor
                    :
                    priceCategoryId == 2 ?
                      item.potonganHargaBukuDuaWarna
                      :
                      item.potonganHargaBukuSatuWarna
              }
            </div>
          </div>
        )
      }
      <div className="w-full flex justify-center">
        <img
          src={item.img}
          width={
            screenSize.width > 500 ? "180px" : "160px"
          }
        />
      </div>
      <div className="flex px-5 flex-col text-left">
        <label
          className="cursor-pointer group-hover:text-green-600"
          style={{ fontSize: "15px" }}
        >
          {item.title}
        </label>
        <label
          className={
            "font-bold cursor-pointer " +
            (catHasDisc() ? " line-through " : "")
          }
          style={{
            fontSize: catHasDisc() ? "13px" : "16px"
          }}
        >
          {priceCategoryId == 0 ?
            "Rp " + numToRupiahFormat(item.hargaDigital)
            :
            priceCategoryId == 1 ?
              "Rp " + numToRupiahFormat(item.hargaPrintFullColor)
              :
              priceCategoryId == 2 ?
                "Rp " + numToRupiahFormat(item.hargaPrintDuaWarna)
                :
                "Rp " + numToRupiahFormat(item.hargaPrintSatuWarna)
          }
        </label>
        {
          catHasDisc() && (
            <label className="font-bold text-red-600">
              {
                priceCategoryId == 0 ?
                  "Rp " + numToRupiahFormat(item.hargaDigitalWithPromotion)
                  :
                  priceCategoryId == 1 ?
                    "Rp " + numToRupiahFormat(item.hargaPrintFullColorWithPromotion)
                    :
                    priceCategoryId == 2 ?
                      "Rp " + numToRupiahFormat(item.hargaPrintDuaWarnaWithPromotion)
                      :
                      "Rp " + numToRupiahFormat(item.hargaPrintSatuWarnaWithPromotion)
              }
            </label>
          )
        }
      </div>
    </button>
  )
}