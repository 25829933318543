import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isSignOutDialogOpen: false,
  changePasswordDialog: {
    isOpen: false
  },
  uploadPhotoDialog: {
    isOpen: false
  },
  screenSize: {
    width: 0,
    height: 0
  },
  sideMenuMobile: {
    isOpen: false
  },
  sideCartMobile: {
    isOpen: false
  },
  isLoading: false,
  systemProfile: [],
  macaDialog: {
    isOpen: false
  },
  downloadAPkDialog: {
    isOpen: false
  }
}

export const GlobalSlice = createSlice({
  name: 'Global',
  initialState,
  reducers: {
    openSignOutDialog: (state, _) => {
      state.isSignOutDialogOpen = true
    },
    closeSignOutDialog: (state, _) => {
      state.isSignOutDialogOpen = false
    },
    openChangePasswordDialog: state => {
      state.changePasswordDialog.isOpen = true
    },
    closeChangePasswordDialog: state => {
      state.changePasswordDialog.isOpen = false
    },
    setScreenSize: (state, { payload }) => {
      state.screenSize = payload
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    openSideMenuMobile: state => {
      state.sideMenuMobile.isOpen = true
    },
    closeSideMenuMobile: state => {
      state.sideMenuMobile.isOpen = false
    },
    openSideCartMobile: state => {
      state.sideCartMobile.isOpen = true
    },
    closeSideCartMobile: state => {
      state.sideCartMobile.isOpen = false
    },
    setSystemProfile: (state, { payload }) => {
      state.systemProfile = payload
    },
    openMacaDialog: state => {
      state.macaDialog.isOpen = true
    },
    closeMacaDialog: state => {
      state.macaDialog.isOpen = false
    },
    openDownloadAPKDialog: state => {
      state.downloadAPkDialog.isOpen = true
    },
    closeDownloadAPKDialog: state => {
      state.downloadAPkDialog.isOpen = false
    },
  }
})

export const {
  openSignOutDialog,
  closeSignOutDialog,
  openChangePasswordDialog,
  closeChangePasswordDialog,
  setScreenSize,
  setIsLoading,
  openSideMenuMobile,
  closeSideMenuMobile,
  openSideCartMobile,
  closeSideCartMobile,
  setSystemProfile,
  openMacaDialog,
  closeMacaDialog,
  openDownloadAPKDialog,
  closeDownloadAPKDialog
} = GlobalSlice.actions

export default GlobalSlice.reducer