import Header from "../../component/Header"
import Footer from "../../component/Footer/Footer"
import { useEffect, useState } from "react"
import { RiEyeCloseLine, RiEyeFill } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { setIsTransactableMode } from "../../component/HeaderSlice"
import { useHistory } from "react-router"
import axios from "axios"
import { startLoading, stopLoading } from "../../component/Loading/LoadingSlice"
import { setAccountDetail } from "../MyAccount/MyAccountSlice"
import { setItems, updateItemSynced } from "../Cart/CartSlice"
import toast from "react-hot-toast"
import ErrorDialog from "../../component/ErrorDialog"

const loginServer = async (payload) => {
  const finalUrl =
    window._HOST + "api/v1/auths/loginuser"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

const forgetPasswordServer = async (payload) => {
  const finalUrl =
    window._HOST + "api/v1/auths/forgot"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

const getPendingCartServer = async () => {
  const finalUrl =
    window._HOST + "api/v1/user/carts/"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

const addToCartServer = async (payload) => {
  const finalUrl =
    window._HOST + "api/v1/user/carts"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export default function LoginPage() {
  const dispatch = useDispatch()
  const history = useHistory()

  const { cartItems } = useSelector(state => state.cart)
  const { screenSize } = useSelector(state => state.global)
  const { accountDetail } = useSelector(state => state.myAccount)

  const [form, setForm] = useState({
    username: '',
    password: '',
    isPasswordOpen: false
  })

  function togglePassword() {
    setForm({
      ...form,
      isPasswordOpen: !form.isPasswordOpen
    })
  }

  const [focusedField, setFocusedField] = useState(null)
  const [errors, setErrors] = useState([])

  const [forgetEmailAddr, setForgetEmailAddr] = useState('')
  const [isSuccessResetPass, setIsSuccessResetPass] = useState(null)

  const [mode, setMode] = useState('login') //login or forget_password

  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    errorMessage: ''
  })
  const openErrorDialog = (errMsg, errD) => ({ ...errD, isOpen: true, errorMessage: errMsg })
  const closeErrorDialog = (errD) => ({ ...errD, isOpen: false, errorMessage: '' })

  useEffect(() => {
    dispatch(setIsTransactableMode(false))
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (accountDetail) {
      dispatch(setIsTransactableMode(true))
      history.push('/my-account')
    }
  }, [accountDetail])

  useEffect(() => {
    if (mode == 'forget_password') {
      setForgetEmailAddr('')
      setIsSuccessResetPass(null)
    }
    setErrors([])
  }, [mode])

  function onLogin() {
    const payload = {
      email: form.username,
      password: form.password
    }

    dispatch(startLoading("Tunggu ya, Sedang memproses"))

    loginServer(payload)
      .then(response => {
        if (response.success) {
          dispatch(setAccountDetail(response.data))
          addUnSyncItemToCart()
        }
        else {
          setErrors(response.errors)
          dispatch(stopLoading())
        }
      })
      .catch(errors => {
        setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
      })
  }

  function addUnSyncItemToCart() {
    const unSyncCartItems = cartItems.filter(i => i.notAddedYet != undefined && i.notAddedYet)
    if (unSyncCartItems.length > 0) {
      const promises = unSyncCartItems.map(async i => {
        const payloadReq = {
          id_buku: i.id_buku,
          tipe_buku: i.tipe_buku,
          jumlah_buku: i.jumlah_barang
        }

        try {
          const response = await addToCartServer(payloadReq)
          if (response.success) {
            dispatch(updateItemSynced({ id_buku: i.id_buku, tipe_buku: i.tipe_buku }))
          }
        } catch (errors) {
          setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
        }

      });

      Promise
        .all(promises)
        .then(response => {
          return getPendingCart()
        })
        .catch(errors => {
          setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
        })

    }
    else {
      getPendingCart()
    }
  }

  function getPendingCart() {
    getPendingCartServer()
      .then(response => {
        if (response.success) {
          const cartItems = response.data
          dispatch(setItems(cartItems))
          history.push("/")
        }
      })
      .catch(errors => {
        setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
      })
      .finally(() => dispatch(stopLoading()))
  }

  function onResetPassword() {
    const payload = {
      email: forgetEmailAddr,
    }

    dispatch(startLoading("Tunggu ya, Sedang memproses"))

    forgetPasswordServer(payload)
      .then(response => {
        if (response.success) {
          setIsSuccessResetPass(true)
          dispatch(stopLoading())
        }
        else {
          setErrors(response.errors)
          dispatch(stopLoading())
        }
      })
      .catch(errors => {
        setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
      })
  }

  return (
    <>
      <ErrorDialog
        isShow={errorDialog.isOpen}
        errorMessage={errorDialog.errorMessage}
        onClose={() => setErrorDialog(closeErrorDialog(errorDialog))}
      />
      <Header />
      <div
        className="w-full py-2 flex justify-center"
        style={{
          paddingTop: screenSize.width > 500 ? "200px" : "10px",
        }}
      >
        <div
          className={
            "container flex " +
            (screenSize.width > 500 ? " " : " justify-center ")
          }
        >
          {screenSize.width > 500 && (
            <div
              className="flex-1"
            >
              <div
                className="relative"
              >
                <img src={(window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/banner-web-05-3.png"} />
                <div className="absolute bottom-12 left-64">
                  <div className="flex space-x-3">
                    <button
                      className="p-2 px-5 rounded-md shadow-sm hover:shadow-lg flex bg-black text-white space-x-2"
                      onClick={() => window.open('https://play.google.com/store/apps/details?id=com.syibook', '_blank')}
                    >
                      <img src="/assets/images/google-play-transparent.png" width="20px" />
                      <label className="cursor-pointer font-bold" style={{ fontFamily: "Nunito" }}>Klik di sini</label>
                    </button>
                    <button
                      className="p-2 px-5 rounded-md shadow-sm hover:shadow-lg flex bg-black text-white space-x-2"
                      onClick={() => window.open('/assets/Syibook-Desktop.rar', '_blank')}
                    >
                      <img src="/assets/images/windows.png" width="20px" />
                      <label className="cursor-pointer font-bold" style={{ fontFamily: "Nunito" }}>Syibook Desktop</label>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={
              "py-5 px-8 rounded-md bg-white space-y-8 " +
              (screenSize.width > 500 ? " border border-gray-200 shadow-xl " : " justify-center ")
            }
            style={{
              width: "390px"
            }}
          >
            {
              mode == 'login' ?
                (
                  <>
                    <div className="text-left" style={{ fontSize: "20px" }}>
                      Log in
                    </div>
                    <div className="space-y-7 text-gray-600" style={{ fontSize: "14px" }}>
                      {errors.length > 0 && (
                        <div className={"p-2 border rounded-md bg-red-500 text-white text-left"}>
                          <ul className="list-disc px-5">
                            {errors.map(e => (
                              <li>{e}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <div className={"p-2 border " + (focusedField == 'username' ? " border-gray-400 shadow-md " : " border-gray-300 ")}>
                        <input
                          className="w-full outline-none"
                          onFocus={() => setFocusedField('username')}
                          onBlur={() => setFocusedField(null)}
                          onChange={e => setForm({ ...form, username: e.target.value })}
                          placeholder="Email"
                        />
                      </div>
                      <div className={"p-2 border flex items-center " + (focusedField == 'password' ? " border-gray-400 shadow-md " : " border-gray-300 ")}>
                        <input
                          type={form.isPasswordOpen ? "text" : "password"}
                          className="w-full outline-none"
                          onFocus={() => setFocusedField('password')}
                          onBlur={() => setFocusedField(null)}
                          onChange={e => setForm({ ...form, password: e.target.value })}
                          placeholder="Password"
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              onLogin()
                            }
                          }}
                        />
                        <button
                          className="px-1 outline-none"
                          onClick={togglePassword}
                          title={form.isPasswordOpen ? "sembunyikan password" : "Lihat password"}
                        >
                          {
                            form.isPasswordOpen ?
                              (<RiEyeCloseLine className="h-6 w-6" />)
                              :
                              (<RiEyeFill className="h-6 w-6" />)
                          }
                        </button>
                      </div>
                    </div>
                    <div className="text-gray-500 space-x-2" style={{ fontSize: "16px" }}>
                      <span
                        className="font-bold text-blue-900 cursor-pointer hover:underline"
                        onClick={() => setMode('forget_password')}
                      >
                        Lupa Password?
                      </span>
                    </div>
                    <div>
                      <button
                        className="w-full py-2 text-center bg-green-500 text-white uppercase hover:bg-green-600 hover:shadow-lg"
                        onClick={onLogin}
                      >
                        Login
                      </button>
                    </div>
                    <div className="text-gray-500 space-x-2" style={{ fontSize: "14px" }}>
                      <span>Belum punya akun?</span>
                      <span
                        className="font-bold text-blue-900 cursor-pointer hover:underline"
                        onClick={() => history.push("/register")}
                      >
                        Daftar
                      </span>
                    </div>
                  </>
                )
                :
                (
                  <>
                    <div className="text-left" style={{ fontSize: "20px" }}>
                      Lupa password
                    </div>
                    {
                      isSuccessResetPass == true ?
                        (
                          <>
                            <div className="text-center">
                              <p>Kami telah mengirimkan email berisi link reset password. silahkan cek inbox email anda.</p>
                            </div>
                          </>
                        )
                        :
                        (
                          <>
                            {errors.length > 0 && (
                              <div className={"p-2 border rounded-md bg-red-500 text-white text-left"}>
                                <ul className="list-disc px-5">
                                  {errors.map(e => (
                                    <li>{e}</li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            <div className={"p-2 border border-gray-300 "}>
                              <input
                                className="w-full outline-none"
                                onChange={e => setForgetEmailAddr(e.target.value)}
                                placeholder="Masukan email anda"
                              />
                            </div>
                            <div>
                              <button
                                className="w-full py-2 text-center bg-green-500 text-white uppercase hover:bg-green-600 hover:shadow-lg"
                                onClick={onResetPassword}
                              >
                                Reset Password
                              </button>
                            </div>
                          </>
                        )
                    }
                    <div className="text-left text-gray-500 space-x-2" style={{ fontSize: "14px" }}>
                      <span
                        className="font-bold text-blue-900 cursor-pointer hover:underline"
                        onClick={() => setMode('login')}
                      >
                        Kembali
                      </span>
                    </div>
                  </>
                )
            }




          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}