import { useEffect, useState } from 'react'
import { AiFillInstagram, AiOutlineMenu, AiOutlineSearch } from 'react-icons/ai'
import { ImCart } from 'react-icons/im'
import { MdHelpOutline } from 'react-icons/md'
import { AiFillFacebook } from 'react-icons/ai'
import './Header.css'
import { FiChevronDown, FiChevronRight } from "react-icons/fi"
import { TiUser } from 'react-icons/ti'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { startLoading, stopLoading } from './Loading/LoadingSlice'
import { resetAccountDetail } from '../pages/MyAccount/MyAccountSlice'
import axios from 'axios'
import { resetCartItems } from '../pages/Cart/CartSlice'
import { setKeywordSearch } from '../pages/Product/productSlice'
import SideMenu from './SideMenu/SideMenu'
import { openSideCartMobile, openSideMenuMobile } from '../app/GlobalSlice'
import SideCart from './SideCart/SideCart'
import { setCurrentSearchKeyword } from './HeaderSlice'
import { numToRupiahFormat } from '../util/currency'

export default function Header() {
  const dispatch = useDispatch()
  const history = useHistory()

  const { isTransactableMode } = useSelector(state => state.header)
  const { accountDetail } = useSelector(state => state.myAccount)
  const { screenSize } = useSelector(state => state.global)

  const [showTopInfo, setShowTopInfo] = useState(true)
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [])

  useEffect(() => {
    if (offset > 25) {
      setShowTopInfo(false)
    }
    else {
      setShowTopInfo(true)
    }
  }, [offset])

  return (
    <>
      <SideMenu />
      <SideCart />
      <div
        className={
          "w-full bg-red-600 py-1 flex items-center justify-center space-x-2 " +
          (showTopInfo ? " " : " hidden ")
        }
        style={{
          zIndex: "900",
        }}
      >
        <label className="text-white">Download Katalog buku di sini</label>
        <button
          className="px-5 border border-white bg-white rounded-full text-blue-700 hover:bg-blue-500 hover:text-white"
          onClick={() => window.open(window._HOST + 'assets/e-katalog.pdf', '_blank')}
        >
          Download
          </button>
      </div>
      <div
        className={
          "w-full bg-white py-1 flex justify-center border-b " +
          (screenSize.width > 500 ? " fixed " : "")
        }
        style={{
          zIndex: "900",
          height: screenSize.width > 500 ? "88px" : "70px"
        }}
      >
        <div className="container">
          {isTransactableMode && screenSize.width > 500 && (<TopSegment />)}
          <div className={"flex items-center justify-between " + (isTransactableMode && screenSize.width > 500 ? "" : "h-full")}>
            <div className={"px-2 flex items-center " + (isTransactableMode && screenSize.width > 500 ? "" : "h-full")}>
              <button
                onClick={() => history.push("/")}
              >
                <img
                  src="/assets/images/logo.png"
                  width="130px"
                />
              </button>
            </div>
            {
              isTransactableMode ?
                screenSize.width > 500 ?
                  (
                    <>
                      <div
                        className="absolute flex items-center justify-center left-1/2"
                        style={{
                          width: "600px",
                          marginLeft: "-300px"
                        }}
                      >
                        <HeaderSearchBar />
                      </div>
                      <div className="flex items-center space-x-5">
                        <HeaderCart />
                        {
                          accountDetail != null ? (<MemberBtn />) : (<SigninComps />)
                        }
                      </div>
                    </>
                  )
                  :
                  (
                    <div className="flex pr-5 space-x-2">
                      <button
                        className="p-2"
                        onClick={() => dispatch(openSideCartMobile())}
                      >
                        <ImCart className="h-6 w-6" />
                      </button>
                      <button
                        className="p-2"
                        onClick={() => dispatch(openSideMenuMobile())}
                      >
                        <AiOutlineMenu className="h-6 w-6" />
                      </button>
                    </div>
                  )
                :
                null
            }
          </div>
        </div>
      </div>
    </>
  )
}

function SigninComps() {
  const history = useHistory()

  return (
    <div className="flex space-x-2">
      <button
        className="font-bold py-2 px-5 border-2 border-transparent rounded-md text-green-700 hover:bg-white hover:text-gray-600 hover:border-green-500 hover:shadow-lg"
        style={{
          fontSize: "13px"
        }}
        onClick={() => history.push("/login")}
      >
        Masuk
      </button>
      <button
        className="font-bold py-2 px-5 border-2 border-transparent rounded-md text-green-700 hover:bg-white hover:text-gray-600 hover:border-green-500 hover:shadow-lg"
        style={{
          fontSize: "13px"
        }}
        onClick={() => history.push("/register")}
      >
        Daftar
      </button>
    </div>
  )
}

function MemberBtn() {
  return (
    <div className="relative flex space-x-2 mbr-btn">
      <button
        className="font-bold py-2 px-3 border-2 border-transparent rounded-md text-green-700 hover:bg-white hover:text-gray-600 hover:border-green-500 hover:shadow-lg"
        style={{
          fontSize: "13px"
        }}
      >
        <TiUser className="w-7 h-7" />
      </button>
      <div
        className={
          "mbr-drop absolute bg-white rounded-md border border-gray-300 shadow-xl flex right-0 mt-14 cart-drop text-gray-600"
        }
        style={{
          width: "300px",
          marginLeft: "-300px",
          zIndex: "800"
        }}
      >
        <MemberDropContent />
      </div>
    </div>
  )
}

const logOutServer = async () => {
  const response =
    await axios.post(window._HOST + "api/v1/auths/logout", {}, { withCredentials: true })

  return response.data
}

function MemberDropContent() {
  const dispatch = useDispatch()
  const history = useHistory()

  const { accountDetail } = useSelector(state => state.myAccount)

  function onLogOut() {
    dispatch(startLoading("Tunggu ya, sedang memproses..."))

    logOutServer()
      .then(response => {
        if (response.success) {
          dispatch(resetAccountDetail())
          dispatch(resetCartItems())
          history.push("/login")
        }
        else {

        }
      })
      .catch(errors => {

      })
      .finally(() => dispatch(stopLoading()))
  }

  return (
    <div
      className="py-2 w-full space-y-3"
      style={{
        // height: "200px"
      }}
    >
      <div
        className="px-5 font-bold text-left text-black"
        style={{ fontSize: "18px" }}
      >
        Halo, {accountDetail.nama_user}
      </div>
      <div className="flex flex-col">
        <button
          className="w-full px-5 py-2 hover:bg-gray-200 text-left"
          onClick={() => history.push("/my-account/orders")}
        >
          Pesanan Saya
        </button>
        <button
          className="w-full px-5 py-2 hover:bg-gray-200 text-left"
          onClick={() => history.push("/my-account")}
        >
          Akun Saya
        </button>
      </div>
      <div>
        <button
          className="w-full px-5 py-2 text-red-500 hover:bg-gray-200 text-left"
          onClick={onLogOut}
        >
          Keluar
        </button>
      </div>
    </div>
  )
}

function TopSegment() {
  const history = useHistory()

  const { systemProfile } = useSelector(state => state.global)
  const [topData, setTopData] = useState({
    fpLink: '',
    instagramLink: ''
  })

  useEffect(() => {
    const fp = systemProfile.find(d => d.key_ == 'fp')
    const ig = systemProfile.find(d => d.key_ == 'ig')

    setTopData({
      ...topData,
      fpLink: fp,
      instagramLink: ig
    })

  }, [systemProfile])

  return (
    <div
      className="flex justify-between text-gray-600"
    >
      <div className="flex space-x-3">
        <label
          style={{
            fontSize: "14px"
          }}
        >
          Ikuti kami di
        </label>
        <div className="flex space-x-1">
          <button className="hover:text-blue-500" onClick={() => window.open(topData.fpLink.value_, '_blank')}>
            <AiFillFacebook className="h-5 w-5" />
          </button>
          <button className="hover:text-red-500" onClick={() => window.open(topData.instagramLink.value_, '_blank')}>
            <AiFillInstagram className="h-5 w-5" />
          </button>
        </div>
      </div>
      <div className="flex items-center space-x-5">
        <button
          className="flex items-center space-x-1 hover:text-gray-200"
          style={{
            fontSize: "14px"
          }}
        >
          <label
            className="cursor-pointer"
            onClick={() => history.push("/about-us")}
          >
            Tentang Kami
          </label>
        </button>
        <button
          className="flex items-center space-x-1 hover:text-gray-200"
          style={{
            fontSize: "14px"
          }}
        >
          <label
            className="cursor-pointer"
            onClick={() => history.push("/contact-us")}
          >
            Kontak Kami
          </label>
        </button>
      </div>
    </div>
  )
}

function HeaderSearchBar() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { currentSearchKeyword } = useSelector(state => state.header)
  const { screenSize } = useSelector(state => state.global)

  function onSearch() {
    dispatch(setKeywordSearch(currentSearchKeyword))
    history.push("/products")
  }

  return (
    <>
      <div
        className="relative flex items-center bg-white border border-gray-400 rounded-sm"
        style={{
          width: 564,
          height: 40
        }}
      >
        <div
          className="cat-btn px-2 py-1 h-full flex items-center text-gray-600 border-r border-gray-200 hover:bg-blue-100 hover:text-blue-700"
        >
          <div className="flex cursor-pointer">
            Produk
            <FiChevronDown className="pt-1 h-5 w-5 ml-1" />
          </div>
          <div
            className={
              "cat-drop absolute inset-x-0 bg-white rounded-sm border border-gray-300 shadow-lg flex text-gray-600"
            }
            style={{
              top: "52px",
              zIndex: "800",
              width: screenSize.width,
              left: (-(screenSize.width / 2)) + 282 // 564/2 == 282
            }}
          >
            <CategoryDropContent />
          </div>
        </div>
        <input
          className="flex-1 px-2 py-1 h-full focus:outline-none text-gray-600"
          placeholder="Cari Buku di sini"
          style={{
            width: "400px",
          }}
          value={currentSearchKeyword}
          onChange={e => dispatch(setCurrentSearchKeyword(e.target.value))}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onSearch()
            }
          }}
        />
        <button
          className="py-1.5 px-5 h-full rounded-sm bg-green-500 text-white hover:bg-green-700 hover:shadow-lg"
          onClick={onSearch}
        >
          <AiOutlineSearch className="h-5 w-5" />
        </button>
      </div>
    </>
  )
}

function HeaderCart() {
  const { cartItems } = useSelector(state => state.cart)

  const [totalCartItemsQty, setTotalCartItemsQty] = useState(0)

  useEffect(() => {
    const totalQty = cartItems.reduce((acc, curr) => acc + curr.jumlah_barang, 0)
    setTotalCartItemsQty(totalQty)
  }, [cartItems])

  return (
    <>
      <div className="relative group cart-div">
        <button
          className="p-3 flex items-center space-x-3 rounded-lg hover:bg-white hover:bg-green-700 hover:text-white hover:shadow-lg relative"
        >
          <ImCart className={"h-6 w-6 "} />
          {
            totalCartItemsQty > 0 && (
              <div
                className="absolute bg-red-500 rounded-full top-0 right-0 text-white text-center"
                style={{
                  fontSize: "12px",
                  padding: totalCartItemsQty.toString().length > 1 ? "4px 6px" : "4px 10px"
                }}
              >
                {totalCartItemsQty}
              </div>
            )
          }
        </button>
        <div
          className={
            "py-3 absolute bg-white border border-gray-300 shadow-lg right-0 mt-3 cart-drop text-gray-600 rounded-lg"
          }
          style={{
            width: "400px",
            marginLeft: "-400px",
            zIndex: "800"
          }}
        >
          {cartItems.length > 0 ?
            (<UnEmptyCart />)
            :
            (<CartEmpty />)
          }
        </div>
      </div>
    </>
  )
}

function CartEmpty() {
  return (
    <div
      className="w-full flex items-center justify-center flex flex-col space-y-5"
      style={{
        height: "300px"
      }}
    >
      <div>
        <img
          className="rounded-xl shadow-md"
          src={(window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/empty_book_cart.png"}
          width={250}
        />
      </div>
      <label
        className="font-bold text-black"
        style={{ fontFamily: "Nunito" }}
      >
        Keranjang belanjamu masih kosong
      </label>
    </div>
  )
}

function UnEmptyCart() {
  const history = useHistory()
  const { cartItems } = useSelector(state => state.cart)

  function typeHasDisc(item, bookType) {
    if (bookType == 'full_color') {
      return item.potongan_harga_buku_full_color != "0%"
    }
    else if (bookType == 'dua_warna') {
      return item.potongan_harga_buku_dua_warna != "0%"
    }
    else if (bookType == 'satu_warna') {
      return item.potongan_harga_buku_dua_warna != "0%"
    }
    else if (bookType == 'digital') {
      return item.potongan_harga_buku_digital != "0%"
    }

    return false
  }

  return (
    <>
      <div
        className="w-full overflow-y-auto border-b divide-y divide-gray-200 space-y-2"
        style={{
          height: "300px"
        }}
      >
        {cartItems.map(i => (
          <div className="py-2 px-3 flex items-center justify-between">
            <div className="flex items-center">
              <img
                // src={"https://penerbitalc.com/fs-assets/uploads/item/2107070035/medium/img-21070700350.jpg?1636611543"}
                src={i.thumb_img_1}
                width="80px"
              />
              <div className="flex flex-col text-left ml-5 space-y-2" style={{ width: "230px" }}>
                <label
                  className="cursor-pointer font-bold hover:text-green-500" style={{ fontSize: "17px" }}
                >
                  {i.buku}
                </label>
                <label className="text-gray-600" style={{ fontSize: "14px" }}>
                  {i.tipe_buku == 'full_color' ? 'Full Color' : i.tipe_buku == 'dua_warna' ? 'Dua Warna' : i.tipe_buku == 'satu_warna' ? 'Hitam Putih' : 'Digital'} - {i.jumlah_barang} barang
                </label>
                <div className="flex items-center space-x-2">
                  <label
                    className={
                      "" + (typeHasDisc(i, i.tipe_buku) ? " line-through " : " font-bold ")
                    }
                    style={{ fontSize: "14px" }}
                  >
                    Rp {numToRupiahFormat(i.tipe_buku == 'full_color' ? i.harga_buku_full_color : i.tipe_buku == 'dua_warna' ? i.harga_buku_dua_warna : i.tipe_buku == 'satu_warna' ? i.harga_buku_satu_warna : i.harga_buku_digital)}
                  </label>
                  {typeHasDisc(i, i.tipe_buku) && (
                    <label
                      className={
                        "font-bold"
                      }
                      style={{ fontSize: "14px" }}
                    >
                      Rp {numToRupiahFormat(i.tipe_buku == 'full_color' ? i.harga_buku_full_color_with_promotion : i.tipe_buku == 'dua_warna' ? i.harga_buku_dua_warna_with_promotion : i.tipe_buku == 'satu_warna' ? i.harga_buku_satu_warna_with_promotion : i.harga_buku_digital_with_promotion)}
                    </label>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

      </div>
      <div className="p-2 px-3 flex justify-between" style={{ fontSize: "22px" }}>
        <label className="uppercase">total</label>
        <label className="font-bold">
          Rp {numToRupiahFormat(cartItems.reduce((acc, curr) => acc + curr.subtotal, 0))}
        </label>
      </div>
      <div className="p-2 px-3">
        <button
          className="w-full py-2 bg-green-600 text-white hover:bg-green-700 hover:shadow-lg"
          onClick={() => history.push("/cart")}
        >
          Lihat Keranjang
        </button>
      </div>
    </>
  )
}

function CategoryDropContent() {
  const [featuredCat, setFeaturedCat] = useState({
    selectedId: 0,
    data: [
      {
        id: 0,
        label: 'Sekolah',
      }
    ]
  })

  const [mapel, setMapel] = useState([
    {
      id: 0,
      label: 'Matematika'
    },
    {
      id: 0,
      label: 'Biologi'
    },
    {
      id: 0,
      label: 'Kimia'
    },
    {
      id: 0,
      label: 'Fisika'
    },
    {
      id: 0,
      label: 'Ekonomi'
    },
    {
      id: 0,
      label: 'Geologi'
    },
    {
      id: 0,
      label: 'Sosiologi'
    },
  ])

  const [jenjang, setJenjang] = useState([
    {
      id: 'smp',
      label: 'SMP/MTS',
      image: (window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/illustrasi-01.jpg",
      active: true,
    },
    {
      id: 'sma',
      label: 'SMA/MA',
      image: (window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/illustrasi-02.jpg",
      active: false
    },
  ])

  const [classes, setClass] = useState([
    {
      id: 0,
      label: 'Kelas 1',
      jenjang: 'sd'
    },
    {
      id: 1,
      label: 'Kelas 2',
      jenjang: 'sd'
    },
    {
      id: 2,
      label: 'Kelas 3',
      jenjang: 'sd'
    },
    {
      id: 3,
      label: 'Kelas 4',
      jenjang: 'sd'
    },
    {
      id: 4,
      label: 'Kelas 5',
      jenjang: 'sd'
    },
    {
      id: 5,
      label: 'Kelas 6',
      jenjang: 'sd'
    },
    {
      id: 6,
      label: 'Kelas 7',
      jenjang: 'smp'
    },
    {
      id: 7,
      label: 'Kelas 8',
      jenjang: 'smp'
    },
    {
      id: 8,
      label: 'Kelas 9',
      jenjang: 'smp'
    },
    {
      id: 9,
      label: 'Kelas 10',
      jenjang: 'sma'
    },
    {
      id: 10,
      label: 'Kelas 11',
      jenjang: 'sma'
    },
    {
      id: 11,
      label: 'Kelas 12',
      jenjang: 'sma'
    }
  ])


  function showMapelCat() {
    return (
      <div className="grid grid-cols-4 gap-x-8 gap-y-5">
        { mapel.map(m => (
          <label
            className="cursor-pointer text-left hover:text-green-600"
          >
            { m.label}
          </label>
        ))}
      </div>
    )
  }

  function showClassCat() {
    return (
      <div className="grid grid-cols-4 gap-x-8 gap-y-5">
        { classes.map(m => (
          <label
            className="cursor-pointer text-left hover:text-green-600"
          >
            { m.label}
          </label>
        ))}
      </div>
    )
  }

  function getActiveJenjang(jenjang_) {
    return jenjang_.find(j => j.active)
  }

  function jenjangContent() {
    return (
      <div
        className="flex space-x-5"
      >
        <div className="space-y-2">
          {jenjang.map(j => (
            <button
              className={
                "cursor-pointer p-2 hover:text-blue-700 font-bold flex justify-between items-center rounded-md " + (j.active ? " bg-blue-100 " : "")
              }
              style={{ width: "120px" }}
              onMouseEnter={() => setJenjang(jenjang.map(jx => jx.id == j.id ? ({ ...j, active: true }) : ({ ...jx, active: false })))}
            >
              <label className={"cursor-pointer " + (j.active ? " text-blue-700 " : "")} >{j.label}</label>
              <label className="cursor-pointer"><FiChevronRight className="h-5 w-5" /></label>
            </button>
          ))}
        </div>
        <div className="space-y-2" style={{ minHeight: "190px" }}>
          {getActiveJenjang(jenjang) && (
            <button
              className={
                "cursor-pointer p-2 px-3 hover:text-blue-700 hover:bg-blue-100 font-bold flex justify-between items-center rounded-md border "
              }
              style={{ width: "150px" }}
              onClick={() => window.open("/products/jenjang=" + getActiveJenjang(jenjang).id.toUpperCase(), "_self")}
            >
              Semua Kelas
            </button>
          )}
          {
            getActiveJenjang(jenjang) && classes.filter(c => c.jenjang == getActiveJenjang(jenjang).id).map(c => (
              <button
                className={
                  "cursor-pointer p-2 px-3 hover:text-blue-700 hover:bg-blue-100 font-bold flex justify-between items-center rounded-md border "
                }
                style={{ width: "150px" }}
                onClick={() => window.open("/products/kelas=" + (c.id + 1), "_self")}
              >
                {c.label}
              </button>
            ))
          }
        </div>
      </div>
    )
  }

  return (
    <div
      className="w-full divide-y divide-gray-200"
    >
      <div
        className="w-full flex justify-center"
      >
        <div
          className="container bg-white flex"
        >
          {featuredCat.data.map(d => (
            <button
              className={"py-2 px-6 uppercase border-b-2 border-transparent text-gray-500 " + (d.id == featuredCat.selectedId ? " text-blue-700 bg-blue-100 border-blue-700 " : " hover:text-green-500 hover:border-green-200")}
              onMouseEnter={() => {
                setFeaturedCat({ ...featuredCat, selectedId: null })
                setFeaturedCat({ ...featuredCat, selectedId: d.id })
              }}
            >
              {d.label}
            </button>
          ))}
        </div>
      </div>
      <div
        className="w-full  flex justify-center"
      >
        <div
          className="container pt-3 pb-5 bg-white flex"
        >
          {jenjangContent()}
          {/* {featuredCat.selectedId == 0 ?
            showMapelCat()
            :
            featuredCat.selectedId == 2 ?
              showClassCat()
              :
              (<div></div>)} */}
        </div>
      </div>
    </div>
  )
}

function CatDropBookPhisical() {
  const [datas, setDatas] = useState({
    selectedId: null,
    data: [
      {
        id: 0,
        label: 'Digital'
      },
      {
        id: 1,
        label: 'Full Color'
      },
      {
        id: 2,
        label: 'Digital'
      },
    ]
  })


  return (
    <div className="grid md:grid-col-3">

    </div>
  )
}
