import Header from "../../component/Header"
import Footer from "../../component/Footer/Footer"
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai"
import { FaTrash } from "react-icons/fa"
import axios from "axios"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setItems } from "./CartSlice"
import { RiLoader5Fill } from "react-icons/ri"
import { startLoading, stopLoading } from "../../component/Loading/LoadingSlice"
import { useHistory } from "react-router"
import { numToRupiahFormat } from '../../util/currency'
import ErrorDialog from "../../component/ErrorDialog"

const getPendingCartServer = async () => {
  const finalUrl =
    window._HOST + "api/v1/user/carts/"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

const updateQtyItemServer = async (payload) => {
  const finalUrl =
    window._HOST + "api/v1/user/carts/"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

const deleteCartItemServer = async (payload) => {
  const finalUrl =
    window._HOST + "api/v1/user/carts/cartitem/"

  const response =
    await axios.delete(finalUrl, { withCredentials: true, data: payload })

  return response.data
}

export default function CartPage() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoad, setIsLoad] = useState(false)

  const { screenSize } = useSelector(state => state.global)
  const { cartItems } = useSelector(state => state.cart)
  const { accountDetail } = useSelector(state => state.myAccount)

  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    errorMessage: ''
  })
  const openErrorDialog = (errMsg, errD) => ({ ...errD, isOpen: true, errorMessage: errMsg })
  const closeErrorDialog = (errD) => ({ ...errD, isOpen: false, errorMessage: '' })

  function getPendingCart() {
    setIsLoad(true)

    getPendingCartServer()
      .then(response => {
        if (response.success) {
          const cartItems = response.data
          dispatch(setItems(cartItems))
        }
        else {
          const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
          setErrorDialog(openErrorDialog(errorMsg, errorDialog))
        }
      })
      .catch(errors => {
        setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
      })
      .finally(() => setIsLoad(false))
  }

  useEffect(() => {
    if (accountDetail == null) {
      return history.push("/login")
    }
    getPendingCart()
    window.scrollTo(0, 0)
  }, [])

  function updateQty(cartItemId, newQty) {
    const payload = {
      id_buku_keranjang: cartItemId,
      jumlah_buku: newQty
    }

    dispatch(startLoading("Tunggu ya, sedang update keranjang..."))

    updateQtyItemServer(payload)
      .then(response => {
        if (response.success) {
          const updated = cartItems.map(i => {
            if (i.id_buku_keranjang == cartItemId) {
              const price =
                i.tipe_buku == 'full_color' ?
                  i.harga_buku_full_color_with_promotion
                  :
                  i.tipe_buku == 'dua_warna' ?
                    i.harga_buku_dua_warna_with_promotion
                    :
                    i.tipe_buku == 'satu_warna' ?
                      i.harga_buku_satu_warna_with_promotion
                      :
                      i.harga_buku_digital_with_promotion

              return { ...i, jumlah_barang: newQty, subtotal: price * newQty }
            }

            return i
          })

          dispatch(setItems(updated))
        }
        else {
          const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
          setErrorDialog(openErrorDialog(errorMsg, errorDialog))
        }
      })
      .catch(errors => {
        setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
      })
      .finally(() => dispatch(stopLoading()))
  }

  function deleteCartItem(cartItemId) {
    const payload = {
      id_buku_keranjang: cartItemId
    }

    dispatch(startLoading("Tunggu ya, sedang update keranjang..."))

    deleteCartItemServer(payload)
      .then(response => {
        if (response.success) {
          const updated = cartItems.filter(i => i.id_buku_keranjang != cartItemId)

          dispatch(setItems(updated))
        }
        else {
          const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
          setErrorDialog(openErrorDialog(errorMsg, errorDialog))
        }
      })
      .catch(errors => {
        setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
      })
      .finally(() => dispatch(stopLoading()))
  }

  return (
    <>
      <ErrorDialog
        isShow={errorDialog.isOpen}
        errorMessage={errorDialog.errorMessage}
        onClose={() => setErrorDialog(closeErrorDialog(errorDialog))}
      />
      <Header />
      <div
        className="w-full py-2 flex justify-center"
        style={{
          paddingTop: screenSize.width > 500 ? "120px" : "10px",
        }}
      >
        <div className="container bg-white">

          <div
            className={
              "font-bold text-left " +
              (screenSize.width > 500 ? " mb-5 " : " px-8 ")
            }
            style={{ fontSize: "22px" }}
          >
            Keranjang Belanja
          </div>
          <div
            className={
              "flex " +
              (screenSize.width > 500 ? " justify-between " : " flex-col ")
            }
          >
            {
              isLoad ?
                (
                  <div className="h-full w-full flex items-center justify-center text-blue-500 py-10">
                    <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
                  </div>
                )
                :
                cartItems.length > 0 ?
                  (
                    <>
                      <div className="flex-1 p-5 rounded-md space-y-2">
                        {cartItems.map(c => (
                          <CartItem
                            item={c}
                            onUpdateQty={updateQty}
                            onDeleteItem={deleteCartItem}
                          />
                        ))}
                      </div>
                      <div>
                        <CartSummary cartItems={cartItems} />
                      </div>
                    </>
                  )
                  :
                  (
                    <div
                      className="w-full flex items-center justify-center flex flex-col space-y-5"
                      style={{
                        height: "300px"
                      }}
                    >
                      <div>
                        <img className="rounded-xl shadow-md" src={(window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/empty_book_cart.png"} width={250} />
                      </div>
                      <label
                        className="font-bold text-black"
                        style={{ fontFamily: "Nunito" }}
                      >
                        Keranjang belanjamu masih kosong
                      </label>
                    </div>
                  )
            }

          </div>

        </div>
      </div>
      <Footer />
    </>
  )
}

function CartItem({
  item,
  onUpdateQty,
  onDeleteItem
}) {
  const { screenSize } = useSelector(state => state.global)

  function typeHasDisc(bookType) {
    if (bookType == 'full_color') {
      return item.potongan_harga_buku_full_color != "0%"
    }
    else if (bookType == 'dua_warna') {
      return item.potongan_harga_buku_dua_warna != "0%"
    }
    else if (bookType == 'satu_warna') {
      return item.potongan_harga_buku_satu_warna != "0%"
    }
    else if (bookType == 'digital') {
      return item.potongan_harga_buku_digital != "0%"
    }

    return false
  }

  //https://penerbitalc.com/fs-assets/uploads/item/2107070035/medium/img-21070700350.jpg?1636611543
  return (
    <div
      className={
        screenSize.width > 500 ?
          "items-center border border-gray-300 rounded-md p-3 px-8"
          :
          "border border-gray-300 rounded-md p-3 px-8"
      }
    >
      <div className="flex items-center mb-2 ">
        <img
          src={item.thumb_img_1}
          width="100px"
        />
        <div className="flex flex-col text-left ml-5 space-y-2" style={{ width: "230px" }}>
          <label className="font-bold" style={{ fontSize: "17px" }}>{item.buku}</label>
          <label className="text-gray-600">
            {item.tipe_buku == 'full_color' ? 'Full Color' : item.tipe_buku == 'dua_warna' ? 'Dua Warna' : item.tipe_buku == 'satu_warna' ? 'Hitam Putih' : 'Digital'} - {item.jumlah_barang} barang
          </label>
          <div className="flex items-center space-x-2">
            <label
              className={
                "text-gray-600 " + (typeHasDisc(item.tipe_buku) ? " line-through " : "")
              }
            >
              Rp {
                item.tipe_buku == 'full_color' ?
                  numToRupiahFormat(item.harga_buku_full_color)
                  :
                  item.tipe_buku == 'dua_warna' ?
                    numToRupiahFormat(item.harga_buku_dua_warna)
                    :
                    item.tipe_buku == 'satu_warna' ?
                      numToRupiahFormat(item.harga_buku_satu_warna)
                      :
                      numToRupiahFormat(item.harga_buku_digital)
              }
            </label>
            {typeHasDisc(item.tipe_buku) && (
              <label className="text-gray-600">
                Rp {
                  item.tipe_buku == 'full_color' ?
                    numToRupiahFormat(item.harga_buku_full_color_with_promotion)
                    :
                    item.tipe_buku == 'dua_warna' ?
                      numToRupiahFormat(item.harga_buku_dua_warna_with_promotion)
                      :
                      item.tipe_buku == 'satu_warna' ?
                        numToRupiahFormat(item.harga_buku_satu_warna_with_promotion)
                        :
                        numToRupiahFormat(item.harga_buku_digital_with_promotion)
                }
              </label>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-between py-3 border-t border-b">
        <div className="text-left" style={{ fontFamily: "Inter", fontFamily: "12px" }}>
          Jumlah Barang
        </div>
        <div className="flex">
          {
            item.tipe_buku != 'digital' && (
              <button
                className="text-blue-500 hover:text-blue-700"
                onClick={() => {
                  if (item.jumlah_barang > 1) {
                    onUpdateQty(item.id_buku_keranjang, item.jumlah_barang - 1)
                  }
                }}
              >
                <AiFillMinusCircle className="h-6 w-6" />
              </button>
            )
          }

          <label
            style={{ minWidth: "60px" }}
          >
            {item.jumlah_barang}
          </label>
          {item.tipe_buku != 'digital' && (
            <button
              className="text-blue-500 hover:text-blue-700"
              onClick={() => onUpdateQty(item.id_buku_keranjang, item.jumlah_barang + 1)}
            >
              <AiFillPlusCircle className="h-6 w-6" />
            </button>
          )}

        </div>

      </div>

      <div className=" py-3 flex justify-end space-y-3">
        <button
          className="flex items-center text-gray-600 hover:text-red-700" style={{ fontSize: "14px" }}
          onClick={() => onDeleteItem(item.id_buku_keranjang)}
        >
          <FaTrash className="mr-2" /> Hapus
                </button>
      </div>
      <div className="py-3 flex justify-between items-center border-t">
        <label>Subtotal</label>
        <label>Rp {numToRupiahFormat(item.subtotal)}</label>
      </div>

    </div>
  )
}

function CartSummary({
  cartItems
}) {
  const history = useHistory()

  const { screenSize } = useSelector(state => state.global)

  return (
    <div
      className={
        "p-5 " +
        (screenSize.width > 500 ? " space-y-5 rounded-md shadow-lg " : " space-y-3 px-8 ")
      }
      style={{ width: screenSize.width > 500 ? "400px" : screenSize.width }}
    >
      <div className="py-2 font-bold text-left" style={{ fontSize: "20px" }}>
        Total Keranjang
      </div>
      <div className="flex justify-between">
        <label className="font-bold">Total</label>
        <label className="font-bold text-blue-700">
          Rp {numToRupiahFormat(cartItems.reduce((acc, curr) => acc + curr.subtotal, 0))}
        </label>
      </div>
      <div>
        <button
          className="py-2 w-full rounded-full bg-blue-600 text-white hover:bg-blue-700"
          onClick={() => history.push("/checkout")}
        >
          Lanjut Pembayaran
        </button>
      </div>
    </div>
  )
}