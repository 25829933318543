import { AiOutlineClose } from "react-icons/ai"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { closeSideCartMobile } from "../../app/GlobalSlice"
import { numToRupiahFormat } from '../../util/currency'

export default function SideCart() {
  const dispatch = useDispatch()
  const history = useHistory()

  const { sideCartMobile, screenSize } = useSelector(state => state.global)

  const { cartItems } = useSelector(state => state.cart)

  function typeHasDisc(item, bookType) {
    if (bookType == 'full_color') {
      return item.potongan_harga_buku_full_color != "0%"
    }
    else if (bookType == 'dua_warna') {
      return item.potongan_harga_buku_dua_warna != "0%"
    }
    else if (bookType == 'digital') {
      return item.potongan_harga_buku_digital != "0%"
    }

    return false
  }

  return (
    <>
      { sideCartMobile.isOpen && (
        <div
          className="fixed top-0 w-full h-full z-10 bg-black bg-opacity-60"
          onClick={() => dispatch(closeSideCartMobile())}
        >
        </div>
      )}
      <div
        className={
          "fixed inset-y-0 right-0 overflow-y-auto transform transition duration-500 ease-in-out shadow-lg bg-white border-l border-gray-300 " +
          (sideCartMobile.isOpen ? "" : " translate-x-full")
        }
        style={{
          zIndex: "999",
          width: "280px",
          height: "100%"
        }}
      >
        <div style={{ height: screenSize.width < 768 ? "70px" : "52px" }} className="border-b border-gray-200 flex items-center px-2 relative">
          <button
            className="p-2 rounded-md bg-red-500 text-white shadow-lg"
            onClick={() => dispatch(closeSideCartMobile())}
          >
            <AiOutlineClose className="h-6 w-6" />
          </button>
          <label className="absolute right-0 pr-2 " style={{ fontFamily: "Inter", fontSize: "18px" }}>
            Keranjang Belanja
          </label>
        </div>
        <div
          className="overflow-y-auto"
          style={{
            height: (screenSize.height - (70 + 103)) + "px"
          }}
        >
          {cartItems.map(i => (
            <div className="py-2 px-3 flex items-center justify-between">
              <div className="flex items-center">
                <img
                  // src={"https://penerbitalc.com/fs-assets/uploads/item/2107070035/medium/img-21070700350.jpg?1636611543"}
                  src={i.thumb_img_1}
                  width="80px"
                />
                <div className="flex flex-col text-left ml-5 space-y-2" style={{ width: "230px" }}>
                  <label
                    className="cursor-pointer font-bold hover:text-green-500" style={{ fontSize: "17px" }}
                  >
                    {i.buku}
                  </label>
                  <label className="text-gray-600" style={{ fontSize: "14px" }}>
                    {i.tipe_buku == 'full_color' ? 'Full Color' : i.tipe_buku == 'dua_warna' ? 'Dua Warna' : 'Digital'} - {i.jumlah_barang} barang</label>
                  <label
                    className={
                      "" + (typeHasDisc(i, i.tipe_buku) ? " line-through " : " font-bold ")
                    }
                    style={{ fontSize: "14px" }}
                  >
                    Rp {numToRupiahFormat(i.tipe_buku == 'full_color' ? i.harga_buku_full_color : i.tipe_buku == 'dua_warna' ? i.harga_buku_dua_warna : i.harga_buku_digital)}
                  </label>
                  {typeHasDisc(i, i.tipe_buku) && (
                    <label
                      className={
                        "font-bold"
                      }
                      style={{ fontSize: "14px" }}
                    >
                      Rp {numToRupiahFormat(i.tipe_buku == 'full_color' ? i.harga_buku_full_color_with_promotion : i.tipe_buku == 'dua_warna' ? i.harga_buku_dua_warna_with_promotion : i.harga_buku_digital_with_promotion)}
                    </label>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full absolute bottom-0" style={{ height: "103px" }}>
          <div className="border-t border-gray-300">
            <div className="p-2 px-3 flex justify-between" style={{ fontSize: "18px" }}>
              <label className="uppercase">total</label>
              <label className="font-bold">
                Rp {numToRupiahFormat(cartItems.reduce((acc, curr) => acc + curr.subtotal, 0))}
              </label>
            </div>
          </div>
          <div className=" p-2 px-3">
            <button
              className="w-full py-2 bg-green-600 text-white hover:bg-green-700 hover:shadow-lg"
              onClick={() => {
                history.push("/cart")
                dispatch(closeSideCartMobile())
              }}
            >
              Lihat Keranjang
            </button>
          </div>
        </div>
      </div>
    </>
  )
}