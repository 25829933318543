import Header from "../../component/Header"
import Footer from "../../component/Footer/Footer"
import { AiOutlineMinus, AiOutlinePlus, AiOutlineStop } from "react-icons/ai"
import { FaCartPlus } from "react-icons/fa"
import { useHistory, useParams } from "react-router"
import { useEffect, useRef, useState } from "react"
import axios from "axios"
import { BsCheckCircle } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { addItem } from "../Cart/CartSlice"
import { startLoading, stopLoading } from "../../component/Loading/LoadingSlice"
import SuccessAddToCart from "./comp/SuccessAddToCart"
import { RiLoader5Fill } from "react-icons/ri"
import { numToRupiahFormat } from '../../util/currency'
import { openMacaDialog } from "../../app/GlobalSlice"
import ErrorDialog from "../../component/ErrorDialog"

const fetchBookDetail = async (bookId) => {
  const finalUrl =
    window._HOST + "api/v1/admin/books/detail?id_buku=" + bookId

  const response =
    await axios.get(finalUrl)

  return response.data
}

const addToCartServer = async (payload) => {
  const finalUrl =
    window._HOST + "api/v1/user/carts"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export default function ProductDetailPage() {
  const dispatch = useDispatch()
  const history = useHistory()

  let { id } = useParams()

  const { screenSize } = useSelector(state => state.global)
  const { accountDetail } = useSelector(state => state.myAccount)

  const [isLoad, setIsLoad] = useState(false)
  const [qty, setQty] = useState(1)
  const [selectedVersion, setSelectedVersion] = useState(null) //full_color, dua_warna, digital
  const [bookDetail, setBookDetail] = useState(null)
  const [subtotal, setSubTotal] = useState(null)
  const [selectedImg, setSelectedImg] = useState(null)
  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    errorMsg: ''
  })

  const [isSuccessAddOpen, setIsSuccessAddOpen] = useState(false)

  const bookRef = useRef()

  function getBookDetail() {
    setIsLoad(true)

    fetchBookDetail(id)
      .then(response => {
        if (response.success) {
          setBookDetail(response.data)
          
          if (response.data.harga_buku_full_color > 0) {
            setSelectedVersion('full_color')
          }
          else if (response.data.harga_buku_dua_warna > 0) {
            setSelectedVersion('dua_warna')
          }
          else {
            setSelectedVersion('digital')
          }

          if (response.data != null) {
            setSelectedImg(response.data.thumb_img_1.replace("_thumb", ""))
          }
        }
      })
      .catch()
      .finally(() => setIsLoad(false))

  }

  useEffect(() => {
    window.scrollTo(0, 0)

    getBookDetail()
  }, [])

  useEffect(() => {
    if (selectedVersion == 'digital') {
      setQty(1)
    }
  }, [selectedVersion])

  useEffect(() => {
    if (bookDetail == null) return;

    const price =
      selectedVersion == 'full_color' ?
        bookDetail.harga_buku_full_color_with_promotion
        :
        selectedVersion == 'dua_warna' ?
          bookDetail.harga_buku_dua_warna_with_promotion
          :
          selectedVersion == 'satu_warna' ?
            bookDetail.harga_buku_satu_warna_with_promotion
            :
            bookDetail.harga_buku_digital_with_promotion

    setSubTotal(qty * price)

  }, [qty, selectedVersion, bookDetail])

  /**
   * Proses Add to Cart
   *  - jika user belum login maka alihkan dahulu ke halaman login 
   */
  function addToCart() {
    const payload = {
      ...bookDetail,
      tipe_buku: selectedVersion,
      jumlah_barang: qty,
      subtotal: subtotal,
      notAddedYet: accountDetail == null
    }

    if (accountDetail == null) {
      dispatch(addItem(payload))
      history.push("/login")
    }
    else {

      const payloadReq = {
        id_buku: payload.id_buku,
        tipe_buku: payload.tipe_buku,
        jumlah_buku: payload.jumlah_barang
      }

      dispatch(startLoading("Tunggu ya, sedang memproses..."))

      addToCartServer(payloadReq)
        .then(response => {
          if (response.success) {
            dispatch(addItem(payload))
            setIsSuccessAddOpen(true)
          }
          else {
            const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
            setErrorDialog({ ...errorDialog, isOpen: true, errorMsg: errorMsg })
          }
        })
        .catch(errors => {
          setErrorDialog({ ...errorDialog, isOpen: true, errorMsg: 'Fatal Error' })
        })
        .finally(() => dispatch(stopLoading()))

    }
  }

  return (
    <>
      <ErrorDialog
        isShow={errorDialog.isOpen}
        errorMessage={errorDialog.errorMsg}
        onClose={() => {
          setErrorDialog({ ...errorDialog, isOpen: false, errorMsg: '' })
        }}
      />
      <SuccessAddToCart
        isShow={isSuccessAddOpen}
        bookDetail={bookDetail}
        orderDetail={{
          selectedVersion: selectedVersion,
          jmlBarang: qty,
          subtotal: subtotal
        }}
        onClose={() => setIsSuccessAddOpen(false)}
      />
      <Header />
      <div
        className="w-full py-2 flex justify-center"
        style={{
          paddingTop: screenSize.width > 500 ? "100px" : "10px",
        }}
      >
        {
          isLoad ?
            (
              <div className="h-full w-full flex items-center justify-center text-blue-500">
                <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
              </div>
            )
            :
            (
              <>
                <div className="my-5 py-2 container bg-white space-y-10">
                  <div
                    className={
                      "flex " +
                      (screenSize.width > 500 ? "" : " flex-col ")
                    }
                  >
                    <div className="space-y-8 space-x-2" style={{ width: screenSize.width > 500 ? "30%" : "100%" }}>
                      <div className="w-full flex justify-center">
                        <img
                          src={selectedImg}
                          width="90%"
                        />
                      </div>
                      <div className="flex justify-center space-x-3">
                        <img
                          className="cursor-pointer"
                          src={bookDetail != null ? bookDetail.thumb_img_1 : '-'}
                          width="80px"
                          onMouseEnter={() => setSelectedImg(bookDetail.thumb_img_1.replace("_thumb", ""))}
                        />
                        <img
                          className="cursor-pointer"
                          src={bookDetail != null ? bookDetail.thumb_img_2 : '-'}
                          onMouseEnter={() => setSelectedImg(bookDetail.thumb_img_2.replace("_thumb", ""))}
                          width="80px"
                        />
                        <img
                          className="cursor-pointer"
                          src={bookDetail != null ? bookDetail.thumb_img_3 : '-'}
                          onMouseEnter={() => setSelectedImg(bookDetail.thumb_img_3.replace("_thumb", ""))}
                          width="80px"
                        />
                      </div>
                    </div>
                    <div
                      className={
                        "py-5 text-left space-y-5 " +
                        (screenSize.width > 500 ? " pl-2 pr-6 " : " px-5 ")
                      }
                      style={{ width: screenSize.width > 500 ? "45%" : "100%" }}
                    >
                      {bookDetail && bookDetail.catatan && bookDetail.catatan != '' && (
                        <div
                          className="border border-gray-400 rounded-md p-2 space-y-2"
                        >
                          <div
                            className="bg-red-500 text-white p-2 rounded-sm font-bold"
                          >
                            Penting! Mohon dibaca
                          </div>
                          <div className="text-justify">
                            <span dangerouslySetInnerHTML={{ __html: bookDetail.catatan }}/>
                          </div>
                          
                        </div>
                      )}
                      <div
                        className="font-bold"
                        style={{
                          fontSize: "20px"
                        }}
                      >
                        {bookDetail != null ? bookDetail.buku : '-'}
                      </div>
                      <div
                        className={
                          "text-gray-700 "
                        }
                        style={{ fontSize: "15px" }}
                      >
                        <div className="space-x-2">
                          <label className="inline-block w-20">Kode Buku</label>
                          <label>:</label>
                          <label className="text-green-800">{bookDetail != null ? bookDetail.id_buku : '-'}</label>
                        </div>
                        <div className="space-x-2">
                          <label className="inline-block w-20">Kategori</label>
                          <label>:</label>
                          <label className="text-green-800">{bookDetail != null ? bookDetail.kategori.kategori : '-'}</label>
                        </div>
                        <div className="space-x-2">
                          <label className="inline-block w-20">Penerbit</label>
                          <label>:</label>
                          <label className="text-green-800">{bookDetail != null ? bookDetail.penerbit : '-'}</label>
                        </div>
                        <div className="space-x-2">
                          <label className="inline-block w-20">ISBN</label>
                          <label>:</label>
                          <label className="text-green-800">{bookDetail != null ? bookDetail.isbn : '-'}</label>
                        </div>
                        <div className="space-x-2">
                          <label className="inline-block w-20">Berat</label>
                          <label>:</label>
                          <label className="text-green-800">{bookDetail != null ? bookDetail.berat_buku + " gr" : '-'}</label>
                        </div>
                        <div className="space-x-2">
                          <label className="inline-block w-20">Tahun terbit</label>
                          <label>:</label>
                          <label className="text-green-800">{bookDetail != null ? bookDetail.tahun_terbit : '-'}</label>
                        </div>
                      </div>
                      <div
                        className="font-bold text-red-600 flex flex-col space-y-1"
                        style={{
                          fontSize: "18px"
                        }}
                      >
                        {bookDetail && bookDetail.harga_buku_full_color_with_promotion > 0 && (
                          <div className="p-1 px-5 flex flex-col rounded-md border border-gray-300">
                            <label className="text-gray-600" style={{ fontSize: "14px" }}>Buku Cetak (Full Color)</label>
                            <div className="flex items-center space-x-2">
                              <label
                                className={
                                  bookDetail != null ?
                                    bookDetail.potongan_harga_buku_full_color != "0%" ?
                                      "line-through text-black"
                                      :
                                      ""
                                    :
                                    ""
                                }
                                style={{
                                  fontSize: bookDetail != null && bookDetail.potongan_harga_buku_full_color != "0%" ? "13px" : "18px"
                                }}
                              >
                                Rp {bookDetail != null ? numToRupiahFormat(bookDetail.harga_buku_full_color) : '-'}
                              </label>
                              {bookDetail != null && bookDetail.potongan_harga_buku_full_color != "0%" && (
                                <label className="font-bold">
                                  Rp {numToRupiahFormat(bookDetail.harga_buku_full_color_with_promotion)}
                                </label>
                              )}
                            </div>
                          </div>
                        )}
                        {bookDetail && bookDetail.harga_buku_dua_warna_with_promotion > 0 && (
                          <div className="p-1 px-5 flex flex-col rounded-md border border-gray-300">
                            <label className="text-gray-600" style={{ fontSize: "14px" }}>Buku Cetak (Dua Warna)</label>
                            <div className="flex items-center space-x-2">
                              <label
                                className={
                                  bookDetail != null ?
                                    bookDetail.potongan_harga_buku_dua_warna != "0%" ?
                                      "line-through text-black"
                                      :
                                      ""
                                    :
                                    ""
                                }
                                style={{
                                  fontSize: bookDetail != null && bookDetail.potongan_harga_buku_dua_warna != "0%" ? "13px" : "18px"
                                }}
                              >
                                Rp {bookDetail != null ? numToRupiahFormat(bookDetail.harga_buku_dua_warna) : '-'}
                              </label>
                              {bookDetail != null && bookDetail.potongan_harga_buku_dua_warna != "0%" && (
                                <label className="font-bold">
                                  Rp {numToRupiahFormat(bookDetail.harga_buku_dua_warna_with_promotion)}
                                </label>
                              )}
                            </div>
                          </div>
                        )}
                        {bookDetail && bookDetail.harga_buku_satu_warna_with_promotion > 0 && (
                          <div className="p-1 px-5 flex flex-col rounded-md border border-gray-300">
                            <label className="text-gray-600" style={{ fontSize: "14px" }}>Buku Cetak (Hitam Putih)</label>
                            <div className="flex items-center space-x-2">
                              <label
                                className={
                                  bookDetail != null ?
                                    bookDetail.potongan_harga_buku_satu_warna != "0%" ?
                                      "line-through text-black"
                                      :
                                      ""
                                    :
                                    ""
                                }
                                style={{
                                  fontSize: bookDetail != null && bookDetail.potongan_harga_buku_satu_warna != "0%" ? "13px" : "18px"
                                }}
                              >
                                Rp {bookDetail != null ? numToRupiahFormat(bookDetail.harga_buku_satu_warna) : '-'}
                              </label>
                              {bookDetail != null && bookDetail.potongan_harga_buku_satu_warna != "0%" && (
                                <label className="font-bold">
                                  Rp {numToRupiahFormat(bookDetail.harga_buku_satu_warna_with_promotion)}
                                </label>
                              )}
                            </div>
                          </div>
                        )}
                        {bookDetail && bookDetail.harga_buku_digital_with_promotion > 0 && (
                          <div className="p-1 px-5 flex flex-col rounded-md border border-gray-300">
                            <label className="text-gray-600" style={{ fontSize: "14px" }}>Buku Digital</label>
                            <div className="flex items-center space-x-2">
                              <label
                                className={
                                  bookDetail != null ?
                                    bookDetail.potongan_harga_buku_digital != "0%" ?
                                      "line-through text-black"
                                      :
                                      ""
                                    :
                                    ""
                                }
                                style={{
                                  fontSize: bookDetail != null && bookDetail.potongan_harga_buku_digital != "0%" ? "13px" : "18px"
                                }}
                              >
                                Rp {bookDetail != null ? numToRupiahFormat(bookDetail.harga_buku_digital) : '-'}
                              </label>
                              {bookDetail != null && bookDetail.potongan_harga_buku_digital != "0%" && (
                                <label className="font-bold">
                                  Rp {numToRupiahFormat(bookDetail.harga_buku_digital_with_promotion)}
                                </label>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="text-gray-700 text-justify">
                        <p className="">
                          <span dangerouslySetInnerHTML={{ __html: bookDetail != null ? bookDetail.keterangan.substring(0, 350) : "-" }}>
                          </span>
                          <span
                            className="cursor-pointer text-green-700 hover:text-green-600 font-bold underline"
                            onClick={() => bookRef.current.scrollIntoView()}
                          >
                            lebih banyak
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      className={
                        screenSize.width > 500 ? "pr-5" : "px-5"
                      }
                      style={{ width: screenSize.width > 500 ? "25%" : "100%" }}
                    >
                      <div className="pb-2">
                        <button
                          className="w-full p-2 rounded-md border bg-blue-500 text-white hover:bg-blue-700"
                          style={{ fontFamily: "Nunito" }}
                          onClick={() => dispatch(openMacaDialog())}
                        >
                          Lihat Sampel di aplikasi Syibook
                        </button>
                      </div>
                      <div className="w-full space-y-4 p-2 px-3 pb-5 rounded-md border border-gray-400 shadow-lg">
                        {selectedVersion != 'digital' && (
                          <div className="text-gray-700 space-y-3">
                            <div className="pb-2 border-b border-gray-200 font-bold">Mau Beli Berapa?</div>
                            <div className="flex">
                              <button
                                className="px-3 py-2 border border-gray-400 hover:bg-green-500 hover:text-white"
                                onClick={() => {
                                  if (qty > 1) {
                                    setQty(qty - 1)
                                  }
                                }}
                              >
                                <AiOutlineMinus />
                              </button>
                              <input
                                // type="number"
                                className="px-2 w-full text-center border-t border-b border-gray-400 outline-none"
                                value={qty}
                                readOnly
                              />
                              <button
                                className="px-3 py-2 border border-gray-400 hover:bg-green-500 hover:text-white"
                                onClick={() => {
                                  setQty(qty + 1)
                                }}
                              >
                                <AiOutlinePlus />
                              </button>
                            </div>
                          </div>
                        )}
                        <div className="text-gray-700 space-y-3">
                          <div className="pb-2 border-b border-gray-200 font-bold">Mau Cetak atau Digital?</div>
                          <div className="flex flex-col space-y-1">
                            {bookDetail && bookDetail.harga_buku_full_color_with_promotion > 0 && (
                              <button
                                className={
                                  "text-left w-full flex items-center py-2 px-2 rounded-md " +
                                  (selectedVersion == 'full_color' ? ' bg-green-600 text-white ' : ' hover:bg-green-200 border border-gray-300 ')
                                }
                                onClick={() => {
                                  if (selectedVersion != 'full_color') setSelectedVersion('full_color')
                                }}
                              >
                                {selectedVersion == 'full_color' && (<BsCheckCircle className="h-5 w-5 mr-2" />)}
                                Buku Cetak (Full Color)
                              </button>
                            )}
                            {bookDetail && bookDetail.harga_buku_dua_warna_with_promotion > 0 && (
                              <button
                                className={
                                  "text-left w-full flex items-center py-2 px-2 rounded-md " +
                                  (selectedVersion == 'dua_warna' ? ' bg-green-600 text-white ' : ' hover:bg-green-200 border border-gray-300 ')
                                }
                                onClick={() => {
                                  if (selectedVersion != 'dua_warna') setSelectedVersion('dua_warna')
                                }}
                              >
                                {selectedVersion == 'dua_warna' && (<BsCheckCircle className="h-5 w-5 mr-2" />)}
                              Buku Cetak (Dua Warna)
                              </button>
                            )}
                            {bookDetail && bookDetail.harga_buku_satu_warna_with_promotion > 0 && (
                              <button
                                className={
                                  "text-left w-full flex items-center py-2 px-2 rounded-md " +
                                  (selectedVersion == 'satu_warna' ? ' bg-green-600 text-white ' : ' hover:bg-green-200 border border-gray-300 ')
                                }
                                onClick={() => {
                                  if (selectedVersion != 'satu_warna') setSelectedVersion('satu_warna')
                                }}
                              >
                                {selectedVersion == 'satu_warna' && (<BsCheckCircle className="h-5 w-5 mr-2" />)}
                              Buku Cetak (Hitam Putih)
                              </button>
                            )}
                            {bookDetail && bookDetail.harga_buku_digital_with_promotion > 0 && (
                              <button
                                className={
                                  "text-left w-full flex items-center py-2 px-2 rounded-md " +
                                  (selectedVersion == 'digital' ? ' bg-green-600 text-white ' : ' hover:bg-green-200 border border-gray-300 ')
                                }
                                onClick={() => {
                                  if (selectedVersion != 'digital') setSelectedVersion('digital')
                                }}
                              >
                                {selectedVersion == 'digital' && (<BsCheckCircle className="h-5 w-5 mr-2" />)}
                              Buku Digital
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="my-2 border-t-2 border-b-2 py-2 space-y-2">
                          <div className="flex items-center justify-between px-2" style={{ fontSize: "14px" }}>
                            <label>Subtotal</label>
                            <label style={{ fontWeight: "700" }}>
                              Rp {
                                bookDetail && selectedVersion == 'full_color' ?
                                  numToRupiahFormat(qty * bookDetail.harga_buku_full_color)
                                  :
                                  bookDetail && selectedVersion == 'dua_warna' ?
                                    numToRupiahFormat(qty * bookDetail.harga_buku_dua_warna)
                                    :
                                    bookDetail && selectedVersion == 'satu_warna' ?
                                      numToRupiahFormat(qty * bookDetail.harga_buku_satu_warna)
                                      :
                                      bookDetail && selectedVersion == 'digital' ?
                                        numToRupiahFormat(qty * bookDetail.harga_buku_digital)
                                        :
                                        ''
                              }
                            </label>
                          </div>
                          <div className="flex items-center justify-between px-2" style={{ fontSize: "14px" }}>
                            <label>Diskon</label>
                            <label style={{ fontWeight: "700" }}>
                              {
                                bookDetail && selectedVersion == 'full_color' ?
                                  bookDetail.potongan_harga_buku_full_color
                                  :
                                  bookDetail && selectedVersion == 'dua_warna' ?
                                    bookDetail.potongan_harga_buku_dua_warna
                                    :
                                    bookDetail && selectedVersion == 'satu_warna' ?
                                      bookDetail.potongan_harga_buku_satu_warna
                                      :
                                      bookDetail && selectedVersion == 'digital' ?
                                        bookDetail.potongan_harga_buku_digital
                                        :
                                        ''
                              }
                            </label>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <div className="flex items-end text-gray-600">
                            Total
                          </div>
                          <div className="font-bold" style={{ fontSize: "22px" }}>
                            Rp {subtotal != null ? numToRupiahFormat(subtotal) : '-'}
                          </div>
                        </div>
                        <button
                          className="w-full p-2 px-3 flex items-center justify-center rounded-sm bg-green-500 text-white hover:bg-green-600 hover:shadow-lg"
                          style={{ fontSize: "15px" }}
                          onClick={addToCart}
                        >
                          <FaCartPlus className="w-5 h-5 mr-2" />
                          Masukan Keranjang
                        </button>
                      </div>
                    </div>
                  </div>
                  <ProductDetailDescription bookDetail={bookDetail} bookRef={bookRef} />
                </div>
              </>
            )
        }



      </div>
      <Footer />
    </>
  )
}

function ProductDetailDescription({
  bookDetail,
  bookRef
}) {
  return (
    <div className="pt-20 px-10 py-3 text-gray-700" id={"description"} ref={bookRef}>
      <div className="py-3 text-left uppercase text-green-700 border-b border-gray-200" style={{ fontSize: "20px" }}>
        Deskripsi
      </div>
      <div className="text-justify py-3" dangerouslySetInnerHTML={{ __html: bookDetail != null ? bookDetail.keterangan : "-" }}>
      </div>
    </div>
  )
}