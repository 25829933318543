import { Dialog, Transition } from "@headlessui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { VscAdd, VscChromeClose } from "react-icons/vsc"
import { AiOutlinePlus } from "react-icons/ai"
import { addNewAddressFromChangeAddressDialog, closeChangeBillAddressDialog, openAddAddressDialog } from "../CheckoutSlice"
import { useHistory } from "react-router"
import { setIsTransactableMode } from "../../../component/HeaderSlice"

export default function ChangeBillAddressDialog({
  onSelected,
  onAddNewAddress
}) {
  const history = useHistory()
  const dispatch = useDispatch()

  const { changeBillAddressDialog } = useSelector(state => state.checkout)
  const { addresses } = useSelector(state => state.myAccount)
  const { screenSize } = useSelector(state => state.global)

  return (
    <Transition appear show={changeBillAddressDialog.isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto"
        style={{
          zIndex: "999"
        }}
        onClose={() => dispatch(closeChangeBillAddressDialog())}
      >
        <div className="min-h-screen px-4 text-center flex justify-center items-center">
          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-70 bg-gray-200" />
          </Transition.Child>

          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={
                "absolute " +
                (screenSize.width > 500 ? " top-20 left-1/2 " : " top-5 left-0 ")
              }
            >
              <div
                className="py-3 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
                style={{
                  position: "absolute",
                  left: screenSize.width > 500 ? "-250px" : "0",
                  width: screenSize.width > 500 ? "500px" : screenSize.width + "px",
                  height: "550px"
                }}
              >
                <Dialog.Title
                  as="h3"
                  className="font-bold pb-3 px-5 text-lg flex items-center justify-between font-medium leading-6 text-gray-900 border-b border-gray-200"
                >
                  Daftar Alamat
                  <button
                    className="p-2 hover:text-red-500"
                    onClick={() => dispatch(closeChangeBillAddressDialog())}
                  >
                    <VscChromeClose className="h-6 w-6" />
                  </button>
                </Dialog.Title>
                <div
                  className="mt-2 px-5 flex flex-col space-y-3 overflow-auto space-y-5"
                  style={{ height: "450px" }}
                >
                  {addresses.map(addr => (<AddressBox address={addr} onSelected={onSelected} />))}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

function EmptyAddress({
  onAddNewAddess
}) {
  return (
    <div
      className="rounded-md bg-blue-200 p-2 py-3 text-center space-y-5"
    >
      <div>
        Anda belum memiliki data alamat pengiriman. Klik tombol dibawah untuk menambahkan data alamat pengiriman.
      </div>
      <div>
        <button
          className="rounded-md w-full py-2 bg-blue-600 text-white hover:bg-blue-900"
          onClick={onAddNewAddess}
        >
          Tambah data
        </button>
      </div>
    </div>
  )
}

function AddressBox({
  address,
  onSelected
}) {
  return (
    <div className="text-left p-3 border border-gray-300 rounded-md space-y-1">
      <div className=" font-bold">
        {address.label_alamat}
      </div>
      <div className="" style={{ fontSize: "14px" }}>
        {address.nama_penerima}  |  {address.no_hp_penerima}
      </div>
      <div className="" style={{ fontSize: "14px" }}>
        {address.alamat}, Kec. {address.subdistrict_name}, Kab. {address.city_name}, {address.province}, {address.postal_code}
      </div>
      <div className="py-2">
        <button
          className="w-full py-2 border border-blue-700 rounded-full font-bold text-blue-700 hover:bg-blue-700 hover:text-white"
          style={{ fontSize: "14px" }}
          onClick={() => onSelected(address.id_user_alamat)}
        >
          Pilih Alamat ini
        </button>
      </div>
    </div>
  )
}