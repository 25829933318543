import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import { GiBookshelf } from "react-icons/gi";
import { RiArrowRightSLine, RiGooglePlayFill, RiWhatsappFill } from "react-icons/ri";
import { FaTelegram } from "react-icons/fa"
import { useHistory } from "react-router";

export default function SimpleSlider() {
  const { screenSize } = useSelector(state => state.global)
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // speed: 2000,
    autoplaySpeed: 5000,
    // cssEase: "linear"
  };

  return (
    <div
      className="w-full"
    >
      <div
        className=""
      >

        <Slider {...settings} >
          <KurikulumMerdeka />
          {/* <Banner2 index={2} /> */}
          <SipLah />
          <Banner1 index={1} />
          <MacaBanner />
          <Banner3 index={3} />
        </Slider>

      </div>
    </div>
  )
}

function MacaBanner() {
  const history = useHistory()
  const { screenSize } = useSelector(state => state.global)

  if (screenSize.width > 700) {
    return (
      <div
        className="w-full flex justify-center "
        style={{
          height: "470px",
          background: "no-repeat 50%",
          backgroundImage: "url(" + (window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/banner-web-04.jpg?" + Math.random() + ")",
          backgroundColor: "rgb(163 202 56)",
          backgroundSize: "cover"
        }}
      >
        <div
          className={
            "container flex relative " +
            (screenSize.width > 500 ? " justify-between " : " justify-center ")
          }
        >
          <div
            className={
              " absolute bottom-10 w-full " +
              (screenSize.width > 500 ? " left-24 " : " flex justify-center px-2")
            }
          >
            <button
              className={
                "p-3 pl-5 rounded-full flex items-center justify-between bg-white hover:shadow-lg bg-red-600 text-white hover:bg-red-900" +
                (screenSize.width > 500 ? " space-x-4 " : " w-full space-x-2 ")
              }
              style={{ fontFamily: "Inter", fontSize: screenSize.width > 500 ? "16px" : "15px" }}
              onClick={() => history.push("/products")}
            >
              <label className="cursor-pointer rounded-full">
                <GiBookshelf className="w-7 h-7" />
              </label>
              <div className="cursor-pointer space-x-2">
                <label className="cursor-pointer font-bold" style={{ fontFamily: "Nunito" }}>Lihat koleksi buku</label>
              </div>
              <div className="">
                <RiArrowRightSLine className="w-6 h-6" />
              </div>
            </button>
          </div>
        </div>
      </div >
    )
  }

  return (
    <div
      className="w-full flex justify-center "
      style={{ height: "470px", background: "rgb(163 202 56)", width: (screenSize.width) + "px" }}
    >
      <div
        className={
          "container flex " +
          (screenSize.width > 500 ? " justify-between " : " justify-center ")
        }
      >
        <div
          className="h-full py-10 text-left relative"
          style={{ width: screenSize.width > 500 ? "60%" : "80%" }}
        >

          <div className={""}>
            <div>
              <label className="font-bold" style={{ fontSize: "48px", fontFamily: "Exo", lineHeight: "48px", color: "white" }}>
                Buku Pelajaran Berkarakter
              </label>
            </div>
            <div
              className="absolute"
              style={{ bottom: screenSize.width > 500 ? "50%" : "40%" }}
            >
              <div>
                <label className="" style={{ fontSize: "24px", fontFamily: "Exo", lineHeight: "24px", color: "white" }}>
                  Bangun prestasi dan karakter siswa secara terpadu
                </label>
              </div>
            </div>
            <div
              className={
                " absolute bottom-10 w-full " +
                (screenSize.width > 500 ? " flex space-x-2 " : " flex flex-col items-center justify-center px-2")
              }
            >
              <button
                className={
                  "p-2 pl-5 rounded-full flex items-center justify-between bg-white hover:shadow-lg bg-blue-700 text-white hover:bg-blue-900" +
                  (screenSize.width > 500 ? " space-x-4 " : " w-full space-x-2 ")
                }
                style={{ fontFamily: "Inter", fontSize: screenSize.width > 500 ? "16px" : "15px" }}
                onClick={() => history.push("/products")}
              >
                <div>
                </div>
                <div className="cursor-pointer space-x-2">
                  <label className="cursor-pointer font-bold" style={{ fontFamily: "Nunito" }}>
                    Lihat koleksi buku
                  </label>
                </div>
                <div className="">
                  <RiArrowRightSLine className="w-6 h-6" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Banner1({
  index
}) {
  const history = useHistory()
  const { screenSize } = useSelector(state => state.global)

  if (screenSize.width > 700) {
    return (
      <div
        className="w-full flex justify-center "
        style={{
          height: "470px",
          background: "no-repeat 50%",
          // backgroundImage: "url(http://localhost/rest_bukualc/assets/images/banner-web-01.jpg)",
          backgroundImage: "url(" + (window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/banner-web-01.jpg?" + Math.random() + ")",
          backgroundColor: "rgb(57 142 212)",
          backgroundSize: "cover"
        }}
      >
        <div
          className={
            "container flex relative " +
            (screenSize.width > 500 ? " justify-between " : " justify-center ")
          }
        >
          <div
            className={
              " absolute bottom-10 w-full " +
              (screenSize.width > 500 ? " left-24 " : " flex justify-center px-2")
            }
          >
            <button
              className={
                "p-3 pl-5 rounded-full flex items-center justify-between bg-white hover:shadow-lg bg-red-600 text-white hover:bg-red-900" +
                (screenSize.width > 500 ? " space-x-4 " : " w-full space-x-2 ")
              }
              style={{ fontFamily: "Inter", fontSize: screenSize.width > 500 ? "16px" : "15px" }}
              onClick={() => window.open('https://play.google.com/store/apps/details?id=com.syibook', '_blank')}
            >
              <label className="cursor-pointer rounded-full">
                <RiGooglePlayFill className="w-7 h-7" />
              </label>
              <div className="cursor-pointer space-x-2">
                <label className="cursor-pointer font-bold" style={{ fontFamily: "Nunito" }}>Download Syibook</label>
              </div>
              <div className="">
                <RiArrowRightSLine className="w-6 h-6" />
              </div>
            </button>
          </div>
        </div>
      </div >
    )
  }

  return (
    <div
      className="w-full flex justify-center "
      style={{ height: "470px", background: "rgb(57, 142, 212)", width: (screenSize.width) + "px" }}
    >
      <div
        className={
          "container flex " +
          (screenSize.width > 500 ? " justify-between " : " justify-center ")
        }
      >
        <div
          className="h-full py-10 text-left relative"
          style={{ width: screenSize.width > 500 ? "50%" : "80%" }}
        >

          <div className={""}>
            <div className="space-y-5">
              <div
                className="flex flex-col"
              >
                <label className="font-bold" style={{ fontSize: "48px", fontFamily: "Exo", lineHeight: "48px", color: "white" }}>
                  Gratis!
                </label>
                <label className="font-bold" style={{ fontSize: "48px", fontFamily: "Exo", lineHeight: "48px", color: "white" }}>
                  Sampel Buku
                </label>
              </div>
              <div>
                <label className="" style={{ fontSize: "24px", fontFamily: "Exo", color: "white" }}>
                  Lihat konten buku dalam aplikasi Syibook
                </label>
              </div>
            </div>
            {/* <div>
              <label className="" style={{ fontSize: "24px", fontFamily: "Exo", color: "white" }}>
                Jangan membeli buku sebelum anda membaca sampelnya dulu.
              </label>
            </div> */}
            <div
              className={
                " absolute bottom-10 w-full " +
                (screenSize.width > 500 ? "" : " flex justify-center px-2")
              }
            >
              <button
                className={
                  "p-3 pl-5 rounded-full flex items-center justify-between bg-white hover:shadow-lg bg-blue-700 text-white hover:bg-blue-900" +
                  (screenSize.width > 500 ? " space-x-4 " : " w-full space-x-2 ")
                }
                style={{ fontFamily: "Inter", fontSize: screenSize.width > 500 ? "16px" : "15px" }}
                onClick={() => window.open('https://play.google.com/store/apps/details?id=com.syibook', '_blank')}
              >
                <label className="cursor-pointer rounded-full">
                  <RiGooglePlayFill className="w-7 h-7" />
                </label>
                <div className="cursor-pointer space-x-2">
                  <label className="cursor-pointer font-bold" style={{ fontFamily: "Nunito" }}>Download Syibook</label>
                </div>
                <div className="">
                  <RiArrowRightSLine className="w-6 h-6" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div >
  )
}

function Banner2({
  index
}) {
  const history = useHistory()
  const { screenSize } = useSelector(state => state.global)

  if (screenSize.width > 700) {
    return (
      <div
        className="w-full flex justify-center "
        style={{
          height: "470px",
          background: "no-repeat 50%",
          // backgroundImage: "url(http://localhost/rest_bukualc/assets/images/banner-web-02.jpg)",
          backgroundImage: "url(" + (window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/banner-web-02.jpg?" + Math.random() + ")",
          backgroundColor: "rgb(218 33 39)",
          backgroundSize: "cover"
        }}
      >
        <div
          className={
            "container flex relative " +
            (screenSize.width > 500 ? " justify-between " : " justify-center ")
          }
        >
          <div
            className={
              " absolute bottom-10 w-full " +
              (screenSize.width > 500 ? " left-24 " : " flex justify-center px-2")
            }
          >
          </div>
        </div>
      </div >
    )
  }

  return (
    <div className="w-full flex justify-center " style={{ height: "470px", background: "rgb(218 33 39)", width: (screenSize.width) + "px" }}>
      <div
        className={
          "container flex " +
          (screenSize.width > 500 ? " justify-between " : " justify-center ")
        }
      >
        <div
          className="h-full py-10 text-left relative"
          style={{ width: screenSize.width > 500 ? "50%" : "80%" }}
        >

          <div className={""}>
            <div className="py-5">
              <p className="font-bold" style={{ fontSize: "48px", fontFamily: 'Exo', lineHeight: "48px", fontWeight: "800", color: "white" }}>
                Diskon harga 30%
              </p>
              <p className="font-bold" style={{ fontSize: "48px", fontFamily: 'Exo', lineHeight: "48px", fontWeight: "800", color: "white" }}>
                Up to 45%
              </p>
            </div>
            <div>
              <label className="" style={{ fontSize: "24px", fontFamily: "Exo", color: "white" }}>
                Harga hemat untuk semua tanpa dibeda-beda
              </label>
            </div>
            <div className="mt-2 py-3 border-t border-white">
              <div className="flex items-center space-x-2" style={{ fontSize: "20px", fontFamily: "Exo", color: "white" }}>
                <RiWhatsappFill className="h-5 w-5" />
                <label>0813 3909 4092</label>
              </div>
              <div className="flex items-center space-x-2" style={{ fontSize: "20px", fontFamily: "Exo", color: "white" }}>
                <FaTelegram className="h-5 w-5" />
                <label>@assyifalearningcenter</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Banner3({
  index
}) {
  const history = useHistory()
  const { screenSize } = useSelector(state => state.global)

  if (screenSize.width > 700) {
    return (
      <div
        className="w-full flex justify-center "
        style={{
          height: "470px",
          background: "no-repeat 50%",
          // backgroundImage: "url(http://localhost/rest_bukualc/assets/images/banner-web-03.jpg)",
          backgroundImage: "url(" + (window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/banner-web-03.jpg?" + Math.random() + ")",
          backgroundColor: "white",
          backgroundSize: "cover"
        }}
      >
        <div
          className={
            "container flex relative " +
            (screenSize.width > 500 ? " justify-between " : " justify-center ")
          }
        >
          <div
            className={
              " absolute bottom-10 w-full " +
              (screenSize.width > 500 ? " left-24 " : " flex justify-center px-2")
            }
          >
            <button
              className={
                "p-3 pl-5 rounded-full flex items-center justify-between bg-white hover:shadow-lg bg-blue-600 text-white hover:bg-blue-900" +
                (screenSize.width > 500 ? " space-x-4 " : " w-full space-x-2 ")
              }
              style={{ fontFamily: "Inter", fontSize: screenSize.width > 500 ? "16px" : "15px" }}
              onClick={() => history.push("/products")}
            >
              <label className="cursor-pointer rounded-full">
                <GiBookshelf className="w-7 h-7" />
              </label>
              <div className="cursor-pointer space-x-2">
                <label className="cursor-pointer font-bold" style={{ fontFamily: "Nunito" }}>Lihat koleksi buku</label>
              </div>
              <div className="">
                <RiArrowRightSLine className="w-6 h-6" />
              </div>
            </button>
          </div>
        </div>
      </div >
    )
  }

  return (
    <div className="w-full flex justify-center " style={{ height: "470px", background: "white", width: (screenSize.width) + "px" }}>
      <div
        className={
          "container flex " +
          (screenSize.width > 500 ? " justify-between " : " justify-center ")
        }
      >
        <div
          className="h-full py-10 text-left relative"
          style={{ width: screenSize.width > 500 ? "50%" : "80%" }}
        >

          <div className={""}>
            <div className="py-5">
              <label className="font-bold" style={{ fontSize: "48px", fontFamily: "Exo", lineHeight: "48px", color: "red" }}>
                Gratis Ongkos Kirim Ke Seluruh Indonesia
              </label>
            </div>
            {screenSize.width > 500 && (
              <div>
                <label className="" style={{ fontSize: "24px", fontFamily: "Exo", color: "gray" }}>
                  Kami kirim ke tempat anda tanpa tambahan biaya*
              </label>
              </div>
            )}
            <div>
              <label className="" style={{ fontSize: "15px", fontFamily: "Exo", color: "gray" }}>
                {screenSize.width > 500 ? '*S & K' : '*Syarat & Ketentuan berlaku'}
              </label>
            </div>
            <div
              className={
                " absolute bottom-10 w-full " +
                (screenSize.width > 500 ? "" : " flex justify-center px-2")
              }
            >
              <button
                className={
                  "p-3 pl-5 rounded-full flex items-center justify-between bg-white hover:shadow-lg bg-blue-700 text-white hover:bg-blue-900" +
                  (screenSize.width > 500 ? " space-x-4 " : " w-full space-x-2 ")
                }
                style={{ fontFamily: "Inter", fontSize: screenSize.width > 500 ? "16px" : "15px" }}
                onClick={() => history.push("/products")}
              >
                <label className="cursor-pointer rounded-full">
                  <GiBookshelf className="w-7 h-7" />
                </label>
                <div className="cursor-pointer space-x-2">
                  <label className="cursor-pointer font-bold" style={{ fontFamily: "Nunito" }}>Lihat koleksi buku</label>
                </div>
                <div className="">
                  <RiArrowRightSLine className="w-6 h-6" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function KurikulumMerdeka() {
  const history = useHistory()
  const { screenSize } = useSelector(state => state.global)

  if (screenSize.width > 700) {
    return (
      <div
        className="w-full flex justify-center "
        style={{
          height: "470px",
          background: "no-repeat 50%",
          backgroundImage: "url(" + (window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/kurikulum_merdeka.jpg?" + Math.random() + ")",
          backgroundColor: "rgb(57 142 212)",
          backgroundSize: "cover"
        }}
      >
        <div
          className={
            "container flex relative " +
            (screenSize.width > 500 ? " justify-between " : " justify-center ")
          }
        >
          <div
            className={
              " absolute bottom-10 w-full " +
              (screenSize.width > 500 ? " left-24 " : " flex justify-center px-2")
            }
          >
            <button
              className={
                "p-3 pl-5 rounded-full flex items-center justify-between bg-white hover:shadow-lg bg-blue-600 text-white hover:bg-blue-900" +
                (screenSize.width > 500 ? " space-x-4 " : " w-full space-x-2 ")
              }
              style={{ fontFamily: "Inter", fontSize: screenSize.width > 500 ? "16px" : "15px" }}
              onClick={() => history.push("/products")}
            >
              <label className="cursor-pointer rounded-full">
                <GiBookshelf className="w-7 h-7" />
              </label>
              <div className="cursor-pointer space-x-2">
                <label className="cursor-pointer font-bold" style={{ fontFamily: "Nunito" }}>Lihat koleksi buku</label>
              </div>
              <div className="">
                <RiArrowRightSLine className="w-6 h-6" />
              </div>
            </button>
          </div>
        </div>
      </div >
    )
  }

  return (
    <div
      className="w-full flex justify-center "
      style={{ height: "470px", background: "rgb(57, 142, 212)", width: (screenSize.width) + "px" }}
    >
      <div
        className={
          "container flex " +
          (screenSize.width > 500 ? " justify-between " : " justify-center ")
        }
      >
        <div
          className="h-full py-10 text-left relative"
          style={{ width: screenSize.width > 500 ? "50%" : "80%" }}
        >

          <div className={""}>
            <div className="space-y-5">
              <div
                className="flex flex-col"
              >
                <label className="font-bold" style={{ fontSize: "48px", fontFamily: "Exo", lineHeight: "48px", color: "white" }}>
                  Tersedia
                </label>
                <label className="font-bold" style={{ fontSize: "48px", fontFamily: "Exo", lineHeight: "48px", color: "white" }}>
                  Kurikulum Merdeka
                </label>
              </div>
              <div>
                <label className="" style={{ fontSize: "24px", fontFamily: "Exo", color: "white" }}>
                  Tersediak koleksi buku kurikulum merdeka
                </label>
              </div>
            </div>
            <div
              className={
                " absolute bottom-10 w-full " +
                (screenSize.width > 500 ? "" : " flex justify-center px-2")
              }
            >
              <button
                className={
                  "p-3 pl-5 rounded-full flex items-center justify-between bg-white hover:shadow-lg bg-blue-600 text-white hover:bg-blue-900" +
                  (screenSize.width > 500 ? " space-x-4 " : " w-full space-x-2 ")
                }
                style={{ fontFamily: "Inter", fontSize: screenSize.width > 500 ? "16px" : "15px" }}
                onClick={() => history.push("/products")}
              >
                <label className="cursor-pointer rounded-full">
                  <GiBookshelf className="w-7 h-7" />
                </label>
                <div className="cursor-pointer space-x-2">
                  <label className="cursor-pointer font-bold" style={{ fontFamily: "Nunito" }}>Lihat koleksi buku</label>
                </div>
                <div className="">
                  <RiArrowRightSLine className="w-6 h-6" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div >
  )
}

function SipLah() {
  const history = useHistory()
  const { screenSize } = useSelector(state => state.global)

  if (screenSize.width > 700) {
    return (
      <div
        className="w-full flex justify-center "
        style={{
          height: "470px",
          background: "no-repeat 50%",
          backgroundImage: "url(" + (window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/siplah.jpg?" + Math.random() + ")",
          backgroundColor: "rgb(57 142 212)",
          backgroundSize: "cover"
        }}
      >
        <div
          className={
            "container flex relative " +
            (screenSize.width > 500 ? " justify-between " : " justify-center ")
          }
        >
          <div
            className={
              " absolute bottom-10 w-full " +
              (screenSize.width > 500 ? " left-24 " : " flex justify-center px-2")
            }
          >
            <a href="https://siplah.tokoladang.co.id/official-store/.23842" target="_blank">
              <button
                className={
                  "p-3 pl-5 rounded-full flex items-center justify-between bg-white hover:shadow-lg bg-red-600 text-white hover:bg-red-900" +
                  (screenSize.width > 500 ? " space-x-4 " : " w-full space-x-2 ")
                }
                style={{ fontFamily: "Inter", fontSize: screenSize.width > 500 ? "16px" : "15px" }}
              >
                <div className="cursor-pointer space-x-2 pl-5">
                  <label className="cursor-pointer font-bold" style={{ fontFamily: "Nunito" }}>Kunjungi Toko</label>
                </div>
                <div className="">
                  <RiArrowRightSLine className="w-6 h-6" />
                </div>
              </button>
            </a>
          </div>
        </div>
      </div >
    )
  }

  return (
    <div
      className="w-full flex justify-center "
      style={{ height: "470px", background: "rgb(57, 142, 212)", width: (screenSize.width) + "px" }}
    >
      <div
        className={
          "container flex " +
          (screenSize.width > 500 ? " justify-between " : " justify-center ")
        }
      >
        <div
          className="h-full py-10 text-left relative"
          style={{ width: screenSize.width > 500 ? "50%" : "80%" }}
        >

          <div className={""}>
            <div className="space-y-5">
              <div
                className="flex flex-col"
              >
                <label className="font-bold" style={{ fontSize: "48px", fontFamily: "Exo", lineHeight: "48px", color: "white" }}>
                  Sudah Tersedia
                </label>
                <label className="font-bold" style={{ fontSize: "48px", fontFamily: "Exo", lineHeight: "48px", color: "white" }}>
                  di SIPLah
                </label>
              </div>
              <div>
                <label className="" style={{ fontSize: "24px", fontFamily: "Exo", color: "white" }}>
                  Koleksi tersedia juga di SIPLah
                </label>
              </div>
            </div>
            <div
              className={
                " absolute bottom-10 w-full " +
                (screenSize.width > 500 ? "" : " flex justify-center px-2")
              }
            >
              <a href="https://siplah.tokoladang.co.id/official-store/.23842" target="_blank">
                <button
                  className={
                    "p-3 pl-5 rounded-full flex items-center justify-between bg-white hover:shadow-lg bg-red-600 text-white hover:bg-red-900" +
                    (screenSize.width > 500 ? " space-x-4 " : " w-full space-x-2 ")
                  }
                  style={{ fontFamily: "Inter", fontSize: screenSize.width > 500 ? "16px" : "15px" }}
                >
                  <div className="cursor-pointer space-x-2 pl-5 ">
                    <label className="cursor-pointer font-bold" style={{ fontFamily: "Nunito" }}>Kunjungi Toko</label>
                  </div>
                  <div className="">
                    <RiArrowRightSLine className="w-6 h-6" />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div >
  )
}
