import { Dialog, Transition } from "@headlessui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { VscChromeClose } from "react-icons/vsc"
import { closeInstructionDialog } from "../CheckoutResultSlice"
import axios from "axios"
import { RiLoader5Fill } from "react-icons/ri"
import { BsChevronDown, BsChevronUp } from "react-icons/bs"

export default function PaymentInstructionDialog() {
  const dispatch = useDispatch()

  const { instructionDialog, paymentInstructions } = useSelector(state => state.checkoutResult)
  const { screenSize } = useSelector(state => state.global)

  const [data, setData] = useState(paymentInstructions.map((p, i) => ({ ...p, isOpen: i == 0 })))

  return (
    <Transition appear show={instructionDialog.isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto"
        style={{
          zIndex: "999"
        }}
        onClose={() => null}
      >
        <div className="min-h-screen px-4 text-center flex justify-center items-center">
          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-70 bg-gray-200" />
          </Transition.Child>

          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={
                "absolute " +
                (screenSize.width > 500 ? " left-1/2 top-20 " : " left-0 top-0 ")
              }
            >
              <div
                className="py-3 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
                style={{
                  position: "absolute",
                  left: screenSize.width > 500 ? "-450px" : "0",
                  width: screenSize.width > 500 ? "900px" : screenSize.width + "px"
                }}
              >
                <Dialog.Title
                  as="h3"
                  className="pb-3 px-5 text-lg flex items-center justify-end font-medium leading-6 text-gray-900 border-b border-gray-200"
                >
                  <button
                    className="p-2 hover:text-red-500"
                    onClick={() => dispatch(closeInstructionDialog())}
                  >
                    <VscChromeClose className="h-6 w-6" />
                  </button>
                </Dialog.Title>
                <div className="">
                  {
                    data.map(d => (
                      <>
                        <div className="flex items-center justify-between px-5 py-3 border">
                          <label className="font-bold" style={{ fontFamily: "Nunito", fontSize: "18px" }}>Via {d.title}</label>
                          <button
                            className="p-2"
                            onClick={() => {
                              setData(data.map(dx => dx.title == d.title ? { ...dx, isOpen: !dx.isOpen } : { ...dx, isOpen: false }))
                            }}
                          >
                            {
                              d.isOpen ?
                                <BsChevronUp className="h5 w-5" />
                                :
                                <BsChevronDown className="h5 w-5" />
                            }

                          </button>
                        </div>
                        {d.isOpen && (
                          <div className="flex flex-col px-8 space-y-2 py-2">
                            {d.steps.map((s, i) => (
                              <div className="flex space-x-2">
                                <div className="flex justify-center w-6 rounded-full bg-gray-300 text-white">
                                  {i + 1}
                                </div>
                                <label>
                                  {s}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    ))
                  }
                </div>
                <div className="pt-5 px-5 flex justify-end">
                  <button
                    className="p-2 px-5 rounded-md bg-blue-600 text-white hover:bg-blue-900 hover:shadow-lg"
                    onClick={() => dispatch(closeInstructionDialog())}
                  >
                    Tutup
                  </button>
                </div>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )

}