import { Dialog, Transition } from "@headlessui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { closeAddDialog } from './PmbBankAccountSlice'
import { VscChromeClose } from "react-icons/vsc"
import { FaChevronRight } from "react-icons/fa"
import { closeSelectPaymentChannelDialog } from "../CheckoutSlice"
import { AiOutlineArrowLeft } from "react-icons/ai"
import { numToRupiahFormat } from '../../../util/currency'

export default function SelectPaymentMethodDialog({
  onSelect
}) {
  const dispatch = useDispatch()
  const { selectPaymentChannelDialog, paymentChannels } = useSelector(state => state.checkout)
  const { screenSize } = useSelector(state => state.global)

  const [groupedPayment, setGroupedPayment] = useState(null)

  const [vaChannels, setVaChannels] = useState([])
  const [storeChannels, setStoreChannels] = useState([])
  const [manualChannels, setManualChannels] = useState([])

  const [selectedChannel, setSelectedChannel] = useState(null)

  const [mode, setMode] = useState('channels') //channels or detail

  useEffect(() => {
    if (paymentChannels.length > 0) {
      const group = paymentChannels.reduce((r, a) => {
        r[a.group] = [...r[a.group] || [], a];
        return r;
      }, {})

      setGroupedPayment(group)
    }
  }, [paymentChannels])

  useEffect(() => {
    if (groupedPayment) {
      if (groupedPayment['Convenience Store'] != undefined) {
        setStoreChannels(groupedPayment['Convenience Store'])
      }
      if (groupedPayment['Virtual Account'] != undefined) {
        setVaChannels(groupedPayment['Virtual Account'])
      }
      if (groupedPayment['Manual Transfer'] != undefined) {
        // setManualChannels(groupedPayment['Manual Transfer'])
      }
    }
  }, [groupedPayment])

  useEffect(() => {
    if (selectedChannel) {
      setMode('detail')
    }
    else {
      setMode('channels')
    }
  }, [selectedChannel])

  useEffect(() => {
    if (!selectPaymentChannelDialog.isOpen) {
      setSelectedChannel(null)
    }
  }, [selectPaymentChannelDialog.isOpen])


  return (
    <Transition appear show={selectPaymentChannelDialog.isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto"
        style={{
          zIndex: "999"
        }}
        onClose={() => null}
      >
        <div className="min-h-screen px-4 text-center flex justify-center items-center">
          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-70 bg-gray-200" />
          </Transition.Child>

          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={
                "absolute " +
                (screenSize.width > 500 ? " left-1/2 top-20 " : " left-0 top-5 ")
              }
            >
              <div
                className="py-3 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
                style={{
                  position: "absolute",
                  left: screenSize.width > 500 ? "-250px" : "0",
                  width: screenSize.width > 500 ? "500px" : screenSize.width + "px",
                  height: "550px"
                }}
              >
                <Dialog.Title
                  as="h3"
                  className="font-bold pb-3 px-5 text-lg flex items-center justify-between font-medium leading-6 text-gray-900 border-b border-gray-200"
                >
                  {mode == 'channels' ?
                    'Metode Pembayaran'
                    :
                    (
                      <>
                        <button
                          className="p-2 hover:text-red-500"
                          onClick={() => setMode('channels')}
                        >
                          <AiOutlineArrowLeft className="h-6 w-6" />
                        </button>
                        <label>Metode Pembayaran</label>
                      </>
                    )
                  }
                  <button
                    className="p-2 hover:text-red-500"
                    onClick={() => dispatch(closeSelectPaymentChannelDialog())}
                  >
                    <VscChromeClose className="h-6 w-6" />
                  </button>
                </Dialog.Title>
                <div
                  className="mt-2 px-5 flex flex-col space-y-3 overflow-auto space-y-5"
                  style={{ height: "450px" }}
                >
                  {
                    mode == 'channels' ?
                      (
                        <>
                          <div className="space-y-1">
                            <div className="font-bold text-black" style={{ fontSize: "14px" }}>
                              Virtual Account (Otomatis)
                            </div>
                            <div className="flex flex-col space-y-2">
                              {vaChannels.map(c => (
                                <button
                                  className="flex items-center justify-between px-2 py-4 border border-gray-200 rounded-lg hover:border-blue-700 shadow-lg"
                                  onClick={() => setSelectedChannel(c)}
                                >
                                  <div className="flex space-x-2" style={{ fontSize: "14px" }}>
                                    <img src={c.icon_url} width="50px" />
                                    <label>{c.name}</label>
                                  </div>
                                  <FaChevronRight />
                                </button>
                              ))}
                            </div>
                          </div>
                          <div className="space-y-1">
                            <div className="font-bold text-black" style={{ fontSize: "14px" }}>
                              Convenince Store (Otomatis)
                            </div>
                            <div className="flex flex-col space-y-2">
                              {storeChannels.map(c => (
                                <button
                                  className="flex items-center justify-between px-2 py-4 border border-gray-200 rounded-lg hover:border-blue-700 shadow-lg"
                                  onClick={() => setSelectedChannel(c)}
                                >
                                  <div className="flex space-x-2" style={{ fontSize: "14px" }}>
                                    <img src={c.icon_url} width="50px" />
                                    <label>{c.name}</label>
                                  </div>
                                  <FaChevronRight />
                                </button>
                              ))}
                            </div>
                          </div>
                          {/* <div>
                            <div className="font-bold text-black" style={{ fontSize: "14px" }}>
                              Manual Transfer
                            </div>
                            <div className="flex flex-col space-y-2">
                              {manualChannels.map(c => (
                                <button
                                  className="flex items-center justify-between px-2 py-4 border border-gray-200 rounded-lg hover:border-blue-700 shadow-lg"
                                  onClick={() => setSelectedChannel(c)}
                                >
                                  <div className="flex space-x-2" style={{ fontSize: "14px" }}>
                                    <img src={c.icon_url} width="50px" />
                                    <label>{c.name}</label>
                                  </div>
                                  <FaChevronRight />
                                </button>
                              ))}
                            </div>
                          </div> */}
                        </>
                      )
                      :
                      (<div className="space-y-8">
                        <div className="p-3 rounded-md shadow-lg border space-y-3 divide-y divide-gray-400">
                          <div className="flex font-bold items-center justify-between py-1">
                            <label style={{ fontSize: "14px" }}> {selectedChannel != null ? selectedChannel.name : ''}</label>
                            {selectedChannel != null && (<img src={selectedChannel.icon_url} width="50px" />)}
                          </div>
                          <div className="py-2 pl-5 text-gray-700" style={{ fontSize: "14px" }}>
                            <ul className="list-disc">
                              {selectedChannel != null && (selectedChannel.group == 'Convenience Store' || selectedChannel.group == 'Virtual Account') && (
                                <li>
                                  Pembayaran Anda akan terkonfimasi secara otomatis dalam 24 jam
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                        <div className="p-3 rounded-md shadow-lg border space-y-3 divide-y divide-gray-400 flex flex-col">
                          <div className="flex flex-col font-bold" style={{ fontSize: "14px" }}>
                            <label className="">Biaya Admin</label>
                            <label className="text-blue-900" style={{ fontSize: "16px" }}>
                              {selectedChannel != null ? "Rp " + numToRupiahFormat(selectedChannel.total_fee.flat) : "-"}
                            </label>
                          </div>
                          <button
                            className="w-full py-2 px-5 font-bold rounded-full border border-blue-700 bg-blue-700 text-white"
                            onClick={() => {
                              onSelect(selectedChannel)
                              dispatch(closeSelectPaymentChannelDialog())
                            }}
                          >
                            Pilih Pembayaran
                          </button>
                        </div>
                      </div>)
                  }

                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition >
  )
}