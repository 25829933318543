import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loadProps: {
    isLoading: false,
    message: 'Tunggu ya, sedang memproses...'
  }
}

export const LoadingSlice = createSlice({
  name: 'Loading',
  initialState,
  reducers: {
    startLoading: (state, { payload }) => {
      state.loadProps.isLoading = true
      state.loadProps.message = payload
    },
    changeLoadMessage: (state, { payload }) => {
      state.loadProps.message = payload
    },
    stopLoading: state => {
      state.loadProps.isLoading = false
      state.loadProps.message = ''
    }
  }
})

export const {
  startLoading,
  changeLoadMessage,
  stopLoading
} = LoadingSlice.actions

export default LoadingSlice.reducer

