import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  addAddressDialog: {
    isOpen: false
  },
  changeBillAddressDialog: {
    isOpen: false
  },
  selectShippingDialog: {
    isOpen: false
  },
  selectPaymentChannelDialog: {
    isOpen: false
  },
  couriers: [
    {
      id: 'jne',
      label: 'Jalur Nugraha Ekakurir (JNE)'
    },
    {
      id: 'tiki',
      label: 'Citra Van Titipan Kilat (TIKI)'
    },
    {
      id: 'pos',
      label: 'POS Indonesia (POS)'
    },
  ],
  paymentChannels: []
}

export const CheckoutSlice = createSlice({
  name: 'Checkout',
  initialState,
  reducers: {
    openAddAddressDialog: state => {
      state.addAddressDialog.isOpen = true
    },
    closeAddAddressDialog: state => {
      state.addAddressDialog.isOpen = false
    },
    openChangeBillAddressDialog: state => {
      state.changeBillAddressDialog.isOpen = true
    },
    closeChangeBillAddressDialog: state => {
      state.changeBillAddressDialog.isOpen = false
    },

    openSelectShippingDialog: state => {
      state.selectShippingDialog.isOpen = true
    },
    closeSelectShippingDialog: state => {
      state.selectShippingDialog.isOpen = false
    },

    openSelectPaymentChannelDialog: state => {
      state.selectPaymentChannelDialog.isOpen = true
    },
    closeSelectPaymentChannelDialog: state => {
      state.selectPaymentChannelDialog.isOpen = false
    },

    addNewAddressFromChangeAddressDialog: state => {
      state.changeBillAddressDialog.isOpen = false
      state.addAddressDialog.isOpen = true
    },

    setPaymentChannel: (state, { payload }) => {
      state.paymentChannels = payload
    }
  }
})

export const {
  openAddAddressDialog,
  closeAddAddressDialog,
  openChangeBillAddressDialog,
  closeChangeBillAddressDialog,
  openSelectShippingDialog,
  closeSelectShippingDialog,
  setPaymentChannel,
  openSelectPaymentChannelDialog,
  closeSelectPaymentChannelDialog,
  addNewAddressFromChangeAddressDialog
} = CheckoutSlice.actions

export default CheckoutSlice.reducer