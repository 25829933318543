import { Dialog, Transition } from "@headlessui/react"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { closeAddDialog } from './PmbBankAccountSlice'
import { VscChromeClose } from "react-icons/vsc"
import { closeAddNewAddressDialog, setAddresses } from "../MyAccountSlice"
import axios from "axios"
import Select from "react-select"
import { RiLoader5Fill } from "react-icons/ri"

const fetchProvinceDrop = async () => {
  const response = await axios.get(window._HOST + 'api/v1/admin/addresses/provincedrop', { withCredentials: true })
  return response.data
}

const fetchCityDrop = async (provinceId) => {
  const response = await axios.get(window._HOST + 'api/v1/admin/addresses/citydrop?province_id=' + provinceId, { withCredentials: true })
  return response.data
}

const fetchSubdistrictDrop = async (provinceId, cityId) => {
  const response = await axios.get(window._HOST + 'api/v1/admin/addresses/subdistrictdrop?province_id=' + provinceId + "&city_id=" + cityId, { withCredentials: true })
  return response.data
}

const addNewAddressServer = async (payload) => {
  const finalUrl =
    window._HOST + "api/v1/user/addresses"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

const fetchAddresses = async () => {
  const finalUrl =
    window._HOST + "api/v1/user/addresses"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export default function AddNewAddressDialog() {
  const dispatch = useDispatch()

  const { addNewAddressDialog } = useSelector(state => state.myAccount)
  const { screenSize } = useSelector(state => state.global)

  const [form, setForm] = useState({
    addressLabel: '',
    receiverName: '',
    hp: '',
    postCode: '',
    fullAddress: ''
  })

  const provRef = useRef(null)
  const [provOpts, setProvOpts] = useState({
    selectedOpt: null,
    data: [],
    isLoad: false
  })
  provRef.current = provOpts.selectedOpt

  function onSelectProvOpt() {
    setProvOpts({ ...provOpts, isLoad: true })

    fetchProvinceDrop()
      .then(response => {
        if (response.success) {
          const data = response.data.map(d => ({ label: d.province, value: d.province_id }))
          setProvOpts({ ...provOpts, data: data, isLoad: false })
        }
        else {
          setProvOpts({ ...provOpts, isLoad: false })
        }
      })
      .catch(error => {
        setProvOpts({ ...provOpts, isLoad: false })

        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
          }
          else {
          }
        }
      })

  }

  const cityRef = useRef(null)
  const [cityOpts, setCityOpts] = useState({
    selectedOpt: null,
    data: [],
    isLoad: false
  })
  cityRef.current = cityOpts.selectedOpt

  function onSelectCityOpt() {
    if (provOpts.selectedOpt != null) {
      setCityOpts({ ...cityOpts, isLoad: true })

      fetchCityDrop(provOpts.selectedOpt.value)
        .then(response => {
          if (response.success) {
            const data = response.data.map(d => ({ label: d.city_name, value: d.city_id }))
            setCityOpts({ ...cityOpts, data: data, isLoad: false })
          }
          else {
            setCityOpts({ ...cityOpts, isLoad: false })
          }
        })
        .catch(error => {
          setCityOpts({ ...cityOpts, isLoad: false })

          if (error.response != undefined && error.response != null) {
            if (error.response.status == 401) {
            }
            else {
            }
          }
        })
    }

  }

  const subdistrictRef = useRef(null)
  const [subdistrictOpts, setSubdistrictOpts] = useState({
    selectedOpt: null,
    data: [],
    isLoad: false
  })
  subdistrictRef.current = subdistrictOpts.selectedOpt

  function onSelectSubdistrictOpt() {
    if (provOpts.selectedOpt != null && cityOpts.selectedOpt != null) {
      setSubdistrictOpts({ ...subdistrictOpts, isLoad: true })

      fetchSubdistrictDrop(provOpts.selectedOpt.value, cityOpts.selectedOpt.value)
        .then(response => {
          if (response.success) {
            const data = response.data.map(d => ({ label: d.subdistrict_name, value: d.subdistrict_id }))
            setSubdistrictOpts({ ...subdistrictOpts, data: data, isLoad: false })
          }
          else {
            setSubdistrictOpts({ ...subdistrictOpts, isLoad: false })
          }
        })
        .catch(error => {
          setSubdistrictOpts({ ...subdistrictOpts, isLoad: false })

          if (error.response != undefined && error.response != null) {
            if (error.response.status == 401) {
            }
            else {
            }
          }
        })
    }

  }

  const [isLoad, setIsLoad] = useState(false)
  const [errors, setErrors] = useState([])

  function onAdd() {
    const payload = {
      label_alamat: form.addressLabel,
      nama_penerima: form.receiverName,
      no_hp_penerima: form.hp,
      alamat: form.fullAddress,
      kode_pos: form.postCode,
      subdistrict_id: subdistrictOpts.selectedOpt != null ? subdistrictOpts.selectedOpt.value : null,
      city_id: cityOpts.selectedOpt != null ? cityOpts.selectedOpt.value : null,
      province_id: provOpts.selectedOpt != null ? provOpts.selectedOpt.value : null,
    }

    setIsLoad(true)

    addNewAddressServer(payload)
      .then(response => {
        if (response.success) {
          regetMyAddresses()
        }
        else {
          setErrors(response.errors)
          setIsLoad(false)
        }
      })
      .catch(errors => {
      })

  }

  function regetMyAddresses() {
    fetchAddresses()
      .then(response => {
        if (response.success) {
          dispatch(setAddresses(response.data))
        }
      })
      .catch(errors => {
      })
      .finally(() => {
        setIsLoad(false)
        dispatch(closeAddNewAddressDialog())
      })
  }

  return (
    <Transition appear show={addNewAddressDialog.isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto"
        style={{
          zIndex: "999"
        }}
        onClose={() => null}
      >
        <div className="min-h-screen px-4 text-center flex justify-center items-center">
          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-70 bg-gray-200" />
          </Transition.Child>

          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={
                "absolute " +
                (screenSize.width > 500 ? " left-1/2 top-20 " : " left-0 top-0 ")
              }
            >
              <div
                className="py-3 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
                style={{
                  position: "absolute",
                  left: screenSize.width > 500 ? "-230px" : "0",
                  width: screenSize.width > 500 ? "460px" : screenSize.width + "px",
                  height: "550px"
                }}
              >
                {
                  isLoad ?
                    (
                      <>
                        <Dialog.Title
                          as="h3"
                          className="pb-3 px-5 text-lg flex items-center justify-between font-medium leading-6 text-gray-900 border-b border-gray-200"
                        >
                          Tambah Alamat
                        <button
                            className="p-2 hover:text-red-500"
                            onClick={() => dispatch(closeAddNewAddressDialog())}
                          >
                            <VscChromeClose className="h-6 w-6" />
                          </button>
                        </Dialog.Title>
                        <div className="h-full w-full flex items-center justify-center text-blue-500 py-10">
                          <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
                        </div>
                      </>
                    )
                    :
                    (
                      <>
                        <Dialog.Title
                          as="h3"
                          className="pb-3 px-5 text-lg flex items-center justify-between font-medium leading-6 text-gray-900 border-b border-gray-200"
                        >
                          Tambah Alamat
                          <button
                            className="p-2 hover:text-red-500"
                            onClick={() => dispatch(closeAddNewAddressDialog())}
                          >
                            <VscChromeClose className="h-6 w-6" />
                          </button>
                        </Dialog.Title>
                        <div
                          className="mt-4 px-5 flex flex-col space-y-3 overflow-auto space-y-5"
                          style={{ height: "450px" }}
                        >
                          {errors.length > 0 && (
                            <div className={"p-2 border rounded-md bg-red-500 text-white text-left"}>
                              <ul className="list-disc px-5">
                                {errors.map(e => (
                                  <li>{e}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                          <div className="space-y-1">
                            <div className="font-bold text-left" style={{ fontSize: "13px" }}>
                              Label Alamat
                            </div>
                            <div className="border-b border-gray-400">
                              <input
                                className="outline-none w-full py-1" placeholder="Cth: (Rumah, Kantor)"
                                value={form.addressLabel}
                                onChange={e => setForm({ ...form, addressLabel: e.target.value })}
                              />
                            </div>
                          </div>
                          <div className="space-y-1">
                            <div className="font-bold text-left" style={{ fontSize: "13px" }}>
                              Nama Penerima
                            </div>
                            <div className="border-b border-gray-400">
                              <input
                                className="outline-none w-full py-1" placeholder=""
                                value={form.receiverName}
                                onChange={e => setForm({ ...form, receiverName: e.target.value })}
                              />
                            </div>
                          </div>
                          <div className="space-y-1">
                            <div className="font-bold text-left" style={{ fontSize: "13px" }}>
                              No. Handphone
                            </div>
                            <div className="border-b border-gray-400">
                              <input
                                className="outline-none w-full py-1" placeholder=""
                                value={form.hp}
                                onChange={e => setForm({ ...form, hp: e.target.value })}
                              />
                            </div>
                          </div>
                          <div className="space-y-1">
                            <div className="font-bold text-left" style={{ fontSize: "13px" }}>
                              Provinsi
                            </div>
                            <div className="">
                              <Select
                                value={provOpts.selectedOpt}
                                options={provOpts.data}
                                isLoading={provOpts.isLoad}
                                menuPlacement="auto"
                                className="w-full rounded-md text-left text-sm text-gray-600"
                                onFocus={onSelectProvOpt}
                                onChange={(value) => {
                                  setProvOpts({ ...provOpts, selectedOpt: value })
                                }}
                                placeholder="Pilih Provinsi"
                              />
                            </div>
                          </div>
                          <div className="space-y-1">
                            <div className="font-bold text-left" style={{ fontSize: "13px" }}>
                              Kota / Kabupaten
                            </div>
                            <div className="">
                              <Select
                                value={cityOpts.selectedOpt}
                                options={cityOpts.data}
                                isLoading={cityOpts.isLoad}
                                menuPlacement="auto"
                                className="w-full rounded-md text-left text-sm text-gray-600"
                                onFocus={onSelectCityOpt}
                                onChange={(value) => {
                                  setCityOpts({ ...cityOpts, selectedOpt: value })
                                }}
                                placeholder="Pilih Kota/Kab"
                              />
                            </div>
                          </div>
                          <div className="space-y-1">
                            <div className="font-bold text-left" style={{ fontSize: "13px" }}>
                              Kecamatan
                            </div>
                            <div className="">
                              <Select
                                value={subdistrictOpts.selectedOpt}
                                options={subdistrictOpts.data}
                                isLoading={subdistrictOpts.isLoad}
                                menuPlacement="auto"
                                className="w-full rounded-md text-left text-sm text-gray-600"
                                onFocus={onSelectSubdistrictOpt}
                                onChange={(value) => {
                                  setSubdistrictOpts({ ...subdistrictOpts, selectedOpt: value })
                                }}
                                placeholder="Pilih Kota/Kab"
                              />
                            </div>
                          </div>
                          <div className="space-y-1">
                            <div className="font-bold text-left" style={{ fontSize: "13px" }}>
                              Kode Pos
                            </div>
                            <div className="border-b border-gray-400">
                              <input
                                className="outline-none w-full py-1" placeholder=""
                                value={form.postCode}
                                onChange={e => setForm({ ...form, postCode: e.target.value })}
                              />
                            </div>
                          </div>
                          <div className="space-y-1">
                            <div className="font-bold text-left" style={{ fontSize: "13px" }}>
                              Alamat Lengkap
                            </div>
                            <div className="border-b border-gray-400">
                              <textarea
                                className="outline-none w-full py-1" rows={3}
                                value={form.fullAddress}
                                onChange={e => setForm({ ...form, fullAddress: e.target.value })}
                              />
                            </div>
                          </div>
                          <div className="w-full">
                            <button
                              type="button"
                              className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                              onClick={onAdd}
                            >
                              Simpan
                            </button>
                          </div>
                        </div>

                      </>
                    )
                }

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}