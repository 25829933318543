import Header from "../../component/Header"
import FeatureBox from "./comp/FeatureBox/FeatureBox"
import FeaturedItemBox from "./comp/FeaturedItemBox/FeaturedItemBox"
import Footer from "../../component/Footer/Footer"
import SimpleCategoryBox from "./comp/SimpleCategoryBox"
import SimpleSlider from "./comp/SimpleSlider"
import SmpFeatured from "./comp/SmpFeatured"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useRef, useState } from "react"
import { setIsTransactableMode } from "../../component/HeaderSlice"
import SmaFeatured from "./comp/SmaFeatured"
import { RiArrowRightSLine } from "react-icons/ri"
import { AiOutlineWhatsApp } from "react-icons/ai"
import { GiBookshelf } from 'react-icons/gi'
import { useHistory } from "react-router"
import LatestBookBox from "./comp/LatestBookBox"
import MostPopularBookBox from "./comp/MostPopularBookBox"
import { openMacaDialog } from "../../app/GlobalSlice"

export default function IndexPage() {
  const dispatch = useDispatch()

  const { screenSize } = useSelector(state => state.global)

  useEffect(() => {
    dispatch(setIsTransactableMode(true))
    dispatch(openMacaDialog())
  }, [])

  return (
    <>
      <Header />
      <div
        className="space-y-5"
        style={{
          paddingTop: screenSize.width > 500 ? "80px" : "0px",
        }}
      >
        <SimpleSlider />
        <LatestBookBox />
        <MostPopularBookBox />
        <div
          className={
            "" +
            (screenSize.width > 500 ? " space-y-5 " : " py-10 space-y-20 divide-y divide-gray-300 ")
          }
        >
          <SmpFeatured />
          <SmaFeatured />
        </div>
        <div className="w-full">
          <img width="100%" src={(window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/" + (screenSize.width > 500 ? 'banner-variant-buku' : 'banner-variant-buku-mobile') + ".jpg"} />
        </div>
        <FeaturedItemBox />
        <Footer />
      </div>
    </>
  )
}