import { useSelector } from "react-redux"

export default function Loading() {
  const { loadProps } = useSelector(state => state.loading)

  return (
    <div
      className="fixed left-0 right-0 top-0 bottom-0 bg-gray-600 bg-opacity-50"
      style={{
        zIndex: "999"
      }}
    >
      <div className="w-full h-full flex justify-center">
        <div
          className="rounded-md bg-white relative"
          style={{
            width: "400px",
            height: "400px",
            marginTop: "80px"
          }}
        >
          <div
            className="w-full absolute left-1/2 bottom-1/2 text-center font-bold animate-pulse"
            style={{
              marginLeft: "-100px",
              marginTop: "200px"
            }}
          >
            <img
              className="mb-2"
              src={(window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/logo.png"}
              width="200px"
            />
          </div>
          <div
            className="w-full absolute bottom-0 mb-10 text-center font-bold animate-pulse"
            style={{
              fontSize: "18px"
            }}
          >
            {loadProps.message}
          </div>
        </div>
      </div>
    </div>
  )
}