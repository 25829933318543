import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  hasChecked: false,
  accountDetail: null,
  changePasswordDialog: {
    isOpen: false
  },
  addNewAddressDialog: {
    isOpen: false
  },
  addresses: [],
  orders: []
}

export const MyAccountSlice = createSlice({
  name: 'MyAccount',
  initialState,
  reducers: {
    setAccountDetail: (state, { payload }) => {
      state.accountDetail = payload
    },
    resetAccountDetail: state => {
      state.accountDetail = null
    },
    openChangePasswordDialog: state => {
      state.changePasswordDialog.isOpen = true
    },
    closeChangePasswordDialog: state => {
      state.changePasswordDialog.isOpen = false
    },
    openAddNewAddressDialog: state => {
      state.addNewAddressDialog.isOpen = true
    },
    closeAddNewAddressDialog: state => {
      state.addNewAddressDialog.isOpen = false
    },
    setAddresses: (state, { payload }) => {
      state.addresses = payload
    },
    setOrders: (state, { payload }) => {
      state.orders = payload
    },
    setHasChecked: (state, { payload }) => {
      state.hasChecked = payload
    }
  }
})

export const {
  setAccountDetail,
  resetAccountDetail,
  openChangePasswordDialog,
  closeChangePasswordDialog,
  openAddNewAddressDialog,
  closeAddNewAddressDialog,
  setAddresses,
  setOrders,
  setHasChecked
} = MyAccountSlice.actions

export default MyAccountSlice.reducer