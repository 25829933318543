import Header from "../../component/Header"
import Footer from "../../component/Footer/Footer"
import { useHistory, useParams } from "react-router"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { format } from "date-fns"
import id from 'date-fns/locale/id'
import { numToRupiahFormat } from '../../util/currency'
import toast from "react-hot-toast"
import { RiFileCopyFill } from "react-icons/ri"

export default function OrderDetailPage() {

  const { screenSize } = useSelector(state => state.global)

  let { id } = useParams()

  const { orders } = useSelector(state => state.myAccount)

  const [order, setOrder] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (orders.length > 0) {
      const ord = orders.find(o => o.id_checkout == id)
      if (ord != undefined) {
        setOrder(ord)
      }
    }
  }, [orders])

  return (
    <>
      <Header />
      <div
        className="w-full py-2 flex justify-center"
        style={{
          paddingTop: screenSize.width > 500 ? "120px" : "10px"
        }}
      >
        <div className="container bg-white">
          {order != null && (
            <>
              <div
                className={
                  "font-bold text-left mb-8 " +
                  (screenSize.width > 500 ? "" : "px-5")
                }
                style={{ fontSize: "22px" }}
              >
                Detail Transaksi
              </div>
              <div
                className={
                  screenSize.width > 500 ? "flex space-x-10" : "flex flex-col space-y-5 px-5"
                }
              >
                <div className="flex flex-1 flex-col space-y-3">
                  <div
                    className={
                      "font-bold text-left "
                    }
                  >
                    Rincian Barang
                  </div>
                  <div className="space-y-5">
                    {order.daftar_buku.map(item => (<CartDetail item={item} />))}
                  </div>
                </div>
                <RightSide order={order} />
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}

function CartDetail({
  item
}) {
  const history = useHistory()

  const { screenSize } = useSelector(state => state.global)

  return (
    <div
      className="py-3 px-8 rounded-md border shadow-md text-gray-600 space-y-5"
    >
      <div>
        <div className="px-3 flex items-center justify-between">
          <div className="flex items-center">
            <img
              src={item.daftar_gambar_buku[0]}
              width="100px"
            />
            <div className="flex flex-col text-left ml-5 space-y-2" style={{ width: "230px" }}>
              <label className="cursor-pointer font-bold" style={{ fontSize: "17px" }}>{item.buku}</label>
              <label className="text-gray-600" style={{ fontSize: "14px" }}>
                {item.tipe_buku == 'full_color' ? 'Full Color' : item.tipe_buku == 'dua_warna' ? 'Dua Warna' : item.tipe_buku == 'satu_warna' ? 'Satu Warna' : 'Digital'} - {item.jumlah_buku} barang</label>
              <label className="font-bold" style={{ fontSize: "14px" }}>Rp {numToRupiahFormat(item.harga_buku)}</label>
            </div>
          </div>
          {screenSize.width > 500 && (
            <div className="font-bold text-blue-700" style={{ fontSize: "17px" }}>
              Rp {numToRupiahFormat(item.subtotal)}
            </div>
          )}
        </div>
      </div>
      { screenSize.width < 500 && (
        <div className="pt-3 flex items-center justify-between">
          <label>Subtotal</label>
          <label className="font-bold text-blue-700" style={{ fontSize: "17px" }}> Rp {numToRupiahFormat(item.subtotal)} </label>
        </div>
      )}
      { item.tipe_buku != 'digital' && (
        <div className="py-5 flex justify-end border-t">
          <button
            className="p-2 px-5 bg-blue-700 text-white rounded-full"
            onClick={() => history.push("/product-detail/" + item.id_buku)}
          >
            Beli Lagi
        </button>
        </div>
      )}
    </div>
  )
}

function RightSide({
  order
}) {
  const history = useHistory()
  const { screenSize } = useSelector(state => state.global)
  useEffect(() => {
    console.log(order)
  }, [order])

  return (
    <div
      className="space-y-5"
      style={{
        width: screenSize.width > 500 ? "380px" : "100%"
      }}
    >
      <div>
        <button
          className="w-full py-2 rounded-md bg-blue-600 hover:bg-blue-900 text-white" style={{ fontFamily: "Nunito" }}
          onClick={() => history.push("/checkout-detail/" + order.id_checkout)}
        >
          Lihat cara pembayaran
        </button>
      </div>
      <OrderInfo order={order} />
      <PaymentDetail order={order} />
      { (order.potongan_ongkir != null || (order.id_kurir != null && order.id_kurir_service != null)) && <ShippingDetail order={order} />}
    </div>
  )
}

function OrderInfo({
  order
}) {
  return (
    <div className="space-y-3">
      <div className="font-bold text-left">
        Info Pemesanan
      </div>
      <div
        className="text-left p-5 px-8 rounded-md border shadow-md text-gray-600 space-y-5"
        style={{ fontSize: "14px" }}
      >
        <div className="space-y-3">
          <div className="font-bold">
            Status Pembayaran
          </div>
          <div
            className=""
          >
            <label className="p-2 rounded-md bg-blue-100 font-bold text-blue-900">{order.payment_status}</label>
          </div>
        </div>
        <div className="space-y-3">
          <div className="font-bold">
            Status Pesanan
          </div>
          <div
            className=""
          >
            <label className="p-2 rounded-md bg-blue-100 font-bold text-blue-700">{order.status_pemesanan}</label>
          </div>
        </div>
        <div className="space-y-1">
          <div className="font-bold">
            No. Transaksi
          </div>
          <div
            className=""
          >
            {order.id_checkout}
          </div>
        </div>
        <div className="space-y-1">
          <div className="font-bold">
            Tanggal Pemesanan
          </div>
          <div
            className=""
          >
            {format(new Date(order.tgl_input), "dd MMMM yyyy, HH:mm:ss", { locale: id })}
          </div>
        </div>
      </div>
    </div>
  )
}

function PaymentDetail({
  order
}) {
  return (
    <div className="space-y-3">
      <div className="font-bold text-left">
        Rincian Pembayaran
      </div>
      <div
        className="text-left p-5 px-8 rounded-md border shadow-md text-gray-600 space-y-5"
        style={{ fontSize: "14px" }}
      >

        <div className="pb-3 space-y-2" style={{ fontSize: "14px" }}>
          <div className="flex justify-between">
            <label>Total Harga</label>
            <label>Rp {numToRupiahFormat(parseInt(order.biaya_buku))}</label>
          </div>
          <div className="flex justify-between">
            <label>Biaya Pengiriman</label>
            <label>Rp {numToRupiahFormat(parseInt(order.biaya_ongkir))}</label>
          </div>
          <div className="flex justify-between">
            <label>Biaya Admin</label>
            <label>Rp {numToRupiahFormat(parseInt(order.biaya_pg))}</label>
          </div>
        </div>
        <div className="py-3 flex justify-between font-bold border-t border-b">
          <label className="text-black">Total Biaya Belanja</label>
          <label className="text-blue-500">Rp {numToRupiahFormat(order.amount)}</label>
        </div>
        <div className="pt-3 space-y-1">
          <div className="font-bold">
            Metode Transaksi
          </div>
          <div
            className=""
          >
            {order.payment_channel}
          </div>
        </div>

      </div>
    </div>
  )
}

function ShippingDetail({
  order
}) {
  return (
    <div className="space-y-3">
      <div className="font-bold text-left">
        Rincian Pengiriman
      </div>
      <div
        className="text-left p-5 px-8 rounded-md border shadow-md text-gray-600 space-y-5"
        style={{ fontSize: "14px" }}
      >

        <div className="flex flex-col space-y-2">
          <label
            className="font-bold text-black"
          >
            Alamat Tujuan Pengiriman
          </label>
          <label>
            {order.alamat.nama_penerima} | {order.alamat.no_hp_penerima}
          </label>
          <label>
            {order.alamat.alamat}, {order.alamat.subdistrict_name}, {order.alamat.city_name}, {order.alamat.province} - {order.alamat.kode_pos}
          </label>
        </div>

        <div className="flex flex-col space-y-2">
          <label
            className="font-bold text-black"
          >
            Metode Pengiriman
          </label>
          <label
            className="font-bold"
          >
            {
              order.potongan_ongkir != null ?
                'Gratis Ongkos Kirim'
                :
                order.id_kurir.toUpperCase() + ' - ' + order.id_kurir_service
            }
          </label>
        </div>

        {order.no_resi && (
          <div className="flex flex-col space-y-2">
            <label
              className="font-bold text-black"
            >
              No. Resi
            </label>
            <div className="flex items-center justify-between rounded-md border border-gray-300">
              <label className="p-2">
                {order.no_resi}
              </label>
              <button
                className="p-1 px-2 border-l"
                title="salin"
                onClick={() => {
                  navigator.clipboard.writeText(order.no_resi)
                  toast.success("Berhasil salin text", { style: { backgroundColor: "green", color: "white" } })
                }}
              >
                <RiFileCopyFill className="h-6 w-6" />
              </button>
            </div>
          </div>
        )}

      </div>
    </div>
  )
}
