import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { RiFileCopy2Fill, RiFileCopyFill, RiLoader5Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import Footer from "../../component/Footer/Footer";
import Header from "../../component/Header";
import { setIsTransactableMode } from "../../component/HeaderSlice";
import { openInstructionDialog, setPaymentInstructions } from "./CheckoutResultSlice";
import PaymentInstructionDialog from "./comp/PaymentInstructionDialog";
import { format } from "date-fns"
import id from 'date-fns/locale/id'
import { numToRupiahFormat } from '../../util/currency'

const fetchInstructions = async (trxId) => {
  const finalUrl =
    window._HOST + "api/v1/user/checkouts/instruction?id_checkout=" + trxId

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

const fetchDetail = async (trxId) => {
  const finalUrl =
    window._HOST + "api/v1/user/checkouts/detailhistory?id_checkout=" + trxId

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export default function CheckoutResultPage() {
  const dispatch = useDispatch()
  const history = useHistory()

  let { trxId } = useParams()

  const { instructionDialog } = useSelector(state => state.checkoutResult)
  const { screenSize } = useSelector(state => state.global)

  const [transaction, setTransaction] = useState(null)
  const [isLoad, setIsLoad] = useState(true)

  useEffect(() => {
    dispatch(setIsTransactableMode(false))
    window.scrollTo(0, 0)

    Promise
      .all([fetchInstructions(trxId), fetchDetail(trxId)])
      .then(response => {
        if (response[0].success && response[1].success) {
          setTransaction(response[1].data)
          dispatch(setPaymentInstructions(response[0].data))
        }
        else {

        }
      })
      .catch(error => {

      })
      .finally(() => setIsLoad(false))
  }, [])

  return (
    <>
      { instructionDialog.isOpen && <PaymentInstructionDialog />}
      <Header />
      <div
        className="w-full py-2 flex justify-center"
        style={{
          paddingTop: screenSize.width > 500 ? "120px" : "10px",
        }}
      >
        <div className="container bg-white" style={{ width: "700px" }}>
          {
            isLoad ?
              (
                <div className="h-full w-full flex items-center justify-center text-blue-500">
                  <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
                </div>
              )
              :
              (<Content trxId={trxId} trx={transaction} />)
          }

        </div>
      </div>
      <Footer />
    </>
  )
}

function Content({
  trxId,
  trx
}) {
  const { screenSize } = useSelector(state => state.global)
  const dispatch = useDispatch()

  return (
    <div
      className={
        " py-5 px-10 " +
        (screenSize.width > 500 ? " rounded-lg border shadow-lg " : "")
      }
    >
      <div className="mb-2">
        <img
          src="http://localhost/rest_presensi_bimbel//assets/images/logo.png"
          width="100px"
        />
      </div>
      <div className="space-y-10 mb-5">
        <div className="flex justify-center">
          <div className="space-y-3">
            <div className="uppercase" style={{ fontFamily: "Inter", fontSize: "20px" }}>
              Pembayaran melalui <span className="font-bold" >{trx.payment_channel}</span>
            </div>
            <div className="font-bold text-red-600">
              Pastikan anda melakukan pembayaran sebelum melewati batas pembayaran dan dengan nominal yang tepat
          </div>
          </div>
        </div>
        <div
          className={
            "grid gap-2 " +
            (screenSize.width > 500 ? " grid-cols-2 " : " grid-cols-1 gap-y-3")
          }
        >
          <div className="space-y-5">
            <div className="text-left space-y-1">
              <div className="">
                Batas Pembayaran
            </div>
              <div className="font-bold text-red-600">
                <label>
                  {format(new Date(trx.expired_datetime), "dd MMMM yyyy, HH:mm", { locale: id })} WIB
                </label>
              </div>
            </div>
            <div className="text-left space-y-1">
              <div className="">
                Nama Pelanggan
            </div>
              <div className="font-bold ">
                <label>
                  {trx.customer_name}
                </label>
              </div>
            </div>
          </div>
          <div className="space-y-5">
            <div className="text-left space-y-2">
              <div className="font-bold">
                No. Invoice
            </div>
              <div className="flex items-center justify-between rounded-md border border-gray-300">
                <label className="p-2">
                  {trx.id_checkout}
                </label>
                <button
                  className="p-1 px-2 border-l"
                  title="salin"
                  onClick={() => {
                    navigator.clipboard.writeText(trx.id_checkout)
                    toast.success("Berhasil salin text", { style: { backgroundColor: "green", color: "white" } })
                  }}
                >
                  <RiFileCopyFill className="h-6 w-6" />
                </button>
              </div>
            </div>
            <div className="text-left space-y-2">
              <div className="font-bold">
                Kode Bayar/Nomor VA
            </div>
              <div className="flex items-center justify-between rounded-md border border-gray-300">
                <label className="p-2">
                  {trx.pay_code}
                </label>
                <button
                  className="p-2 px-2 border-l"
                  title="salin"
                  onClick={() => {
                    navigator.clipboard.writeText(trx.pay_code)
                    toast.success("Berhasil salin text", { style: { backgroundColor: "green", color: "white" } })
                  }}
                >
                  <RiFileCopyFill className="h-6 w-6" />
                </button>
              </div>
            </div>
            <div className="text-left space-y-2">
              <div className="font-bold">
                Jumlah Tagihan
            </div>
              <div className="rounded-md border border-gray-300 p-2">
                <label>
                  Rp {numToRupiahFormat(trx.amount)}
                </label>
              </div>
            </div>
            <div>
              <button
                className="w-full p-2 rounded-md bg-blue-600 text-white hover:bg-blue-700 hover:shadow-md"
                onClick={() => dispatch(openInstructionDialog(trxId))}
              >
                Cara Pembayaran
            </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  )

}