import './App.css'

import { Toaster } from 'react-hot-toast'
import {
  Switch,
  Route
} from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { RiLoader5Fill, RiSendPlane2Fill } from 'react-icons/ri'
import { setIsLoading, setScreenSize, setSystemProfile } from './app/GlobalSlice'
import { useEffect, useState } from 'react'
import IndexPage from './pages/Index/IndexPage'
import ProductDetailPage from './pages/ProductDetail/ProductDetailPage'
import ProductPage from './pages/Product/ProductPage'
import LoginPage from './pages/Login/LoginPage'
import RegisterPage from './pages/Register/RegisterPage'
import MyAccountPage from './pages/MyAccount/MyAccountPage'
import CartPage from './pages/Cart/CartPage'
import CheckoutPage from './pages/Checkout/CheckoutPage'
import OrderDetailPage from './pages/OrderDetail/OrderDetailPage'
import Loading from './component/Loading/Loading'
import axios from 'axios'
import { setAccountDetail, setHasChecked } from './pages/MyAccount/MyAccountSlice'
import { setItems } from './pages/Cart/CartSlice'
import { AiOutlineClose, AiOutlineWhatsApp } from 'react-icons/ai'
import CheckoutResultPage from './pages/CheckoutResult/CheckoutResultPage'
import ChangePasswordDialog from './pages/MyAccount/comp/ChangePasswordDialog'
import ContactUsPage from './pages/ContactUs/ContactUsPage'
import ContentPage from './component/ContentPage/ContentPage'
import MacaDialog from './component/MacaDialog'

const checkUserSessionServer = async () => {
  const finalUrl =
    window._HOST + "api/v1/user/profiles"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

const getPendingCartServer = async () => {
  const finalUrl =
    window._HOST + "api/v1/user/carts/"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

const getSystemProfile = async () => {
  const finalUrl =
    window._HOST + "api/v1/systemprofile"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function App() {
  const dispatch = useDispatch()

  const { isLoading, screenSize, macaDialog } = useSelector(state => state.global)
  const { loadProps } = useSelector(state => state.loading)

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  const [isWaOpen, setIsWaOpen] = useState(false)
  const [waMsg, setWaMsg] = useState('')
  const [waNumber, setWaNumber] = useState(null)

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  useEffect(() => {
    dispatch(setScreenSize(windowDimensions))
  }, [windowDimensions])

  useEffect(() => {
    checkUserSession()
  }, [])

  useEffect(() => {
    getSystemProfile()
      .then(response => {
        if (response.success) {
          dispatch(setSystemProfile(response.data))
          const waNum = response.data.find(d => d.key_ == "telp")
          if (waNum != undefined) {
            setWaNumber(waNum.value_)
          }
        }
      })
      .catch(error => { })
  }, [])

  async function checkUserSession() {
    try {
      const userResp = await checkUserSessionServer()

      if (!userResp.success) {
        dispatch(setHasChecked(true))
        return;
      }

      const cartResp = await getPendingCartServer()
      if (!cartResp.success) {
        dispatch(setHasChecked(true))
        return;
      }

      dispatch(setHasChecked(true))
      dispatch(setAccountDetail(userResp.data[0]))
      dispatch(setItems(cartResp.data))

    }
    catch (errors) { }
  }

  return (
    <div className="App h-screen">
      {/* { macaDialog.isOpen && <MacaDialog />} */}
      { loadProps.isLoading && (<Loading />)}
      <Toaster
        position="top-right"
      />
      { isLoading &&
        (<div className="h-screen w-screen fixed bg-gray-200 bg-opacity-50 z-50">
          <div className="h-full absolute left-1/2 flex items-center">
            <div className="relative -left-1/2 text-blue-700">
              <RiLoader5Fill className="h-12 w-12 animate-spin" />
            </div>
          </div>
        </div>)
      }
      { screenSize.width < 500 && (<ChangePasswordDialog />)}
      <Switch>
        <Route path={"/contact-us"}>
          <ContactUsPage />
        </Route>
        <Route path={"/checkout-detail/:trxId"}>
          <CheckoutResultPage />
        </Route>
        <Route path={"/product-detail/:id"}>
          <ProductDetailPage />
        </Route>
        <Route path={"/product-detail/"}>
          <ProductDetailPage />
        </Route>
        <Route path={"/products/jenjang=:jenjangId"}>
          <ProductPage />
        </Route>
        <Route path={"/products/kelas=:kelas"}>
          <ProductPage />
        </Route>
        <Route path={"/products"}>
          <ProductPage />
        </Route>
        <Route path={"/register"}>
          <RegisterPage />
        </Route>
        <Route path={"/login"}>
          <LoginPage />
        </Route>
        <Route path={"/my-account/:mode"}>
          <MyAccountPage />
        </Route>
        <Route path={"/my-account"}>
          <MyAccountPage />
        </Route>
        <Route path={"/cart"}>
          <CartPage />
        </Route>
        <Route path={"/checkout"}>
          <CheckoutPage />
        </Route>
        <Route path={"/order-detail/:id"}>
          <OrderDetailPage />
        </Route>
        <Route path={"/:page"}>
          <ContentPage />
        </Route>
        <Route path={"/"}>
          <IndexPage />
        </Route>
      </Switch>
      <div
        className={
          "fixed bottom-10 " +
          (screenSize.width > 500 ?
            screenSize.width > 1600 ?
              " right-60 "
              :
              " right-16 "
            :
            " right-3 ")
        }
      >
        <div className="right-0 bottom-5 flex flex-col items-end space-y-3">
          {
            waNumber != undefined && waNumber != null && (
              <>
                {isWaOpen && (
                  <div
                    className="shadow-lg"
                    style={{
                      width: "300px"
                    }}
                  >
                    <div className="p-3 text-left rounded-tl-md rounded-tr-md bg-green-600 text-white" style={{ fontSize: "14px" }}>
                      Chat dengan kami di Whatsapp!
              </div>
                    <div className="p-3" style={{ fontSize: "14px", backgroundColor: "#e5ddd5" }}>
                      <div className="p-3 text-left rounded-lg bg-white">
                        Hallo, Ada yang bisa kami bantu?
                </div>
                    </div>
                    <div className="p-3 flex items-center bg-white rounded-bl-md rounded-br-md space-x-2">
                      <div className="flex-1 p-2 border border-gray-400 rounded-md" style={{ fontSize: "14px" }}>
                        <input
                          className="w-full outline-none"
                          value={waMsg}
                          onChange={e => setWaMsg(e.target.value)}
                        />
                      </div>
                      <button
                        className="hover:text-green-600"
                        onClick={() => window.open("https://api.whatsapp.com/send/?phone=" + waNumber + "&text=" + waMsg + "&app_absent=0", "_blank")}
                      >
                        <RiSendPlane2Fill className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                )}
                <div>
                  <button
                    className="p-2 rounded-full text-white shadow-lg" style={{ backgroundColor: "#4ece5d" }}
                    onClick={() => setIsWaOpen(!isWaOpen)}
                  >
                    {
                      !isWaOpen ?
                        <AiOutlineWhatsApp className="w-10 h-10" />
                        :
                        <AiOutlineClose className="w-10 h-10" />
                    }
                  </button>
                </div>
              </>
            )
          }
        </div>

      </div>
    </div>
  );
}

export default App;
