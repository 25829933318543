import axios from "axios"
import { useEffect } from "react"
import { useState } from "react"
import { RiLoader5Fill } from "react-icons/ri"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import ErrorDialog from "../../../component/ErrorDialog"
import { numToRupiahFormat } from '../../../util/currency'

const fetchDataSmaFeatured = async () => {
  const finalUrl =
    window._HOST + "api/v1/catalog/catalogsamplepopularsma"

  const response =
    await axios.get(finalUrl)

  return response.data
}

const fetchDataSmpFeatured = async () => {
  const finalUrl =
    window._HOST + "api/v1/catalog/catalogsamplepopularsmp"

  const response =
    await axios.get(finalUrl)

  return response.data
}

const fetchDataSdFeatured = async () => {
  const finalUrl =
    window._HOST + "api/v1/catalog/catalogsamplepopularsd"

  const response =
    await axios.get(finalUrl)

  return response.data
}

const fetchDataUmumFeatured = async () => {
  const finalUrl =
    window._HOST + "api/v1/catalog/catalogsamplepopularumum"

  const response =
    await axios.get(finalUrl)

  return response.data
}

export default function MostPopularBookBox() {
  const { screenSize } = useSelector(state => state.global)

  const [latestBookUmum, setLatestBookUmum] = useState([])
  const [latestBookSd, setLatestBookSd] = useState([])
  const [latestBookSmp, setLatestBookSmp] = useState([])
  const [latestBookSma, setLatestBookSma] = useState([])

  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    errorMessage: ''
  })

  const openErrorDialog = (errMsg, errD) => ({ ...errD, isOpen: true, errorMessage: errMsg })
  const closeErrorDialog = (errD) => ({ ...errD, isOpen: false, errorMessage: '' })

  const [isLoad, setIsLoad] = useState(false)

  const [featuredCat, setFeaturedCat] = useState({
    selectedId: 0,
    data: [
      {
        id: 0,
        label: 'SD',
      },
      {
        id: 1,
        label: 'SMP',
      },
      {
        id: 2,
        label: 'SMA',
      },
      {
        id: 3,
        label: 'UMUM',
      },
    ]
  })

  useEffect(() => {
    Promise
      .all([fetchDataSmpFeatured(), fetchDataSmaFeatured(), fetchDataSdFeatured(), fetchDataUmumFeatured()])
      .then(response => {
        if (response[0].success && response[1].success && response[2].success && response[3].success) {
          const manipulatedDataSmp = response[0].data.map(d => ({
            id: d.id_buku,
            title: d.buku,
            desc: d.deskripsi,
            img: d.img_thumb,
            hargaPrintFullColor: d.harga_buku_full_color,
            hargaPrintDuaWarna: d.harga_buku_dua_warna,
            hargaDigital: d.harga_buku_digital,
            hargaPrintFullColorWithPromotion: d.harga_buku_full_color_with_promotion,
            hargaPrintDuaWarnaWithPromotion: d.harga_buku_dua_warna_with_promotion,
            hargaDigitalWithPromotion: d.harga_buku_digital_with_promotion,
            potonganHargaBukuFullColor: d.potongan_harga_buku_full_color,
            potonganHargaBukuDuaWarna: d.potongan_harga_buku_dua_warna,
            potonganHargaBukuDigital: d.potongan_harga_buku_digital
          }))

          setLatestBookSmp(manipulatedDataSmp)

          const manipulatedDataSma = response[1].data.map(d => ({
            id: d.id_buku,
            title: d.buku,
            desc: d.deskripsi,
            img: d.img_thumb,
            hargaPrintFullColor: d.harga_buku_full_color,
            hargaPrintDuaWarna: d.harga_buku_dua_warna,
            hargaDigital: d.harga_buku_digital,
            hargaPrintFullColorWithPromotion: d.harga_buku_full_color_with_promotion,
            hargaPrintDuaWarnaWithPromotion: d.harga_buku_dua_warna_with_promotion,
            hargaDigitalWithPromotion: d.harga_buku_digital_with_promotion,
            potonganHargaBukuFullColor: d.potongan_harga_buku_full_color,
            potonganHargaBukuDuaWarna: d.potongan_harga_buku_dua_warna,
            potonganHargaBukuDigital: d.potongan_harga_buku_digital
          }))

          setLatestBookSma(manipulatedDataSma)

          const manipulatedDataSd = response[2].data.map(d => ({
            id: d.id_buku,
            title: d.buku,
            desc: d.deskripsi,
            img: d.img_thumb,
            hargaPrintFullColor: d.harga_buku_full_color,
            hargaPrintDuaWarna: d.harga_buku_dua_warna,
            hargaDigital: d.harga_buku_digital,
            hargaPrintFullColorWithPromotion: d.harga_buku_full_color_with_promotion,
            hargaPrintDuaWarnaWithPromotion: d.harga_buku_dua_warna_with_promotion,
            hargaDigitalWithPromotion: d.harga_buku_digital_with_promotion,
            potonganHargaBukuFullColor: d.potongan_harga_buku_full_color,
            potonganHargaBukuDuaWarna: d.potongan_harga_buku_dua_warna,
            potonganHargaBukuDigital: d.potongan_harga_buku_digital
          }))

          setLatestBookSd(manipulatedDataSd)

          const manipulatedDataUmum = response[3].data.map(d => ({
            id: d.id_buku,
            title: d.buku,
            desc: d.deskripsi,
            img: d.img_thumb,
            hargaPrintFullColor: d.harga_buku_full_color,
            hargaPrintDuaWarna: d.harga_buku_dua_warna,
            hargaDigital: d.harga_buku_digital,
            hargaPrintFullColorWithPromotion: d.harga_buku_full_color_with_promotion,
            hargaPrintDuaWarnaWithPromotion: d.harga_buku_dua_warna_with_promotion,
            hargaDigitalWithPromotion: d.harga_buku_digital_with_promotion,
            potonganHargaBukuFullColor: d.potongan_harga_buku_full_color,
            potonganHargaBukuDuaWarna: d.potongan_harga_buku_dua_warna,
            potonganHargaBukuDigital: d.potongan_harga_buku_digital
          }))

          setLatestBookUmum(manipulatedDataUmum)
        }
        else {
          const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
          setErrorDialog(openErrorDialog(errorMsg, errorDialog))
        }
      })
      .catch(errors => {
        setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
      })
      .finally(() => setIsLoad(false))

    setIsLoad(true)

  }, [])

  return (
    <div
      className="w-full flex justify-center"
    >
      <ErrorDialog
        isShow={errorDialog.isOpen}
        errorMessage={errorDialog.errorMessage}
        onClose={() => setErrorDialog(closeErrorDialog(errorDialog))}
      />
      <div
        className="container bg-white"
      >
        <div
          className={
            " flex items-center justify-start border-b mb-2 " +
            (screenSize.width > 500 ? " px-2 " : " px-5 ")
          }
        >
          <label className="font-bold mr-5 w-36 text-left" style={{ fontFamily: "Nunito", fontSize: "20px" }}>
            Paling Populer
          </label>
          {featuredCat.data.map(d => (
            <button
              className={"py-3 px-6 uppercase hover:bg-green-200 border-b-2 border-transparent " + (d.id == featuredCat.selectedId ? " border-green-500 " : "  ")}
              onClick={() => setFeaturedCat({ ...featuredCat, selectedId: d.id })}
              style={{ fontFamily: "Inter", fontSize: screenSize.width > 500 ? "16px" : "14px" }}
            >
              { screenSize.width > 500 ? d.label : d.label.replace('Print', '')}
            </button>
          ))}
        </div>
        {
          isLoad ?
            (
              <div className="h-full w-full flex items-center justify-center text-blue-500">
                <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
              </div>
            )
            :
            (<div
              className={
                "grid " +
                (screenSize.width > 500 ? " grid-cols-5 gap-y-3 " : " grid-cols-2 gap-y-1 ")
              }
            >
              {
                featuredCat.selectedId == 1 ?
                  latestBookSmp.filter((i, index) => index < (screenSize.width > 500 ? 5 : 4)).map(i => (
                    <ItemBox item={i} />
                  ))
                  :
                  featuredCat.selectedId == 2 ? latestBookSma.filter((i, index) => index < (screenSize.width > 500 ? 5 : 4)).map(i => (
                    <ItemBox item={i} />
                  ))
                  :
                  featuredCat.selectedId == 3 ? latestBookUmum.filter((i, index) => index < (screenSize.width > 500 ? 5 : 4)).map(i => (
                    <ItemBox item={i} />
                  ))
                  :
                  latestBookSd.filter((i, index) => index < (screenSize.width > 500 ? 5 : 4)).map(i => (
                    <ItemBox item={i} />
                  ))
              }
            </div>)
        }
      </div>
    </div>
  )
}

function ItemBox({ item }) {
  const history = useHistory()
  const { screenSize } = useSelector(state => state.global)

  return (
    <button
      id={item.id}
      className="py-2 relative cursor-pointer group border border-transparent flex flex-col justify-start hover:border-green-300 hover:shadow-md"
      onClick={() => history.push("/product-detail/" + item.id)}
    >
      {
        item.potonganHargaBukuFullColor != "0%" && (
          <div
            className="absolute right-5 rounded-full bg-red-500 text-white shadow-lg"
            style={{
              width: "50px",
              height: "50px"
            }}
          >
            <div className="h-full flex items-center justify-center">
              -{item.potonganHargaBukuFullColor}
            </div>
          </div>
        )
      }
      <div className="w-full flex justify-center">
        <img
          src={item.img}
          width={
            screenSize.width > 500 ? " 180px " : "160px"
          }
        />
      </div>
      <div
        className={
          "py-2 flex flex-col text-left " +
          (screenSize.width > 500 ? " px-5 " : " px-4 ")
        }
      >
        <label
          className="cursor-pointer group-hover:text-green-600"
          style={{ fontSize: "15px" }}
        >
          {item.title}
        </label>
        {/* <label
          className={
            "font-bold cursor-pointer " +
            (item.potonganHargaBukuFullColor != "0%" ? " line-through " : "")
          }
          style={{
            fontSize: item.potonganHargaBukuFullColor != "0%" ? "13px" : "16px"
          }}
        >
          {"Rp " + numToRupiahFormat(item.hargaPrintFullColor)}
        </label> */}
        <div
          className="flex flex-col"
        >
          <label style={{ fontSize: "13px", marginTop: "5px", fontWeight: "700" }}>Harga mulai dari</label>
          <label className="font-bold text-red-600">
            {"Rp " + numToRupiahFormat(item.hargaDigitalWithPromotion)}
          </label>
        </div>
        {/* {
          item.potonganHargaBukuFullColor != "0%" && (
            <label className="font-bold text-red-600">
              {"Rp " + numToRupiahFormat(item.hargaPrintFullColorWithPromotion)}
            </label>
          )
        } */}
      </div>
    </button>
  )
}