import Header from "../../component/Header"
import Footer from "../../component/Footer/Footer"
import { useEffect, useState } from "react"
import { RiEyeCloseLine, RiEyeFill } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { setIsTransactableMode } from "../../component/HeaderSlice"
import { useHistory } from "react-router"
import axios from "axios"
import { startLoading, stopLoading } from "../../component/Loading/LoadingSlice"
import { setAccountDetail } from "../MyAccount/MyAccountSlice"
import { setItems, updateItemSynced } from "../Cart/CartSlice"

const register = async (payload) => {
  const finalUrl =
    window._HOST + "api/v1/auths/register"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

const getPendingCartServer = async () => {
  const finalUrl =
    window._HOST + "api/v1/user/carts/"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

const addToCartServer = async (payload) => {
  const finalUrl =
    window._HOST + "api/v1/user/carts"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export default function RegisterPage() {
  const dispatch = useDispatch()
  const history = useHistory()

  const { cartItems } = useSelector(state => state.cart)
  const { screenSize } = useSelector(state => state.global)
  const { accountDetail } = useSelector(state => state.myAccount)

  const [form, setForm] = useState({
    fullname: '',
    username: '',
    hp: '',
    password: '',
    isPasswordOpen: false
  })

  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    errorMessage: ''
  })
  const openErrorDialog = (errMsg, errD) => ({ ...errD, isOpen: true, errorMessage: errMsg })
  const closeErrorDialog = (errD) => ({ ...errD, isOpen: false, errorMessage: '' })

  function togglePassword() {
    setForm({
      ...form,
      isPasswordOpen: !form.isPasswordOpen
    })
  }

  const [focusedField, setFocusedField] = useState(null)

  const [errors, setErrors] = useState([])

  const [mode, setMode] = useState('form') //form or success

  useEffect(() => {
    dispatch(setIsTransactableMode(false))
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (accountDetail) {
      dispatch(setIsTransactableMode(true))
      history.push('/my-account')
    }
  }, [accountDetail])

  function onRegister() {
    const payload = {
      nama_user: form.fullname,
      email: form.username,
      password: form.password,
      password_confirmation: form.password,
      hp: form.hp
    }

    dispatch(startLoading("Tunggu ya, sedang memproses..."))

    doReg(payload)

    // register(payload)
    //   .then(response => {
    //     if (response.success) {
    //       dispatch(setAccountDetail(response.data))
    //       history.push("/")
    //     }
    //     else {
    //       setErrors(response.errors)
    //     }
    //   })
    //   .catch(errors => {

    //   })
    //   .finally(() => dispatch(stopLoading()))
  }

  function addUnSyncItemToCart() {
    const unSyncCartItems = cartItems.filter(i => i.notAddedYet != undefined && i.notAddedYet)
    if (unSyncCartItems.length > 0) {
      const promises = unSyncCartItems.map(async i => {
        const payloadReq = {
          id_buku: i.id_buku,
          tipe_buku: i.tipe_buku,
          jumlah_buku: i.jumlah_barang
        }

        try {
          const response = await addToCartServer(payloadReq)
          if (response.success) {
            dispatch(updateItemSynced({ id_buku: i.id_buku, tipe_buku: i.tipe_buku }))
          }
        } catch (errors) {
          setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
        }

      });

      return Promise.all(promises)
    }
    else {
      Promise.resolve(null)
    }
  }

  function getPendingCart() {
    getPendingCartServer()
      .then(response => {
        if (response.success) {
          const cartItems = response.data
          dispatch(setItems(cartItems))
          history.push("/")
        }
        else {
          const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
          setErrorDialog(openErrorDialog(errorMsg, errorDialog))
        }
      })
  }

  async function doReg(payload) {
    try {
      const regResp = await register(payload)
      if (regResp.success) {
        setMode('success')
        window.fbq('track', 'CompleteRegistration');
      }
      else {
        setErrors(regResp.errors)
      }

      // const _ = await addUnSyncItemToCart()

      // const cartResp = await getPendingCart()

      // if (cartResp.success) {
      //   const cartItems = cartResp.data
      //   dispatch(setItems(cartItems))
      //   history.push("/")
      // }

    }
    catch (errors) {
      setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
    }
    finally { dispatch(stopLoading()) }

  }


  useEffect(() => {
    console.log("err ", errors)
  }, [errors])

  return (
    <>
      <Header />
      <div
        className="w-full py-2 flex justify-center"
        style={{
          paddingTop: screenSize.width > 500 ? "200px" : "10px",
        }}
      >
        <div
          className={
            "container flex " +
            (screenSize.width > 500 ? " justify-end " : " justify-center ")
          }
        >
          {screenSize.width > 500 && (
            <div
              className="flex-1"
            >
              <div
                className="relative"
              >
                <img src={(window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/banner-web-05-3.png"} />
                <div className="absolute bottom-12 left-64">
                  <div className="flex space-x-3">
                    <button
                      className="p-2 px-5 rounded-md shadow-sm hover:shadow-lg flex bg-black text-white space-x-2"
                      onClick={() => window.open('https://play.google.com/store/apps/details?id=com.syibook', '_blank')}
                    >
                      <img src="/assets/images/google-play-transparent.png" width="20px" />
                      <label className="cursor-pointer font-bold" style={{ fontFamily: "Nunito" }}>Klik di sini</label>
                    </button>
                    <button
                      className="p-2 px-5 rounded-md shadow-sm hover:shadow-lg flex bg-black text-white space-x-2"
                      onClick={() => window.open('/assets/Syibook-Desktop.rar', '_blank')}
                    >
                      <img src="/assets/images/windows.png" width="20px" />
                      <label className="cursor-pointer font-bold" style={{ fontFamily: "Nunito" }}>Syibook Desktop</label>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={
              "py-5 px-8 rounded-md bg-white space-y-8 " +
              (screenSize.width > 500 ? " border border-gray-200 shadow-xl " : " justify-center ")
            }
            style={{
              width: "390px"
            }}
          >
            {
              mode == 'success' ?
                (
                  <RegistrationSuccess />
                )
                :
                (
                  <>
                    <div className="text-left" style={{ fontSize: "20px" }}>
                      Daftar
                    </div>
                    <div className="space-y-7 text-gray-600" style={{ fontSize: "14px" }}>
                      {errors.length > 0 && (
                        <div className={"p-2 border rounded-md bg-red-500 text-white text-left"}>
                          <ul className="list-disc px-5">
                            {errors.map(e => (
                              <li>{e}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <div className={"p-2 border " + (focusedField == 'fullname' ? " border-gray-400 shadow-md " : " border-gray-300 ")}>
                        <input
                          className="w-full outline-none"
                          onFocus={() => setFocusedField('fullname')}
                          onBlur={() => setFocusedField(null)}
                          placeholder="Nama lengkap"
                          onChange={e => setForm({ ...form, fullname: e.target.value })}
                        />
                      </div>
                      <div className={"p-2 border " + (focusedField == 'email' ? " border-gray-400 shadow-md " : " border-gray-300 ")}>
                        <input
                          className="w-full outline-none"
                          onFocus={() => setFocusedField('email')}
                          onBlur={() => setFocusedField(null)}
                          placeholder="email@domain.com"
                          onChange={e => setForm({ ...form, username: e.target.value })}
                        />
                      </div>
                      <div className={"p-2 border " + (focusedField == 'hp' ? " border-gray-400 shadow-md " : " border-gray-300 ")}>
                        <input
                          className="w-full outline-none"
                          onFocus={() => setFocusedField('hp')}
                          onBlur={() => setFocusedField(null)}
                          placeholder="No. HP. Cth: 628522xxxxx"
                          onChange={e => setForm({ ...form, hp: e.target.value })}
                        />
                      </div>
                      <div className={"p-2 border flex items-center " + (focusedField == 'password' ? " border-gray-400 shadow-md " : " border-gray-300 ")}>
                        <input
                          type={form.isPasswordOpen ? "text" : "password"}
                          className="w-full outline-none"
                          onFocus={() => setFocusedField('password')}
                          onBlur={() => setFocusedField(null)}
                          placeholder="Password"
                          onChange={e => setForm({ ...form, password: e.target.value })}
                        />
                        <button
                          className="px-1 outline-none"
                          onClick={togglePassword}
                          title={form.isPasswordOpen ? "sembunyikan password" : "Lihat password"}
                        >
                          {
                            form.isPasswordOpen ?
                              (<RiEyeCloseLine className="h-6 w-6" />)
                              :
                              (<RiEyeFill className="h-6 w-6" />)
                          }
                        </button>
                      </div>
                    </div>
                    <div>
                      <button
                        className="w-full py-2 text-center bg-green-500 text-white uppercase hover:bg-green-600 hover:shadow-lg"
                        onClick={onRegister}
                      >
                        Daftar
                      </button>
                    </div>
                    <div className="text-gray-500 space-x-2 flex flex-col items-center" style={{ fontSize: "14px" }}>
                      <label>Dengan mendaftar, saya menyetujui</label>
                      <div>
                        <span className="cursor-pointer text-green-600 font-bold mr-2" onClick={() => history.push('/tos')}>Syarat dan Ketentuan</span>
                        serta
                        <span className="cursor-pointer text-green-600 font-bold ml-2" onClick={() => history.push('/privacy')}>Kebijakan Privasi</span>
                      </div>
                    </div>
                    <div className="text-gray-500 space-x-2" style={{ fontSize: "14px" }}>
                      <span>Sudah punya akun?</span>
                      <span
                        className="font-bold text-red-600 cursor-pointer hover:underline"
                        onClick={() => history.push("/login")}
                      >
                        Log In
                      </span>
                    </div>
                  </>
                )
            }
          </div>
        </div>
      </div>
      <Footer />
    </>
  )

}

function RegistrationSuccess() {
  return (
    <div>
      <div className="text-left" style={{ fontSize: "20px" }}>
        Pendaftaran Berhasil!
      </div>
      <div
        className="my-3 rounded-md bg-blue-100 py-2"
      >
        <p>Cek inbox email kamu.</p>
        <p>Kami telah mengirimkan email berisi link verifikasi akun member Penerbit ALC.</p>
      </div>
      <div>
        <p>
          Unduh aplikasi Maca dan login dengan akun yang sudah diverifikasi untuk membaca buku-buku sampel digital kami.
          <span className="font-bold ml-2">Gratis!</span>
        </p>
      </div>
      <div>
        <button
          className="my-2 w-full py-2 rounded-md bg-blue-700 text-white hover:bg-blue-900"
          onClick={() => window.open('http://www.facebook.com', '_blank')}
        >
          Unduh Aplikasi
        </button>
      </div>

    </div>
  )
}