import Header from "../../component/Header"
import Footer from "../../component/Footer/Footer"

import { IoLocationSharp } from "react-icons/io5"
import { MdLocalShipping } from "react-icons/md"
import { FaCashRegister } from "react-icons/fa"
import { BsChevronDown } from "react-icons/bs"
import { AiOutlineShoppingCart } from "react-icons/ai"
import ChangeBillAddressDialog from "./comp/ChangeBillAddressDialog"
import SelectPaymentMethodDialog from "./comp/SelectPaymentMethodDialog"
import SelectShippingDialog from "./comp/SelectShippingDialog"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { setAddresses } from "../MyAccount/MyAccountSlice"
import { RiLoader5Fill } from "react-icons/ri"
import { closeChangeBillAddressDialog, openAddAddressDialog, openChangeBillAddressDialog, openSelectPaymentChannelDialog, openSelectShippingDialog, setPaymentChannel } from "./CheckoutSlice"
import { setIsTransactableMode } from "../../component/HeaderSlice"
import { startLoading, stopLoading } from "../../component/Loading/LoadingSlice"
import { setItems } from "../Cart/CartSlice"
import { useHistory } from "react-router"
import { numToRupiahFormat } from '../../util/currency'
import ErrorDialog from "../../component/ErrorDialog"
import AddNewAddressDialog from "./comp/AddNewAddressDialog"

const fetchAddresses = async () => {
  const finalUrl =
    window._HOST + "api/v1/user/addresses"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

const doCheckoutServer = async (payload) => {
  const finalUrl =
    window._HOST + "api/v1/user/checkouts"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export default function CheckoutPage() {
  const dispatch = useDispatch()
  const history = useHistory()

  const { addresses, accountDetail } = useSelector(state => state.myAccount)
  const { cartItems } = useSelector(state => state.cart)
  const { screenSize } = useSelector(state => state.global)

  const [isAllDigital, setIsAllDigital] = useState(false)

  const [checkOutPref, setCheckOutPref] = useState({
    selectedAddressId: null,
    selectedCourierId: null,
    selectedCourierServiceId: null,
    selectedPaymentChannelId: null
  })

  const [selectedAddress, setSelectedAddress] = useState(null)
  const [selectedCourierService, setSelectedCourierService] = useState(null)
  const [selectedPaymentChannel, setSelectedPaymentChannel] = useState(null)
  const [selectedAvailDisc, setSelectedAvailDisc] = useState(null)

  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    errorMessage: ''
  })
  const openErrorDialog = (errMsg, errD) => ({ ...errD, isOpen: true, errorMessage: errMsg })
  const closeErrorDialog = (errD) => ({ ...errD, isOpen: false, errorMessage: '' })

  const [availDisc, setAvailDisc] = useState(null)

  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    if (accountDetail == null) {
      return history.push("/login")
    }
    dispatch(setIsTransactableMode(false))
    window.scrollTo(0, 0)
  }, [])

  useState(() => {
    const nonDigitalItem = cartItems.find(i => i.tipe_buku != 'digital')
    setIsAllDigital(nonDigitalItem == undefined)
  }, [cartItems])

  useEffect(() => {
    setIsLoad(true)

    fetchAddresses()
      .then(response => {
        if (response.success) {
          dispatch(setAddresses(response.data))
        }
        else {
          const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
          setErrorDialog(openErrorDialog(errorMsg, errorDialog))
        }
      })
      .catch(errors => {
        setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
      })
      .finally(() => setIsLoad(false))

  }, [])

  useEffect(() => {
    if (checkOutPref.selectedAddressId) {
      const selectedAddr = addresses.find(d => d.id_user_alamat === checkOutPref.selectedAddressId)
      if (selectedAddr != undefined) {
        setSelectedAddress(selectedAddr)
        setSelectedCourierService(null)
      }
    }
  }, [checkOutPref.selectedAddressId])

  useEffect(() => {
    if (selectedCourierService == null) {
      setSelectedPaymentChannel(null)
    }
  }, [selectedCourierService])

  useEffect(() => {
    if (selectedCourierService) {
      setCheckOutPref({ ...checkOutPref, selectedCourierServiceId: selectedCourierService.service.service })
    }
    else {
      setCheckOutPref({ ...checkOutPref, selectedCourierServiceId: null })
    }

  }, [selectedCourierService])

  useEffect(() => {
    if (selectedPaymentChannel) {
      setCheckOutPref({ ...checkOutPref, selectedPaymentChannelId: selectedPaymentChannel })
    }
    else {
      setCheckOutPref({ ...checkOutPref, selectedPaymentChannelId: null })
    }
  }, [selectedPaymentChannel])

  function onDoCheckout() {
    const payload = {
      payment_method: selectedPaymentChannel.code,
      id_user_alamat: selectedAddress ? selectedAddress.id_user_alamat : null,
      opsi_kurir: !isAllDigital && selectedCourierService != null ? selectedCourierService.courierId : null,
      service: !isAllDigital && selectedCourierService != null ? selectedCourierService.service.service : null,
      id_keranjang_promosi: !isAllDigital && selectedAvailDisc != null ? selectedAvailDisc.id_keranjang_promosi : null,
      biaya_pg: selectedPaymentChannel.total_fee.flat
    }

    doCheckoutServer(payload)
      .then(response => {
        if (response.success) {
          dispatch(setItems([]))
          history.push("/checkout-detail/" + response.data.id_checkout)
        }
        else {
          const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
          setErrorDialog(openErrorDialog(errorMsg, errorDialog))
        }
      })
      .catch(errors => {
        setErrorDialog(openErrorDialog('Fatal Error ' + errors, errorDialog))
      })
      .finally(() => dispatch(stopLoading()))

    dispatch(startLoading("Tunggu ya, sedang memproses..."))
  }

  return (
    <>
      <ErrorDialog
        isShow={errorDialog.isOpen}
        errorMessage={errorDialog.errorMessage}
        onClose={() => setErrorDialog(closeErrorDialog(errorDialog))}
      />
      <SelectPaymentMethodDialog
        onSelect={setSelectedPaymentChannel}
      />
      <SelectShippingDialog
        availDisc={availDisc}
        cityDestId={selectedAddress != null ? selectedAddress.city_id : null}
        onSelected={service => {
          setSelectedCourierService(service)
          setSelectedAvailDisc(null)
        }}
        onSelectShippingDisc={availDisc => {
          setSelectedAvailDisc(availDisc)
          setSelectedCourierService(null)
        }}
      />
      <Header />
      <div
        className="w-full py-2 flex justify-center"
        style={{
          paddingTop: screenSize.width > 500 ? "120px" : "10px",
        }}
      >
        <div className="container bg-white">

          <div
            className={
              "font-bold text-left " +
              (screenSize.width > 500 ? " mb-5 " : " mb-3 px-8 ")
            }
            style={{ fontSize: "22px" }}
          >
            Checkout
          </div>
          <div
            className={
              "flex " +
              (screenSize.width > 500 ? " space-x-10 " : " flex-col ")
            }
          >
            <div
              className="flex flex-1 flex-col space-y-5"
            >
              {!isAllDigital && <CheckOutAddress
                isLoad={isLoad}
                setIsLoad={setIsLoad}
                errorDialog={errorDialog}
                setErrorDialog={setErrorDialog}
                openErrorDialog={openErrorDialog}
                selectedAddressId={checkOutPref.selectedAddressId}
                onSelectedAddressId={id => setCheckOutPref({ ...checkOutPref, selectedAddressId: id })}
              />
              }
              {!isAllDigital && (
                <CheckOutShipping
                  checkOutPref={checkOutPref}
                  selectedCourierService={selectedCourierService}
                  selectedAvailDisc={selectedAvailDisc}
                  setAvailDisc={setAvailDisc}
                />
              )}
              {screenSize.width > 500 && <CheckOutListOrder cartItems={cartItems} />}
            </div>
            <div
              className="text-left space-y-5"
              style={{ width: screenSize.width > 500 ? "400px" : screenSize.width + "px" }}
            >
              <CheckOutPayment
                isAllDigital={isAllDigital}
                checkOutPref={checkOutPref}
                selectedAvailDisc={selectedAvailDisc}
                selectedPaymentChannel={selectedPaymentChannel}
              />
              <CheckOutSummary
                isAllDigital={isAllDigital}
                cartItems={cartItems}
                checkOutPref={checkOutPref}
                selectedCourierService={selectedCourierService}
                selectedPaymentChannel={selectedPaymentChannel}
                selectedAvailDisc={selectedAvailDisc}
                onCheckOut={onDoCheckout}
              />
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  )
}

function CheckOutAddress({
  isLoad,
  setIsLoad,
  errorDialog,
  setErrorDialog,
  openErrorDialog,
  selectedAddressId,
  onSelectedAddressId
}) {
  const dispatch = useDispatch()

  const { addresses } = useSelector(state => state.myAccount)

  const [selectedAddress, setSelectedAddress] = useState(null)

  useEffect(() => {
    if (addresses.length > 0) {
      if (selectedAddressId == null) {
        onSelectedAddressId(addresses[0].id_user_alamat)
      }
      else {
        const selectedAddr = addresses.find(d => d.id_user_alamat === selectedAddressId)
        if (selectedAddr != undefined) {
          setSelectedAddress(selectedAddr)
        }
      }
    }
  }, [addresses, selectedAddressId])

  function onSuccessAddAddress() {
    setIsLoad(true)

    fetchAddresses()
      .then(response => {
        if (response.success) {
          dispatch(setAddresses(response.data))
        }
        else {
          const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
          setErrorDialog(openErrorDialog(errorMsg, errorDialog))
        }
      })
      .catch(errors => {
        setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
      })
      .finally(() => setIsLoad(false))
  }

  return (
    <>
      <AddNewAddressDialog
        onSuccessAdded={onSuccessAddAddress} 
      />
      <ChangeBillAddressDialog
        onSelected={id => {
          onSelectedAddressId(id)
          dispatch(closeChangeBillAddressDialog())
        }}
        onAddNewAddress={() => dispatch(openAddAddressDialog())}
      />
      <div className="p-6 border rounded-md shadow-lg space-y-5">
        {
          isLoad ?
            (
              <div className="h-full w-full flex items-center justify-center text-blue-500">
                <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
              </div>
            )
            :
            (
              <>
                <div className="flex justify-between">
                  <label className="flex items-center font-bold text-gray-600">
                    <IoLocationSharp className="w-5 h-5 mr-2" />
                  Alamat Tujuan Pengiriman
                  </label>
                  <div className="flex space-x-3">
                    { addresses.length > 0 && (
                      <button
                        className="font-bold text-blue-600 hover:text-blue-700"
                        onClick={() => 
                          dispatch(openChangeBillAddressDialog())
                        }
                      >
                        Ubah Alamat
                      </button>
                    )}
                    <button
                      className="font-bold text-blue-600 hover:text-blue-700"
                      onClick={() =>
                        dispatch(openAddAddressDialog())
                      }
                    >
                      Tambah Alamat
                    </button>
                  </div>
                </div>
                {
                  selectedAddress != null ?
                    (
                      <div className="text-gray-600 text-left space-y-1">
                        <div className="font-bold text-left text-black">{selectedAddress.label_alamat}</div>
                        <div>{selectedAddress.nama_penerima}  |  {selectedAddress.no_hp_penerima}</div>
                        <div>{selectedAddress.alamat}, Kec. {selectedAddress.subdistrict_name}, Kab. {selectedAddress.city_name}, {selectedAddress.province}, {selectedAddress.postal_code}</div>
                      </div>
                    )
                    :
                    (
                      <div>
                        Kamu belum mengatur alamat pengiriman
                      </div>
                    )
                }
              </>
            )
        }
      </div>
    </>
  )
}

const fetchShippingDiscAvl = async () => {
  const finalUrl =
    window._HOST + "api/v1/user/cartpromotions/shipping_discounts_availability"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

function CheckOutShipping({
  checkOutPref,
  selectedCourierService,
  setAvailDisc,
  selectedAvailDisc = { selectedAvailDisc }
}) {
  const dispatch = useDispatch()
  const [isLoad, setIsLoad] = useState(false)

  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    errorMessage: ''
  })
  const openErrorDialog = (errMsg, errD) => ({ ...errD, isOpen: true, errorMessage: errMsg })
  const closeErrorDialog = (errD) => ({ ...errD, isOpen: false, errorMessage: '' })

  useEffect(() => {
    fetchShippingDiscAvl()
      .then(response => {
        if (response.success) {
          setAvailDisc(response.data)
        }
        else {
          const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
          setErrorDialog(openErrorDialog(errorMsg, errorDialog))
        }
      })
      .catch(error => {
        setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
      })
      .finally(() => setIsLoad(false))

    setIsLoad(true)
  }, [])

  return (
    <div className="p-6 border rounded-md shadow-lg space-y-5">
      <ErrorDialog
        isShow={errorDialog.isOpen}
        errorMessage={errorDialog.errorMessage}
        onClose={() => setErrorDialog(closeErrorDialog(errorDialog))}
      />
      {
        isLoad ?
          (<>
            <div className="h-full w-full flex items-center justify-center text-blue-500">
              <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
            </div>
          </>)
          :
          (
            <>
              <div className="flex items-center justify-between">
                <label className="flex items-center font-bold text-gray-600">
                  <MdLocalShipping className="w-5 h-5 mr-2" />
                  Kurir
                </label>
                <button
                  className={
                    "px-5 py-2 flex items-center rounded-full border  font-bold  " +
                    (checkOutPref.selectedAddressId == null ? " border-gray-700 text-gray-700 bg-gray-200 cursor-not-allowed " : " border-blue-700 text-blue-700 ")
                  }
                  disabled={checkOutPref.selectedAddressId == null}
                  onClick={() => dispatch(openSelectShippingDialog())}
                >
                  Pilih Kurir
                  <BsChevronDown className="h-5 w-5 ml-5" />
                </button>
              </div>
              <div className="text-left">
                {selectedCourierService != null && (
                  <>
                    <div>
                      {selectedCourierService.courierId.toUpperCase()} - {selectedCourierService.service.service}
                    </div>
                    <div>
                      Rp {numToRupiahFormat(selectedCourierService.service.cost[0].value)}
                    </div>
                  </>
                )}
                {selectedAvailDisc != null && (
                  <div className="font-bold">
                    Promo Bebas Ongkos Kirim dipilih
                  </div>
                )}
              </div>
            </>
          )
      }

    </div>
  )
}

const fetchPaymentChannel = async () => {
  const finalUrl =
    window._HOST + "api/v1/user/checkouts/paymentchannel"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

function CheckOutPayment({
  isAllDigital,
  checkOutPref,
  selectedPaymentChannel,
  selectedAvailDisc
}) {
  const dispatch = useDispatch()
  const [isLoad, setIsLoad] = useState(false)

  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    errorMessage: ''
  })
  const openErrorDialog = (errMsg, errD) => ({ ...errD, isOpen: true, errorMessage: errMsg })
  const closeErrorDialog = (errD) => ({ ...errD, isOpen: false, errorMessage: '' })

  useEffect(() => {
    fetchPaymentChannel()
      .then(response => {
        if (response.success) {
          dispatch(setPaymentChannel(response.data))
        }
        else {
          const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
          setErrorDialog(openErrorDialog(errorMsg, errorDialog))
        }
      })
      .catch(errors => {
        setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
      })
      .finally(() => setIsLoad(false))

    setIsLoad(true)
  }, [])


  return (
    <div className="p-6 border rounded-md shadow-lg space-y-5">
      <ErrorDialog
        isShow={errorDialog.isOpen}
        errorMessage={errorDialog.errorMessage}
        onClose={() => setErrorDialog(closeErrorDialog(errorDialog))}
      />
      <div className="flex items-center justify-between">
        <label className="flex items-center font-bold text-gray-600">
          <div className="flex">
            <FaCashRegister className="w-5 h-5 mr-2" />
            Metode Pembayaran
          </div>
          { }
        </label>
        {
          selectedPaymentChannel != null && (
            <button
              className="font-bold text-blue-600 hover:text-blue-700"
              onClick={() => dispatch(openSelectPaymentChannelDialog())}
            >
              Ubah Metode
            </button>
          )
        }
      </div>
      <div>
        {
          isLoad ?
            (
              <div className="h-full w-full flex items-center justify-center text-blue-500">
                <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
              </div>
            )
            :
            selectedPaymentChannel == null ?
              (<button
                disabled={(!isAllDigital && checkOutPref.selectedCourierServiceId == null && selectedAvailDisc == null)
                }
                className={
                  "w-full py-2 px-5 font-bold rounded-full border flex items-center justify-between " +
                  ((!isAllDigital && checkOutPref.selectedCourierServiceId == null && selectedAvailDisc == null) ? " border-gray-700 text-gray-700 bg-gray-200 cursor-not-allowed " : " border-blue-700 text-blue-700 ")
                }
                onClick={() => dispatch(openSelectPaymentChannelDialog())}
              >
                Pilih Metode Pembayaran
                <BsChevronDown className="h-5 w-5" />
              </button>)
              :
              (<div className="flex items-center space-x-5">
                <img src={selectedPaymentChannel.icon_url} width="100px" />
                <label className="font-bold" style={{ fontSize: "14px" }}> {selectedPaymentChannel.name} </label>
              </div>)
        }
      </div>
    </div>
  )
}

function CheckOutSummary({
  isAllDigital,
  checkOutPref,
  selectedCourierService,
  selectedPaymentChannel,
  selectedAvailDisc,
  cartItems,
  onCheckOut
}) {
  const [total, setTotal] = useState(0)

  useEffect(() => {
    if (cartItems.length > 0) {
      const subTotalOrder = cartItems.reduce((acc, curr) => acc + curr.subtotal, 0)

      setTotal(
        subTotalOrder +
        (selectedCourierService == null ? 0 : selectedCourierService.service.cost[0].value) +
        (selectedPaymentChannel == null ? 0 : selectedPaymentChannel.total_fee.flat)
      )
    }
  }, [selectedCourierService, cartItems, selectedPaymentChannel])

  return (
    <div className="border rounded-md shadow-lg space-y-5">
      <div className="px-6 text-left font-bold py-6 border-b border-gray-300 " style={{ fontSize: "20px" }}>
        Rincian Belanja
      </div>
      <div className="px-6 pb-5 text-gray-600">
        <div className="font-bold">
          Total Biaya Belanja
        </div>
        <div className="space-y-1 py-4 border-b border-gray-300">
          <div className="flex justify-between" style={{ fontSize: "14px" }}>
            <label>Total Belanja</label>
            <label className="text-right font-bold text-black">
              Rp {numToRupiahFormat(cartItems.reduce((acc, curr) => acc + curr.subtotal, 0))}
            </label>
          </div>
          <div className="flex justify-between" style={{ fontSize: "14px" }}>
            <label>Biaya Pengiriman</label>
            <label className="text-right font-bold text-black">
              Rp {
                selectedAvailDisc == null && selectedCourierService == null ?
                  '-'
                  :
                  selectedAvailDisc != null ?
                    0
                    :
                    numToRupiahFormat(selectedCourierService.service.cost[0].value)
              }
            </label>
          </div>
          <div className="flex justify-between" style={{ fontSize: "14px" }}>
            <label>Biaya Admin</label>
            <label className="text-right font-bold text-black">
              Rp {selectedPaymentChannel != null ? numToRupiahFormat(selectedPaymentChannel.total_fee.flat) : '-'}
            </label>
          </div>
        </div>
        <div className="flex justify-between py-3 ">
          <label className="font-bold text-black">Total</label>
          <label className="text-right font-bold text-blue-700">
            Rp {numToRupiahFormat(total)}
          </label>
        </div>
        <div className="py-2">
          <button
            disabled={selectedPaymentChannel == null || (!isAllDigital && checkOutPref.selectedCourierServiceId == null && selectedAvailDisc == null)}
            className={
              "py-2 w-full rounded-full " +
              (selectedPaymentChannel == null || (!isAllDigital && checkOutPref.selectedCourierServiceId == null && selectedAvailDisc == null) ? " border border-gray-700 text-gray-700 bg-gray-200 cursor-not-allowed " : " bg-blue-700 text-white ")
            }
            onClick={onCheckOut}
          >
            Bayar Pesanan
          </button>
        </div>
      </div>
    </div>
  )
}

function CheckOutListOrder({
  cartItems
}) {

  return (
    <div className="p-6 border rounded-md shadow-lg space-y-5">
      <div>
        <label className="flex items-center font-bold text-gray-600">
          <AiOutlineShoppingCart className="w-5 h-5 mr-2" />
          Daftar Pesanan
        </label>
      </div>
      <div>
        {cartItems.map(i => (
          <CheckOutListOrderItem cartItem={i} />
        ))}
      </div>
    </div>
  )
}

function CheckOutListOrderItem({
  cartItem
}) {

  function typeHasDisc(item, bookType) {
    if (bookType == 'full_color') {
      return item.potongan_harga_buku_full_color != "0%"
    }
    else if (bookType == 'dua_warna') {
      return item.potongan_harga_buku_dua_warna != "0%"
    }
    else if (bookType == 'satu_warna') {
      return item.potongan_harga_buku_satu_warna != "0%"
    }
    else if (bookType == 'digital') {
      return item.potongan_harga_buku_digital != "0%"
    }

    return false
  }

  //https://penerbitalc.com/fs-assets/uploads/item/2107070035/medium/img-21070700350.jpg?1636611543
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <img
          src={cartItem.thumb_img_1}
          width="120px"
        />
        <div className="flex flex-col text-left ml-5 space-y-2" style={{ width: "230px" }}>
          <label className="font-bold" style={{ fontSize: "17px" }}>{cartItem.buku}</label>
          <label className="text-gray-600" style={{ fontSize: "14px" }}>
            {cartItem.tipe_buku == 'full_color' ? 'Full Color' : cartItem.tipe_buku == 'dua_warna' ? 'Dua Warna' : cartItem.tipe_buku == 'satu_warna' ? 'Hitam Putih' : 'Digital'} - {cartItem.jumlah_barang} barang
          </label>
          <div className="flex items-center space-x-2">
            <label
              className={
                "text-gray-600 " + (typeHasDisc(cartItem, cartItem.tipe_buku) ? " line-through " : "")
              }
            >
              Rp {
                cartItem.tipe_buku == 'full_color' ?
                  numToRupiahFormat(cartItem.harga_buku_full_color)
                  :
                  cartItem.tipe_buku == 'dua_warna' ?
                    numToRupiahFormat(cartItem.harga_buku_dua_warna)
                    :
                    cartItem.tipe_buku == 'satu_warna' ?
                      numToRupiahFormat(cartItem.harga_buku_satu_warna)
                      :
                      numToRupiahFormat(cartItem.harga_buku_digital)
              }
            </label>
            {typeHasDisc(cartItem, cartItem.tipe_buku) && (
              <label className="text-gray-600">
                Rp {
                  cartItem.tipe_buku == 'full_color' ?
                    numToRupiahFormat(cartItem.harga_buku_full_color_with_promotion)
                    :
                    cartItem.tipe_buku == 'dua_warna' ?
                      numToRupiahFormat(cartItem.harga_buku_dua_warna_with_promotion)
                      :
                      cartItem.tipe_buku == 'satu_warna' ?
                        numToRupiahFormat(cartItem.harga_buku_satu_warna_with_promotion)
                        :
                        numToRupiahFormat(cartItem.harga_buku_digital_with_promotion)
                }
              </label>
            )}
          </div>
        </div>
      </div>
      <div className="pl-5 flex flex-col items-end space-y-3 font-bold text-blue-700">
        <label>Rp {numToRupiahFormat(cartItem.subtotal)}</label>
      </div>
    </div>
  )
}