import axios from "axios"
import { useEffect, useState } from "react"
import { RiLoader5Fill } from "react-icons/ri"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import ErrorDialog from "../../../component/ErrorDialog"
import { numToRupiahFormat } from '../../../util/currency'

const fetchDataFeatured = async () => {
  const finalUrl =
    window._HOST + "api/v1/catalog/catalogsamplelatestsma"

  const response =
    await axios.get(finalUrl)

  return response.data
}

export default function SmaFeatured() {
  const history = useHistory()

  const { screenSize } = useSelector(state => state.global)

  const [items, setItems] = useState([])
  const [isLoad, setIsLoad] = useState(false)

  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    errorMessage: ''
  })

  const openErrorDialog = (errMsg, errD) => ({ ...errD, isOpen: true, errorMessage: errMsg })
  const closeErrorDialog = (errD) => ({ ...errD, isOpen: false, errorMessage: '' })

  function doGetDataFeatured() {
    setIsLoad(true)

    fetchDataFeatured()
      .then(response => {
        if (response.success) {
          const manipulatedData = response.data.slice(0, 4).map(d => ({
            id: d.id_buku,
            title: d.buku,
            desc: d.deskripsi,
            img: d.img_thumb,
            hargaPrintFullColor: d.harga_buku_full_color,
            hargaPrintDuaWarna: d.harga_buku_dua_warna,
            hargaDigital: d.harga_buku_digital,
            hargaPrintFullColorWithPromotion: d.harga_buku_full_color_with_promotion,
            hargaPrintDuaWarnaWithPromotion: d.harga_buku_dua_warna_with_promotion,
            hargaDigitalWithPromotion: d.harga_buku_digital_with_promotion,
            potonganHargaBukuFullColor: d.potongan_harga_buku_full_color,
            potonganHargaBukuDuaWarna: d.potongan_harga_buku_dua_warna,
            potonganHargaBukuDigital: d.potongan_harga_buku_digital
          }))

          setItems(manipulatedData)
        }
        else {
          const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
          setErrorDialog(openErrorDialog(errorMsg, errorDialog))
        }
      })
      .catch(errors => {
        setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
      })
      .finally(() => setIsLoad(false))

  }

  useEffect(() => {
    doGetDataFeatured()
  }, [])

  return (
    <div className="py-5">
      <ErrorDialog
        isShow={errorDialog.isOpen}
        errorMessage={errorDialog.errorMessage}
        onClose={() => setErrorDialog(closeErrorDialog(errorDialog))}
      />
      {
        isLoad ?
          (
            <div className="h-full w-full flex items-center justify-center text-blue-500">
              <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
            </div>
          )
          :
          (
            <>
              { screenSize.width < 500 && (
                <div className="flex items-center justify-between px-10 mb-5">
                  <label className="font-bold" style={{ fontFamily: "Nunito", fontSize: "25px" }}>Buku SMA</label>
                  <label
                    className="cursor-pointer font-bold text-blue-600"
                    style={{ fontFamily: "Nunito", fontSize: "16px" }}
                    onClick={() => history.push("/products/jenjang=SMA")}
                  >
                    Lihat Semua
                  </label>
                </div>
              )}
              <div
                className="w-full flex justify-center"
              >
                <div
                  className="container flex space-x-5"
                >
                  {screenSize.width > 500 && (
                    <div>
                      <div
                        className="cursor-pointer rounded-md border border-gray-400 relative"
                        style={{
                          width: "204px",
                        }}
                      >
                        <img src={(window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/illustrasi-02.jpg"} />
                        <div
                          className="w-full py-1 absolute bottom-0 left-0 bg-blue-600 text-white font-bold rounded-bl-md rounded-br-md"
                          style={{
                            fontFamily: "Nunito",
                            fontSize: "20px"
                          }}
                        >
                          Lihat Semua
                      </div>
                      </div>
                    </div>
                  )}
                  <div className="flex-1">
                    {screenSize.width > 500 && (
                      <div className="flex items-center justify-between mb-5">
                        <label className="font-bold" style={{ fontFamily: "Nunito", fontSize: "20px" }}>Buku SMA</label>
                        <label
                          className="cursor-pointer font-bold text-blue-600"
                          style={{ fontFamily: "Nunito", fontSize: "16px" }}
                          onClick={() => history.push("/products/jenjang=SMA")}
                        >
                          Lihat Semua
              </label>
                      </div>
                    )}

                    <div
                      className={
                        "grid " +
                        (screenSize.width > 500 ? " grid-cols-4 gap-y-3 " : " grid-cols-2 gap-y-1 ")
                      }
                    >
                      {items.map(i => (
                        <ItemBox item={i} />
                      ))}
                    </div>
                  </div>

                </div>
              </div>
            </>
          )
      }

    </div>
  )
}

function ItemBox({ item }) {
  const history = useHistory()
  const { screenSize } = useSelector(state => state.global)

  return (
    <button
      id={item.id}
      className="py-2 relative cursor-pointer group border border-transparent flex flex-col justify-start hover:border-green-300 hover:shadow-md"
      onClick={() => history.push("/product-detail/" + item.id)}
    >
      {
        item.potonganHargaBukuFullColor != "0%" && (
          <div
            className="absolute right-5 rounded-full bg-red-500 text-white shadow-lg"
            style={{
              width: "50px",
              height: "50px"
            }}
          >
            <div className="h-full flex items-center justify-center">
              -{item.potonganHargaBukuFullColor}
            </div>
          </div>
        )
      }
      <div className="w-full flex justify-center">
        <img
          src={item.img}
          width={
            screenSize.width > 500 ? " 180px " : "160px"
          }
        />
      </div>
      <div
        className={
          "py-2 flex flex-col text-left " +
          (screenSize.width > 500 ? " px-5 " : " px-4 ")
        }
      >
        <label
          className="cursor-pointer group-hover:text-green-600"
          style={{ fontSize: "15px" }}
        >
          {item.title}
        </label>
        {/* <label
          className={
            "font-bold cursor-pointer " +
            (item.potonganHargaBukuFullColor != "0%" ? " line-through " : "")
          }
          style={{
            fontSize: item.potonganHargaBukuFullColor != "0%" ? "13px" : "16px"
          }}
        >
          {"Rp " + numToRupiahFormat(item.hargaPrintFullColor)}
        </label> */}
        <div
          className="flex flex-col"
        >
          <label style={{ fontSize: "13px", marginTop: "5px", fontWeight: "700" }}>Harga mulai dari</label>
          <label className="font-bold text-red-600">
            {"Rp " + numToRupiahFormat(item.hargaDigitalWithPromotion)}
          </label>
        </div>
        {/* {
          item.potonganHargaBukuFullColor != "0%" && (
            <label className="font-bold text-red-600">
              {"Rp " + numToRupiahFormat(item.hargaPrintFullColorWithPromotion)}
            </label>
          )
        } */}
      </div>
    </button>
  )
}