import axios from "axios"
import { useEffect, useState } from "react"
import { RiLoader5Fill } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { setOrders } from "../MyAccountSlice"
import { format } from "date-fns"
import id from 'date-fns/locale/id'
import { numToRupiahFormat } from '../../../util/currency'
import ErrorDialog from "../../../component/ErrorDialog"

const fetchOrders = async () => {
  const finalUrl =
    window._HOST + "api/v1/user/checkouts/history"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export default function MyOrders() {
  const dispatch = useDispatch()

  const { orders } = useSelector(state => state.myAccount)
  const { screenSize } = useSelector(state => state.global)

  const [isLoad, setIsLoad] = useState(false)

  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    errorMessage: ''
  })
  const openErrorDialog = (errMsg, errD) => ({ ...errD, isOpen: true, errorMessage: errMsg })
  const closeErrorDialog = (errD) => ({ ...errD, isOpen: false, errorMessage: '' })

  useEffect(() => {
    setIsLoad(true)

    fetchOrders()
      .then(response => {
        if (response.success) {
          dispatch(setOrders(response.data))
        }
        else {
          const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
          setErrorDialog(openErrorDialog(errorMsg, errorDialog))
        }
      })
      .catch(error => {
        setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
      })
      .finally(() => setIsLoad(false))

  }, [])

  const EmptyOrders = () => {
    return (
      <div
        className="w-full py-10"
      >
        <div
          className="w-full flex items-center justify-center flex flex-col space-y-5"
          style={{
            height: "300px"
          }}
        >
          <div>
            <img className="rounded-xl shadow-md" src={(window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/empty_book_cart.png"} width={250} />
          </div>
          <label
            className="font-bold text-black"
            style={{ fontFamily: "Nunito" }}
          >
            Catatan transaksi kamu masih kosong
          </label>
        </div>
      </div>
    )
  }

  return (
    <div
      className={
        "w-full space-y-5 " +
        (screenSize.width > 500 ? "" : "px-2")
      }
    >
      <ErrorDialog
        isShow={errorDialog.isOpen}
        errorMessage={errorDialog.errorMessage}
        onClose={() => setErrorDialog(closeErrorDialog(errorDialog))}
      />
      {
        isLoad ?
          (
            <div className="h-full w-full flex items-center justify-center text-blue-500">
              <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
            </div>
          )
          :
          orders.length > 0 ?
            orders.map(order => (
              <OrderItem order={order} />
            ))
            :
            <EmptyOrders />
      }
    </div>
  )
}

function OrderItem({
  order
}) {
  const history = useHistory()

  const { screenSize } = useSelector(state => state.global)

  return (
    <div
      className={
        "border rounded-md " +
        (screenSize.width > 500 ? " shadow-md " : " border-gray-300 ")
      }
    >
      <div
        className={
          "py-5 px-5 text-left " +
          (screenSize.width > 500 ? " space-x-5 " : " flex flex-col space-y-1 ")
        }
      >
        <label className="text-gray-600" style={{ fontSize: "14px" }}>{format(new Date(order.tgl_input), "dd MMMM yyyy, HH:mm:ss", { locale: id })}</label>
        <label className="text-gray-600" style={{ fontSize: "14px" }}>No. Pemesanan {order.id_checkout}</label>
        {
          screenSize.width > 500 ?
            (
              <label className="py-1 px-3 bg-blue-100 text-blue-900 font-bold" style={{ fontSize: "14px" }}>
                {order.payment_status}
              </label>
            )
            :
            (
              <div>
                <label className="py-1 px-3 bg-blue-100 text-blue-900 font-bold" style={{ fontSize: "14px" }}>
                  {order.payment_status}
                </label>
              </div>
            )
        }

      </div>
      <div className="py-5 border-t border-b">
        <div className="px-3 flex items-center justify-between">
          <div className="flex items-center">
            <img
              src={order.daftar_buku[0].daftar_gambar_buku[0]}
              width="100px"
            />
            <div className="flex flex-col text-left ml-5 space-y-2" style={{ width: "230px" }}>
              <label className="cursor-pointer font-bold" style={{ fontSize: "17px" }}>{order.daftar_buku[0].buku}</label>
              <label className="text-gray-600" style={{ fontSize: "14px" }}>
                {order.daftar_buku[0].tipe_buku == 'full_color' ? 'Full Color' : order.daftar_buku[0].tipe_buku == 'dua_warna' ? 'Dua Warna' : order.daftar_buku[0].tipe_buku == 'satu_warna' ? 'Satu Warna' : 'Digital'} - {order.daftar_buku[0].jumlah_buku} barang</label>
              <label className="font-bold" style={{ fontSize: "14px" }}>Rp {numToRupiahFormat(order.daftar_buku[0].harga_buku)}</label>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 px-5 flex justify-between space-x-5">
        <label
          className="cursor-pointer text-blue-600 font-bold" style={{ fontSize: "14px" }}
          onClick={() => history.push("/order-detail/" + order.id_checkout)}
        >
          Lihat Detail Pesanan
        </label>
        <label
          className="text-gray-600 space-x-2"
          style={{ fontSize: "14px" }}
        >
          <span>Total Pesanan</span>
          <span className="font-bold text-black">Rp {numToRupiahFormat(order.amount)}</span>
        </label>
      </div>

    </div>
  )
}