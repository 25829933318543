import axios from "axios"
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { closeSideMenuMobile } from "../../app/GlobalSlice"
import { resetCartItems } from "../../pages/Cart/CartSlice"
import { openChangePasswordDialog, resetAccountDetail } from "../../pages/MyAccount/MyAccountSlice"
import { startLoading, stopLoading } from "../Loading/LoadingSlice"

const logOutServer = async () => {
  const response =
    await axios.post(window._HOST + "api/v1/auths/logout", {}, { withCredentials: true })

  return response.data
}

export default function SideMenu() {
  const dispatch = useDispatch()
  const history = useHistory()

  const { sideMenuMobile, screenSize } = useSelector(state => state.global)
  const { accountDetail } = useSelector(state => state.myAccount)

  function onLogOut() {
    dispatch(startLoading("Tunggu ya, sedang memproses..."))

    logOutServer()
      .then(response => {
        if (response.success) {
          dispatch(resetAccountDetail())
          dispatch(resetCartItems())
          history.push("/")
        }
        else {

        }
      })
      .catch(errors => {

      })
      .finally(() => dispatch(stopLoading()))
  }

  return (
    <>
      { sideMenuMobile.isOpen && (
        <div
          className="fixed top-0 w-full h-full z-10 bg-black bg-opacity-60"
          onClick={() => dispatch(closeSideMenuMobile())}
        >
        </div>
      )}
      <div
        className={
          "fixed inset-y-0 right-0 overflow-y-auto transform transition duration-500 ease-in-out shadow-lg bg-white border-l border-gray-300 " +
          (sideMenuMobile.isOpen ? "" : " translate-x-full")
        }
        style={{
          zIndex: "999",
          width: "280px",
          height: "100%"
        }}
      >
        <div style={{ height: screenSize.width < 768 ? "70px" : "52px" }} className="border-b border-gray-200 flex items-center px-2">
          <button
            className="p-2 rounded-md bg-red-500 text-white shadow-lg"
            onClick={() => dispatch(closeSideMenuMobile())}
          >
            <AiOutlineClose className="h-6 w-6" />
          </button>
        </div>
        <div
          className="overflow-y-auto"
          style={{
            height: (screenSize.height - (70)) + "px"
          }}
        >
          <div className="p-2">
            <div className="p-1 flex items-center bg-white border border-gray-400 rounded-sm">
              <input
                className="px-2 h-full focus:outline-none text-gray-600"
                placeholder="Cari Buku di sini"
                style={{
                  width: "400px",
                }}
              // value={getDataParams.keywordSearch}
              // onChange={e => dispatch(setKeywordSearch(e.target.value))}
              />
              <button
                className="py-1.5 px-5 h-full rounded-sm bg-green-500 text-white hover:bg-green-700 hover:shadow-lg"
              // onClick={onSearch}
              >
                <AiOutlineSearch className="h-5 w-5" />
              </button>
            </div>
          </div>
          <div className="p-1 px-3">
            <div
              className="text-left font-bold" style={{ fontSize: "18px" }}
            >
              Menu Utama
            </div>
            <div className="pl-5 py-2 flex flex-col space-y-1">
              <button
                className="p-2 text-left hover:text-green-600"
                onClick={() => { history.push("/"); dispatch(closeSideMenuMobile()) }}
              >
                Home
              </button>
              <button
                className="p-2 text-left hover:text-green-600"
                onClick={() => { history.push("/about-us"); dispatch(closeSideMenuMobile()) }}
              >
                Tentang Kami
              </button>
              <button
                className="p-2 text-left hover:text-green-600"
                onClick={() => { history.push("/contact-us"); dispatch(closeSideMenuMobile()) }}
              >
                Kontak
              </button>
            </div>
          </div>
          <div className="p-1 px-3">
            <div className="text-left font-bold" style={{ fontSize: "18px" }}>
              Hal. Pelanggan
            </div>
            <div className="pl-5 py-2 flex flex-col space-y-1">
              {
                accountDetail != null ?
                  (
                    <>
                      <button
                        className="p-2 text-left hover:text-green-600"
                        onClick={() => {
                          dispatch(closeSideMenuMobile())
                          history.push("/my-account/orders")
                        }}
                      >
                        Pesanan Saya
                      </button>
                      <button
                        className="p-2 text-left hover:text-green-600"
                        onClick={() => {
                          dispatch(closeSideMenuMobile())
                          history.push("/my-account")
                        }}
                      >
                        Akun Saya
                      </button>
                      <button
                        className="p-2 text-left hover:text-green-600"
                        onClick={() => {
                          dispatch(openChangePasswordDialog())
                          dispatch(closeSideMenuMobile())
                        }}
                      >
                        Ganti kata sandi
                      </button>
                      <button
                        className="p-2 text-left hover:text-green-600"
                        onClick={() => {
                          onLogOut()
                          dispatch(closeSideMenuMobile())
                        }}
                      >
                        Keluar
                      </button>
                    </>
                  )
                  :
                  (
                    <>
                      <button
                        className="p-2 text-left hover:text-green-600"
                        onClick={() => {
                          dispatch(closeSideMenuMobile())
                          history.push("/login")
                        }}
                      >
                        Masuk
                      </button>
                      <button
                        className="p-2 text-left hover:text-green-600"
                        onClick={() => {
                          dispatch(closeSideMenuMobile())
                          history.push("/register")
                        }}
                      >
                        Daftar
                      </button>
                    </>
                  )
              }
            </div>
          </div>

        </div>
      </div>
    </>
  )
}