import axios from "axios"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"

export default function Footer() {
  const { screenSize } = useSelector(state => state.global)

  return (
    <div
      className="w-full py-2 flex justify-center bg-white"
      style={{ paddingTop: "120px" }}
    >
      <div
        className="container"
      >
        <div className="flex flex-col space-y-2 divide-y divide-gray-200">
          {screenSize.width > 500 && <SponsoredBy />}
          <div className="py-12 text-left">
            <FooterMenu />
          </div>
          <div className="py-5">
            Copyright © {new Date().getFullYear()} Penerbit ALC. All Right Reserved.
          </div>
        </div>
      </div>
    </div>
  )
}

function SponsoredBy() {
  return (
    <div className="py-2 flex justify-between">
      <img
        className="opacity-50 hover:opacity-100"
        src={(window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/logo.png"}
        width="150px"
      />
      <img
        className="opacity-50 hover:opacity-100"
        src={(window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/kunci-soal-2.png"}
        width="200px"
      />
      <img
        className="opacity-50 hover:opacity-100"
        src={(window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/kunci-belajar.png"}
        width="200px"
      />
      <img
        className="opacity-50 hover:opacity-100"
        src={(window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/hexant.jpg"}
        width="200px"
      />
      <img
        className="opacity-50 hover:opacity-100"
        src={(window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/imagi.png"}
        width="200px"
      />
    </div>
  )
}

const fetchPages = async () => {
  const finalUrl =
    window._HOST + "api/v1/pages"

  const response =
    await axios.get(finalUrl)

  return response.data
}

function FooterMenu() {
  const { screenSize, systemProfile } = useSelector(state => state.global)
  const history = useHistory()

  const [pages, setPages] = useState([])
  const [isLoad, setIsLoad] = useState(false)

  const [footerMenus, setFooterMenus] = useState({
    address: '',
    email: '',
    telp: ''
  })

  useEffect(() => {
    fetchPages()
      .then(response => {
        if (response.success) {
          setPages(response.data)
        }
      })
      .catch(error => {

      })
      .finally(() => setIsLoad(false))

    setIsLoad(true)
  }, [])

  useEffect(() => {
    const address = systemProfile.find(d => d.key_ == 'address')
    const email = systemProfile.find(d => d.key_ == 'email')
    const telp = systemProfile.find(d => d.key_ == 'telp')

    setFooterMenus({
      ...footerMenus,
      address: address != undefined ? address : '',
      email: email != undefined ? email : '',
      telp: telp != undefined ? telp : ''
    })
  }, [systemProfile])

  return (
    <div
      className={
        screenSize.width > 500 ?
          "flex justify-between space-x-10 "
          :
          "px-5 space-y-8 border-t border-gray-300"
      }
    >
      <div
        className={
          "flex flex-col flex-1 " +
          (screenSize.width > 500 ? " " : " pt-10 ")
        }
      >
        <img
          className="mb-2"
          src={(window._HOST_APP != "" ? "/" + window._HOST_APP : "") + "/assets/images/logo.png"}
          width="150px"
        />
        <div className="uppercase font-bold" style={{ fontSize: "14px" }}>
          Assyifa Learning Center HQ
        </div>
        <div
          className={
            "text-gray-600 " +
            (screenSize.width > 500 ? "pt-1" : "pt-1")
          }
        >
          <div className="flex space-x-3">
            <label className="inline-block w-30">Alamat</label>
            <label>:</label>
            <label className="text-justify text-gray-600">{footerMenus.address.value_}</label>
          </div>
          <div className="flex space-x-3">
            <label style={{ width: "50px" }}>Email</label>
            <label>:</label>
            <label className="text-justify text-gray-600">{footerMenus.email.value_}</label>
          </div>
          <div className="flex space-x-3">
            <label style={{ width: "50px" }}>Telp.</label>
            <label>:</label>
            <label className="text-justify text-gray-600">{footerMenus.telp.value_}</label>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="uppercase font-bold" style={{ fontSize: "17px" }}>
          Halaman
        </div>
        <div
          className={
            "text-gray-600 " +
            (screenSize.width > 500 ? "pt-5" : "pt-1")
          }
        >
          <ul className="space-y-2">
            {pages.map(p => (
              <li className="cursor-pointer hover:text-black" onClick={() => history.push("/" + p.url)}>{p.title}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="uppercase font-bold" style={{ fontSize: "17px" }}>
          layanan pelanggan
        </div>
        <div
          className={
            "text-gray-600 " +
            (screenSize.width > 500 ? "pt-5" : "pt-1")
          }
        >
          <ul className="space-y-2">
            <li className="cursor-pointer hover:text-black" onClick={() => history.push("/tos")}>Syarat dan ketentuan</li>
            <li className="cursor-pointer hover:text-black" onClick={() => history.push("/privacy")}>Kebijakan Privasi</li>
          </ul>
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="uppercase font-bold" style={{ fontSize: "17px" }}>
          tentang
        </div>
        <div
          className={
            "text-gray-600 " +
            (screenSize.width > 500 ? "pt-5" : "pt-1")
          }
        >
          <ul className="space-y-2">
            <li
              className="cursor-pointer hover:text-black"
              onClick={() => history.push("/about-us")}
            >
              Tentang Kami
            </li>
            <li
              className="cursor-pointer hover:text-black"
              onClick={() => history.push("/contact-us")}
            >
              Kontak
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

