import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  paymentInstructions: [],
  instructionDialog: {
    isOpen: false,
    id: null
  }
}

export const CheckoutResultSlice = createSlice({
  name: 'CheckoutResult',
  initialState,
  reducers: {
    openInstructionDialog: (state, { payload }) => {
      state.instructionDialog.isOpen = true
      state.instructionDialog.id = payload
    },
    closeInstructionDialog: state => {
      state.instructionDialog.isOpen = false
      state.instructionDialog.id = null
    },
    setPaymentInstructions: (state, { payload }) => {
      state.paymentInstructions = payload
    }
  }
})

export const {
  openInstructionDialog,
  closeInstructionDialog,
  setPaymentInstructions
} = CheckoutResultSlice.actions

export default CheckoutResultSlice.reducer