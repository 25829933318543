import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isTransactableMode: true,
  currentSearchKeyword: ''
}

export const HeaderSlice = createSlice({
  name: 'Header',
  initialState,
  reducers: {
    setIsTransactableMode: (state, { payload }) => {
      state.isTransactableMode = payload
    },
    setCurrentSearchKeyword: (state, { payload }) => {
      state.currentSearchKeyword = payload
    }
  }
})

export const {
  setIsTransactableMode,
  setCurrentSearchKeyword
} = HeaderSlice.actions

export default HeaderSlice.reducer

