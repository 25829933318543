import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Footer from "../../component/Footer/Footer";
import Header from "../../component/Header";

export default function ContactUsPage() {
  const { screenSize, systemProfile } = useSelector(state => state.global)

  const [model, setModel] = useState({
    address: '',
    email: '',
    hp: ''
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const address = systemProfile.find(d => d.key_ == 'address')
    const email = systemProfile.find(d => d.key_ == 'email')
    const telp = systemProfile.find(d => d.key_ == 'telp')

    setModel({
      ...model,
      address: address != undefined ? address : '',
      email: email != undefined ? email : '',
      hp: telp != undefined ? telp : ''
    })
  }, [systemProfile])

  return (
    <>
      <Header />
      <div
        className="w-full py-2 flex justify-center"
        style={{
          paddingTop: screenSize.width > 500 ? "120px" : "10px",
        }}
      >
        <div
          className={
            "container px-5"
          }
        >
          <div className="py-2 text-left" style={{ fontSize: "30px", fontFamily: "Nunito" }}>
            <label>Kontak Kami</label>
          </div>
          <div>
            <div>
              <iframe
                src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1666.2012190481553!2d107.71935236090252!3d-6.657247435365722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x49be53415e299e26!2sAs%20Syifa%20Boarding%20School%20Subang!5e0!3m2!1sid!2sid!4v1609961480122!5m2!1sid!2sid"}
                width="100%"
                height="450"
                style={{ border: "0" }}

              />
            </div>
            <div className="pt-5 pb-2 text-left" style={{ fontSize: "30px", fontFamily: "Nunito" }}>
              Lokasi dan Detail
            </div>
            <div
              className={screenSize.width > 500 ? "w-1/3" : "w-full"}
            >
              <div
                className={
                  "text-gray-600"
                }
              >
                <div className="flex space-x-3">
                  <label className="text-left inline-block w-16">Alamat</label>
                  <label>:</label>
                  <label className="text-justify text-gray-600 flex-1">{model.address.value_}</label>
                </div>
                <div className="flex space-x-3">
                  <label className="text-left inline-block w-16">Email</label>
                  <label>:</label>
                  <label className="text-justify text-gray-600">{model.email.value_}</label>
                </div>
                <div className="flex space-x-3">
                  <label className="text-left inline-block w-16">Telp.</label>
                  <label>:</label>
                  <label className="text-justify text-gray-600">{model.hp.value_}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}