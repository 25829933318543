import Header from "../../component/Header"
import Footer from "../../component/Footer/Footer"
import { useEffect, useState } from "react"
import { FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { setDataStatus, setOffset, setSelectedKelas, setSelectedPriceDate } from "./productSlice"
import axios from "axios"
import { useHistory, useParams } from "react-router"
import { numToRupiahFormat } from '../../util/currency'
import { RiLoader5Fill } from "react-icons/ri"
import ErrorDialog from "../../component/ErrorDialog"

const fetchClasses = async () => {
  const finalUrl =
    window._HOST + "api/v1/user/classes/dropdown"

  const response =
    await axios.get(finalUrl)

  return response.data
}

const fetchProduct = async (getDataParams) => {
  const finalUrl =
    window._HOST +
    "api/v1/catalog/?" +
    (getDataParams.selectedKelas != null ? "id_kelas=" + getDataParams.selectedKelas.id : "") +
    "&price_data_param=" + getDataParams.selectedPriceDate.id +
    "&keyword=" + getDataParams.keywordSearch +
    "&limit=" + getDataParams.limit +
    "&offset=" + getDataParams.offset

  const response =
    await axios.get(finalUrl)

  return response.data
}

export default function ProductPage() {
  const dispatch = useDispatch()

  let { jenjangId, kelas } = useParams()

  console.log('jenjang ', jenjangId)
  console.log('kelas ', kelas)


  const { screenSize } = useSelector(state => state.global)

  useEffect(() => {
    dispatch(setSelectedKelas(null))

    if (jenjangId == 'SMP') {
      dispatch(setSelectedKelas({ id: 'all_smp' }))
    }
    else if (jenjangId == 'SMA') {
      dispatch(setSelectedKelas({ id: 'all_sma' }))
    }

  }, [jenjangId])

  useEffect(() => {
    window.scrollTo(0, 0)

    return () => {
      dispatch(setSelectedKelas(null))
    }
  }, [])

  return (
    <>
      <Header />
      <div
        className="w-full py-2 flex justify-center"
        style={{
          paddingTop: screenSize.width > 500 ? "120px" : "10px"
        }}
      >
        <div
          className={
            "container flex bg-white " +
            (screenSize.width > 500 ? "  " : " flex-col ")
          }
        >
          <div
            className={
              "flex " +
                screenSize.width > 500 ? "" : " px-2 mb-2 "
            }
            style={{
              width: screenSize.width > 500 ? "280px" : screenSize.width + "px",
            }}
          >
            <SidePane kelas={kelas} />
          </div>
          <div
            className="flex flex-1 px-2"
          >
            <CatalogPane jenjangId={jenjangId} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

function SidePane({
  kelas
}) {
  const dispatch = useDispatch()

  const { screenSize } = useSelector(state => state.global)
  const { getDataParams } = useSelector(state => state.product)

  const [isSmpDropOpen, setIsSmpDropOpen] = useState(false)
  const [isSmaDropOpen, setIsSmaDropOpen] = useState(false)

  const [groupedClasses, setGroupedClass] = useState([])

  const [smpClasses, setSmpClasses] = useState([])
  const [smaClasses, setSmaClasses] = useState([])

  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    errorMessage: ''
  })
  const openErrorDialog = (errMsg, errD) => ({ ...errD, isOpen: true, errorMessage: errMsg })
  const closeErrorDialog = (errD) => ({ ...errD, isOpen: false, errorMessage: '' })

  useEffect(() => {
    fetchClasses()
      .then(response => {
        if (response.success) {
          const grouped = response.data.reduce((r, a) => {
            r[a.group] = [...r[a.group] || [], a];
            return r;
          }, {})

          setGroupedClass(grouped)
        }
        else {
          const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
          setErrorDialog(openErrorDialog(errorMsg, errorDialog))
        }
      })
      .catch(error => {
        setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
      })
      .finally()
  }, [])

  useEffect(() => {
    // if (getDataParams.selectedKelas != null && getDataParams.selectedKelas.id == 'all_smp') {
    //   setIsSmpDropOpen(true)
    // }
    // else if (getDataParams.selectedKelas != null && getDataParams.selectedKelas.id == 'all_sma') {
    //   setIsSmaDropOpen(true)
    // }

  }, [getDataParams.selectedKelas])

  useEffect(() => {
    if (groupedClasses.SMA != undefined) {
      setSmaClasses(groupedClasses.SMA)
    }

    if (groupedClasses.SMP != undefined) {
      setSmpClasses(groupedClasses.SMP)
    }

  }, [groupedClasses])

  useEffect(() => {
    if (kelas && smpClasses.length > 0 && smaClasses.length > 0) {
      const findKelasByKelasParam = (kelas) => {
        if (kelas >= 7 && kelas <= 9) {
          return smpClasses.find(k => k.text == kelas)
        }
        else if (kelas >= 10 && kelas <= 12) {
          return smaClasses.find(k => k.text == kelas)
        }

        return null
      }

      const kelas_ = findKelasByKelasParam(kelas)

      if (kelas_) {
        dispatch(setSelectedKelas(kelas_))
      }
    }
  }, [kelas, smpClasses, smaClasses])

  return (
    <div
      className="w-full p-2 text-gray-600 space-y-2"
    >
      <ErrorDialog
        isShow={errorDialog.isOpen}
        errorMessage={errorDialog.errorMessage}
        onClose={() => setErrorDialog(closeErrorDialog(errorDialog))}
      />
      <div className="text-left space-y-2">
        <div className="w-full uppercase font-bold text-black">
          Filter Kelas
        </div>
        <div className=" w-full flex flex-col space-y-2">
          <div className="border border-gray-300 rounded-md">
            <button
              className={
                "py-3 px-2 flex items-center justify-between text-left w-full " +
                (isSmpDropOpen ? " border-b " : "")
              }
              onClick={() => setIsSmpDropOpen(!isSmpDropOpen)}
            >
              SMP / Mts
              {
                isSmpDropOpen ? <FaChevronUp /> : <FaChevronDown />
              }
            </button>
            <div className={"pl-7 py-2 flex flex-col " + (isSmpDropOpen ? "" : " hidden ")}>
              <button
                className={
                  "w-full text-left py-2 font-bold hover:text-blue-500 " +
                  (getDataParams.selectedKelas != null && getDataParams.selectedKelas.id == "all_smp" ? " text-blue-500 " : "")
                }
                onClick={() => {
                  dispatch(setSelectedKelas({ id: 'all_smp' }))
                  if (screenSize.width < 500) {
                    setIsSmpDropOpen(false)
                  }
                }}
              >
                Semua Kelas
              </button>
              {smpClasses.map(c => (
                <button
                  className={
                    "w-full text-left py-2 font-bold hover:text-blue-500 " +
                    (getDataParams.selectedKelas != null && getDataParams.selectedKelas.id == c.id ? " text-blue-500 " : "")
                  }
                  onClick={() => {
                    dispatch(setSelectedKelas(c))
                    if (screenSize.width < 500) {
                      setIsSmpDropOpen(false)
                    }
                  }}
                >
                  Kelas {c.text}
                </button>
              ))}
            </div>
          </div>
          <div className="border border-gray-300 rounded-md">
            <button
              className={
                "py-3 px-2 flex items-center justify-between text-left w-full " +
                (isSmaDropOpen ? " border-b " : "")
              }
              onClick={() => {
                setIsSmaDropOpen(!isSmaDropOpen)
              }}
            >
              SMA / MA
              {
                isSmaDropOpen ? <FaChevronUp /> : <FaChevronDown />
              }
            </button>
            <div className={"pl-7 py-2 flex flex-col " + (isSmaDropOpen ? "" : " hidden ")}>
              <button
                className={
                  "w-full text-left py-2 font-bold hover:text-blue-500 " +
                  (getDataParams.selectedKelas != null && getDataParams.selectedKelas.id == "all_sma" ? " text-blue-500 " : "")
                }
                onClick={() => {
                  dispatch(setSelectedKelas({ id: 'all_sma' }))
                  if (screenSize.width < 500) {
                    setIsSmaDropOpen(false)
                  }
                }}
              >
                Semua Kelas
              </button>
              {smaClasses.map(c => (
                <button
                  className={
                    "w-full text-left py-2 font-bold hover:text-blue-500 " +
                    (getDataParams.selectedKelas != null && getDataParams.selectedKelas.id == c.id ? " text-blue-500 " : "")
                  }
                  onClick={() => {
                    dispatch(setSelectedKelas(c))
                    if (screenSize.width < 500) {
                      setIsSmaDropOpen(false)
                    }
                  }}
                >
                  Kelas {c.text}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function CatalogPane({
  jenjangId
}) {
  const dispatch = useDispatch()

  const { screenSize } = useSelector(state => state.global)

  const { priceAndDateParams, getDataParams, dataStatus } = useSelector(state => state.product)

  const [sortOpts, setSortOpts] = useState({
    selected: priceAndDateParams[0],
    data: priceAndDateParams,
    isOpen: false
  })

  const [isLoad, setIsLoad] = useState(false)

  const [items, setItems] = useState([])

  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    errorMessage: ''
  })
  const openErrorDialog = (errMsg, errD) => ({ ...errD, isOpen: true, errorMessage: errMsg })
  const closeErrorDialog = (errD) => ({ ...errD, isOpen: false, errorMessage: '' })

  useEffect(() => {
    dispatch(setSelectedPriceDate(sortOpts.selected))
  }, [sortOpts.selected])

  useEffect(() => {
    if ((jenjangId != undefined && getDataParams.selectedKelas != null)
      || (jenjangId == undefined && (getDataParams.selectedKelas == null || getDataParams.selectedKelas != 'all_sma' || getDataParams.selectedKelas != 'all_smp'))) {
      fetchProduct(getDataParams)
        .then(response => {
          if (response.success) {
            const dataStatus = {
              totalData: parseInt(response.data.total),
              totalFiltered: parseInt(response.data.total_filtered)
            }

            const items = response.data.data.map(d => ({
              id: d.id_buku,
              title: d.buku,
              desc: "-",
              img: d.img_thumb,
              price: d.harga_buku_digital,
              priceWithPromotion: d.harga_buku_digital_with_promotion,
              disc: d.potongan_harga_buku_full_color
            }))

            dispatch(setDataStatus(dataStatus))

            setItems(items)

          }
          else {
            const errorMsg = response.errors.reduce((acc, curr) => acc + '\n' + curr)
            setErrorDialog(openErrorDialog(errorMsg, errorDialog))
          }
        })
        .catch(errors => {
          setErrorDialog(openErrorDialog('Fatal Error', errorDialog))
        })
        .finally(() => setIsLoad(false))

      setIsLoad(true)
    }
  }, [getDataParams])

  return (
    <div
      className="w-full p-2 space-y-2"
    >
      <ErrorDialog
        isShow={errorDialog.isOpen}
        errorMessage={errorDialog.errorMessage}
        onClose={() => setErrorDialog(closeErrorDialog(errorDialog))}
      />
      {
        isLoad ?
          (<>
            <div className="h-full w-full flex items-center justify-center text-blue-500">
              <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
            </div>
          </>)
          :
          (<>
            <div className="flex items-center justify-between text-gray-700">
              {
                screenSize.width > 500 && (
                  <div className="h-full flex items-center">
                    <span className="text-black font-bold">1 - {dataStatus.totalFiltered}</span>&nbsp;dari hasil pencarian produk dengan kata kunci {getDataParams.keywordSearch != '' ? (<span className="font-bold ml-1">{getDataParams.keywordSearch}</span>) : '""'}
                  </div>
                )
              }
              <div
                className="h-full flex flex-col justify-center relative"
              >
                {screenSize.width < 500 && <label className="text-left mb-2 uppercase font-bold">Pengurutan</label>}
                <button
                  className={
                    "w-44 px-3 py-2 border text-left " +
                    (sortOpts.isOpen ? " rounded-tl-md rounded-tr-md bg-gray-200 shadow-lg text-blue-600 border-transparent " : " rounded-md border-gray-600 ")
                  }
                  onClick={() => setSortOpts({ ...sortOpts, isOpen: !sortOpts.isOpen })}
                >
                  {sortOpts.selected.label}
                </button>
                <div
                  className={
                    "absolute py-2 mt-40 bg-white w-44 text-left shadow-lg border rounded-bl-md rounded-br-md " +
                    (sortOpts.isOpen ? "" : " hidden ")
                  }
                  style={{
                    zIndex: "100"
                  }}
                >
                  {sortOpts.data.map(o => (
                    <button
                      className="w-full py-1 px-3 hover:bg-gray-200 text-left"
                      onClick={() => setSortOpts({ ...sortOpts, selected: o, isOpen: false })}
                    >
                      { o.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div
              className={
                "p-2 grid " +
                (screenSize.width > 500 ? " grid-cols-4 gap-y-3 gap-x-3 " : " grid-cols-2 gap-y-2 gap-x-2 ")
              }
            >
              {items.map(i => (
                <ItemBox item={i} />
              ))}
            </div>
            <div className="flex justify-between">
              <label>Menampilkan 1-{getDataParams.limit} dari {dataStatus.totalData}</label>
              <div className="flex space-x-2">
                {(getDataParams.offset - getDataParams.limit) >= 0 && (
                  <button
                    className="hover:text-blue-500"
                    onClick={() => dispatch(setOffset(getDataParams.offset - getDataParams.limit))}
                  >
                    <FaChevronLeft />
                  </button>
                )}
                <label style={{ fontSize: "18px" }}>{Math.floor(getDataParams.offset / getDataParams.limit) + 1}/{Math.ceil(dataStatus.totalData / getDataParams.limit)}</label>
                {(getDataParams.offset + getDataParams.limit) < dataStatus.totalData && (
                  <button
                    className="hover:text-blue-500"
                    onClick={() => dispatch(setOffset(getDataParams.offset + getDataParams.limit))}
                  >
                    <FaChevronRight />
                  </button>
                )}
              </div>
            </div>
          </>)
      }
    </div>
  )
}

function ItemBox({ item }) {
  const history = useHistory()

  const { screenSize } = useSelector(state => state.global)

  function hasDisc() {
    return item.disc != "0%"
  }

  return (
    <button
      id={item.id}
      className="relative py-3 cursor-pointer group border border-transparent flex flex-col justify-start hover:border-green-300 hover:shadow-md"
      onClick={() => history.push("/product-detail/" + item.id)}
    >
      {hasDisc() && (
        <div
          className="absolute right-5 rounded-full bg-red-500 text-white shadow-lg"
          style={{
            width: "50px",
            height: "50px"
          }}
        >
          <div className="h-full flex items-center justify-center">
            -{item.disc}
          </div>
        </div>
      )}
      <div className="w-full flex justify-center">
        <img src={item.img} width="160px" />
      </div>
      <div className="flex flex-col px-3 text-left">
        <label
          className="py-1 cursor-pointer group-hover:text-green-600"
          style={{ fontSize: screenSize.width > 500 ? "15px" : "15px" }}
        >
          {item.title}
        </label>
        {/* <label
          className={
            "font-bold cursor-pointer " +
            (item.potonganHargaBukuFullColor != "0%" ? " line-through " : "")
          }
          style={{
            fontSize: item.potonganHargaBukuFullColor != "0%" ? "13px" : "16px"
          }}
        >
          {"Rp " + numToRupiahFormat(item.hargaPrintFullColor)}
        </label> */}
        <div
          className="flex flex-col"
        >
          <label style={{ fontSize: "13px", marginTop: "5px", fontWeight: "700" }}>Harga mulai dari</label>
          <label className="font-bold text-red-600">
            {"Rp " + numToRupiahFormat(item.priceWithPromotion)}
          </label>
        </div>
        {/* {
          item.potonganHargaBukuFullColor != "0%" && (
            <label className="font-bold text-red-600">
              {"Rp " + numToRupiahFormat(item.hargaPrintFullColorWithPromotion)}
            </label>
          )
        } */}
      </div>
    </button>
  )
}