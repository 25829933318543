import { createSlice } from '@reduxjs/toolkit'

/*
* cart Item structure :
*   - id buku
*   - nama buku
*   - img
*   - tipe buku (full_color, dua_warna, digital)
*   - harga buku
*   - jumlah barang
*   - subtotal item
*   - notAddedYet (boolean, attr ini exist ketika user add to cart item tapi belum login, maka ketika login dilakukan add to cart semua item dengan attr notAddYet true, lalu update jadi false ketika selesai add to cart)
*/
const initialState = {
  cartItems: []
}

export const CartSlice = createSlice({
  name: 'Cart',
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.cartItems = payload
    },
    /**
     * Handle kasus :
     *  - add item baru (belum pernah di add sebelumnya)
     *  - add item dengan id dan tipe buku sudah pernah di add sebelumnya (maka menambah stok untuk id dan tipe buku tsb)
     * @param {*} state 
     * @param {*} param1 
     */
    addItem: (state, { payload }) => {
      const exist = state.cartItems.find(i => i.id_buku == payload.id_buku && i.tipe_buku == payload.tipe_buku)
      if (exist) {
        state.cartItems =
          state
            .cartItems
            .map(i => i.id_buku == payload.id_buku && i.tipe_buku == payload.tipe_buku ? { ...i, jumlah_barang: i.jumlah_barang + payload.jumlah_barang } : i)
      }
      else {
        state.cartItems = [...state.cartItems, payload]
      }
    },
    /**
     * Tambah jumlah barang di pesan dan subtotal
     * @param {*} state 
     * @param {*} param1 
     */
    incrementJmlBarang: (state, { payload }) => {
      state.cartItems = state.cartItems.map(i => {
        if (i.id_buku == payload.id_buku && i.tipe_buku == payload.tipe_buku) {
          const selectedPrice =
            i.tipe_buku == 'full_color' ?
              i.harga_buku_full_color
              :
              i.tipe_buku == 'dua_warna' ?
                i.harga_buku_dua_warna
                :
                i.harga_buku_digital

          return { ...i, jumlah_barang: i.jumlah_barang + 1, subtotal: (i.jumlah_barang + 1) * selectedPrice }
        }
        return i
      })
    },
    decrementJmlBarang: (state, { payload }) => {
      state.cartItems = state.cartItems.map(i => {
        if (i.id_buku == payload.id_buku && i.tipe_buku == payload.tipe_buku && i.jumlah_barang > 1) {
          const selectedPrice =
            i.tipe_buku == 'full_color' ?
              i.harga_buku_full_color
              :
              i.tipe_buku == 'dua_warna' ?
                i.harga_buku_dua_warna
                :
                i.harga_buku_digital

          return { ...i, jumlah_barang: i.jumlah_barang - 1, subtotal: (i.jumlah_barang - 1) * selectedPrice }
        }
        return i
      })
    },
    removeItem: (state, { payload }) => {
      state.cartItems = state.cartItems.filter(i => i.id_buku != payload.id_buku || i.tipe_buku != payload.tipe_buku)
    },
    updateItemSynced: (state, { payload }) => {
      state.cartItems = state.cartItems.map(i => i.id_buku == payload.id_buku && i.tipe_buku == payload.tipe_buku ? { ...i, notAddedYet: false } : i)
    },
    resetCartItems: state => {
      state.cartItems = []
    }
  }
})

export const {
  setItems,
  addItem,
  incrementJmlBarang,
  decrementJmlBarang,
  removeItem,
  updateItemSynced,
  resetCartItems
} = CartSlice.actions

export default CartSlice.reducer