import { Dialog, Transition } from "@headlessui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { closeAddDialog } from './PmbBankAccountSlice'
import { VscChromeClose } from "react-icons/vsc"
import { closeSelectShippingDialog } from "../CheckoutSlice"
import { AiOutlineArrowLeft } from "react-icons/ai"
import axios from "axios"
import { RiLoader5Fill } from "react-icons/ri"
import { numToRupiahFormat } from '../../../util/currency'

export default function SelectShippingDialog({
  availDisc,
  cityDestId,
  onSelected,
  onSelectShippingDisc
}) {
  const dispatch = useDispatch()

  const { screenSize } = useSelector(state => state.global)

  const {
    selectShippingDialog,
    couriers
  } = useSelector(state => state.checkout)

  const [mode, setMode] = useState('courier') // courier or service

  const [pref, setPref] = useState({
    selectedCourierId: null,
    selectedServiceId: null
  })

  useEffect(() => {
    if (mode == 'courier') {
      setPref({ ...pref, selectedCourierId: null, selectedServiceId: null })
    }
  }, [mode])

  useEffect(() => {
    if (pref.selectedCourierId) {
      setMode('service')
    }
    else {
      setMode('courier')
    }
  }, [pref.selectedCourierId])

  useEffect(() => {
    if (!selectShippingDialog.isOpen) {
      setPref({ ...pref, selectedCourierId: null, selectedServiceId: null })
    }
  }, [selectShippingDialog.isOpen])

  return (
    <Transition appear show={selectShippingDialog.isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto"
        style={{
          zIndex: "999"
        }}
        onClose={() => null}
      >
        <div className="min-h-screen px-4 text-center flex justify-center items-center">
          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-70 bg-gray-200" />
          </Transition.Child>

          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={
                "absolute " +
                (screenSize.width > 500 ? " left-1/2 top-20 " : " left-0 top-5 ")
              }
            >
              <div
                className="py-3 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
                style={{
                  position: "absolute",
                  left: screenSize.width > 500 ? "-250px" : "0",
                  width: screenSize.width > 500 ? "500px" : screenSize.width + "px",
                  height: "550px"
                }}
              >
                <Dialog.Title
                  as="h3"
                  className="font-bold pb-3 px-5 text-lg flex items-center justify-between font-medium leading-6 text-gray-900 border-b border-gray-200"
                >
                  {mode == 'courier' ?
                    'Pilih Kurir'
                    :
                    (
                      <>
                        <button
                          className="p-2 hover:text-red-500"
                          onClick={() => setMode('courier')}
                        >
                          <AiOutlineArrowLeft className="h-6 w-6" />
                        </button>
                        <label>Pilih Layanan Kirim</label>
                      </>
                    )
                  }

                  <button
                    className="p-2 hover:text-red-500"
                    onClick={() => dispatch(closeSelectShippingDialog())}
                  >
                    <VscChromeClose className="h-6 w-6" />
                  </button>
                </Dialog.Title>
                <div
                  className="mt-2 px-5 flex flex-col space-y-3 overflow-auto space-y-3"
                  style={{ height: "450px" }}
                >
                  {
                    mode == 'courier' ?
                      (<SelectCourier
                        availDisc={availDisc}
                        couriers={couriers}
                        onSelect={id => setPref({ ...pref, selectedCourierId: id })}
                        onSelectShippingDisc={(disc) => {
                          onSelectShippingDisc(disc)
                          dispatch(closeSelectShippingDialog())
                        }}
                      />)
                      :
                      (<SelectService
                        courierId={pref.selectedCourierId}
                        cityDestId={cityDestId}
                        onSelected={onSelected}
                      />)
                  }

                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

const fetchServicesServer = async (payload) => {
  const finalUrl =
    window._HOST + "api/v1/user/checkouts/deliveryservice?id_kota_tujuan=" + payload.id_kota_tujuan + "&opsi_kurir=" + payload.opsi_kurir

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

function SelectCourier({
  availDisc,
  couriers,
  onSelect,
  onSelectShippingDisc
}) {

  const renderedCour = couriers.map(c => (
    <div className="text-left p-3 border border-gray-300 rounded-md space-y-1">
      <div className="text-center font-bold">
        {c.label}
      </div>
      <div className="py-2">
        <button
          className="w-full py-2 border border-blue-700 rounded-full font-bold text-blue-700 hover:bg-blue-700 hover:text-white"
          style={{ fontSize: "14px" }}
          onClick={() => onSelect(c.id)}
        >
          Pilih Kurir
        </button>
      </div>
    </div>
  ))

  return (
    <>
      {availDisc != null && (
        <div className="text-left p-3 border border-red-300 bg-red-600 text-white rounded-md space-y-2">
          <div className="text-center font-bold" style={{ fontSize: "18px" }}>
            Gratis Ongkos Kirim
          </div>
          <div>
            <ul className="list-disc ml-5">
              <li>
                Kurir pengiriman ditentukan oleh Penerbit ALC
              </li>
            </ul>
          </div>
          <div className="py-2">
            <button
              className="w-full py-2 bg-white rounded-full font-bold text-red-500 hover:shadow-lg hover:text-red-900"
              style={{ fontSize: "14px" }}
              onClick={() => onSelectShippingDisc(availDisc)}
            >
              Pilih Layanan Ini
            </button>
          </div>
        </div>
      )}
      {renderedCour}
    </>
  )

}

function SelectService({
  courierId,
  cityDestId,
  onSelected
}) {
  const dispatch = useDispatch()

  const [isLoad, setIsLoad] = useState(false)

  const [services, setServices] = useState([])

  useEffect(() => {
    if (courierId) {
      setIsLoad(true)

      const payload = {
        id_kota_tujuan: cityDestId,
        opsi_kurir: courierId
      }

      fetchServicesServer(payload)
        .then(response => {
          if (response.success) {
            setServices(response.data[0].costs)
          }
        })
        .catch(errors => { })
        .finally(() => setIsLoad(false))
    }
  }, [courierId])

  if (isLoad) {
    return (
      <div className="h-full w-full flex items-center justify-center text-blue-500">
        <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
      </div>
    )
  }

  if (services.length == 0) {
    return (
      <div>
        Layanan tidak tersedia
      </div>
    )
  }

  return services.map(s => (
    <div className="text-left p-3 border border-gray-300 rounded-md space-y-1">
      <div className="text-left font-bold">
        {s.service} ( Rp {numToRupiahFormat(s.cost[0].value)} )
      </div>
      <div className="" style={{ fontSize: "14px" }}>
        Estimasi pengiriman {s.cost[0].etd.toLowerCase().replace("hari", "")} hari
      </div>
      <div className="py-2">
        <button
          className="w-full py-2 border border-blue-700 rounded-full font-bold text-blue-700 hover:bg-blue-700 hover:text-white"
          style={{ fontSize: "14px" }}
          onClick={() => {
            const payload = {
              courierId: courierId,
              service: s
            }

            dispatch(closeSelectShippingDialog())

            onSelected(payload)
          }}
        >
          Pilih Layanan
        </button>
      </div>
    </div>
  ))

}