import { Dialog, Transition } from "@headlessui/react"
import { useEffect, useRef, useState } from "react"
import { VscChromeClose } from "react-icons/vsc"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { numToRupiahFormat } from '../../../util/currency'

export default function SuccessAddToCart({
  isShow,
  bookDetail,
  orderDetail,
  onClose
}) {
  const history = useHistory()
  const { screenSize } = useSelector(state => state.global)

  return (
    <Transition appear show={isShow}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto"
        style={{
          zIndex: "999"
        }}
        onClose={() => null}
      >
        <div className="min-h-screen px-4 text-center flex justify-center items-center">
          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-50 bg-black" />
          </Transition.Child>

          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={
                "absolute top-20 " +
                (screenSize.width > 500 ? " left-1/2 " : " left-0 ")
              }
            >
              <div
                className="py-3 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
                style={{
                  position: "absolute",
                  left: screenSize.width > 500 ? "-230px" : "0",
                  width: screenSize.width > 500 ? "460px" : screenSize.width + "px",
                  height: orderDetail.selectedVersion != 'digital' ? "320px" : "510px"
                }}
              >
                <Dialog.Title
                  as="h3"
                  className="pb-3 px-5 text-lg flex items-center justify-between font-bold leading-6 text-gray-900 border-b border-gray-200"
                >
                  Item Berhasil Ditambahkan!
                  <button
                    className="p-2 hover:text-red-500"
                    onClick={onClose}
                  >
                    <VscChromeClose className="h-6 w-6" />
                  </button>
                </Dialog.Title>
                {orderDetail.selectedVersion == 'digital' && (
                  <div className="bg-blue-100 py-5 px-2 flex flex-col space-y-2">
                    <label className="font-bold text-black text-center" style={{ fontFamily: "Nunito" }} >
                      Anda perlu mengunduh aplikasi Syibook (Gratis) untuk mengakses buku digital yang telah anda beli  
                    </label>
                    <label className="font-bold text-black text-center" style={{ fontFamily: "Nunito" }} >
                      Tersedia untuk platform Android dan Windows  
                    </label>
                    <button
                      className="py-1 bg-green-700 hover:bg-green-900 text-white rounded-full font-bold" style={{ fontFamily: "Nunito" }}
                      onClick={() => window.open('https://play.google.com/store/apps/details?id=com.syibook', '_blank')}
                    >
                      Download Syibook Android
                    </button>
                    <button
                      className="py-1 bg-blue-700 hover:bg-blue-900 text-white rounded-full font-bold" style={{ fontFamily: "Nunito" }}
                      onClick={() => window.open('https://play.google.com/store/apps/details?id=com.syibook', '_blank')}
                    >
                      Download Syibook Windows
                    </button>
                  </div>
                )}
                <div
                  className="mt-4 px-5 flex flex-col space-y-3 overflow-auto space-y-5"
                  style={{ height: "450px" }}
                >
                  <div className="flex space-x-2">
                    <img
                      src={bookDetail != null ? bookDetail.thumb_img_1 : '-'}
                      width="80px"
                    />
                    <div className="flex flex-col text-left ml-5 space-y-2">
                      <label className="font-bold text-black" style={{ fontFamily: "Nunito" }}>{bookDetail != null ? bookDetail.buku : '-'}</label>
                      <label className="text-gray-600">
                        {orderDetail != null ?
                          orderDetail.selectedVersion == 'full_color' ?
                            'Cetak - Full Color'
                            :
                            orderDetail.selectedVersion == 'dua_warna' ?
                              'Cetak - Dua Warna'
                              :
                              orderDetail.selectedVersion == 'satu_warna' ?
                                'Cetak - Hitam Putih'
                                :
                                orderDetail.selectedVersion == 'digital' ?
                                  'Digital'
                                  :
                                  '-'
                          :
                          '-'
                        }
                        {' - '}
                        {
                          orderDetail != null ?
                            orderDetail.jmlBarang + ' barang '
                            :
                            '-'
                        }
                      </label>
                      <label className="text-gray-600">
                        {orderDetail != null ?
                          'Subtotal : Rp ' + numToRupiahFormat(orderDetail.subtotal)
                          :
                          '-'
                        }
                      </label>
                    </div>
                    <div>

                    </div>
                  </div>
                  <div className="w-full">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center px-4 py-2 text-sm font-bold text-white bg-blue-700 border border-transparent rounded-full hover:shadow-lg hover:bg-blue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={() => history.push("/cart")}
                    >
                      Lihat Keranjang Belanja
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )

}